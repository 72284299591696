import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { NewUserBodyType } from "./usersAPI";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Role } from "./constants";
import { StatusType } from "../../app/constants";
import LoadingButton from "../shared/LoadingButton";

export default function UserForm({
  currentUser,
  handleSubmit,
  handleOnChange,
  formError,
  status,
  isUpdate,
  onCancel,
}: {
  status: StatusType;
  currentUser: NewUserBodyType;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleOnChange: (label: string, value: string | string[]) => void;
  formError: {
    name: boolean;
    email: boolean;
    password: boolean;
    role: boolean;
  };
  isUpdate?: boolean;
  onCancel?: () => void;
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        value={currentUser.name}
        margin="normal"
        required
        fullWidth
        id="name"
        label="Nombre"
        name="name"
        autoFocus
        type="text"
        onChange={(e) => handleOnChange("name", e.target.value)}
        error={formError.name}
        helperText={formError.name ? "Por favor ingrese un nombre válido" : ""}
      />

      <TextField
        value={currentUser.email}
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        autoComplete="email"
        type="email"
        onChange={(e) => handleOnChange("email", e.target.value)}
        error={formError.email}
        helperText={formError.email ? "Por favor ingrese un email válido" : ""}
        disabled={isUpdate}
      />

      <TextField
        value={currentUser.password}
        margin="normal"
        required
        fullWidth
        name="password"
        label="Contraseña"
        type={showPassword ? "text" : "password"}
        id="password"
        autoComplete="current-password"
        onChange={(e) => handleOnChange("password", e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleClickShowPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={formError.password}
        helperText={
          formError.password
            ? "Por favor ingrese una contraseña válida (al menos 8 caracteres)"
            : ""
        }
      />

      <FormControl fullWidth margin="normal">
        <InputLabel id="user-role-label">Rol</InputLabel>
        <Select
          labelId="user-role-label"
          id="user-role"
          value={currentUser.roles[0]}
          label="Role"
          onChange={(e) => handleOnChange("roles", [e.target.value])}
        >
          <MenuItem value={Role.seller}>Vendedor</MenuItem>
          <MenuItem value={Role.customerService}>Customer Service</MenuItem>
          <MenuItem value={Role.admin}>Administrador</MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ display: "flex" }}>
        <Button
          type="button"
          onClick={onCancel}
          sx={{ flex: 1, mt: 3, mb: 2, marginRight: 2 }}
        >
          Cancelar
        </Button>
        <LoadingButton
          sx={{ flex: 1 }}
          loading={status === StatusType.loading}
          text={isUpdate ? "Modificar usuario" : "Crear usuario"}
          type="submit"
        />
      </Box>
    </Box>
  );
}
