import {
  Box,
  Button,
  Container,
  Dialog,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { StatusType } from "../../app/constants";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectProfile } from "../signIn/signInSlice";
import { Role } from "../users/constants";
import { ClientType, GetClientsFilters } from "./clientsAPI";
import {
  getClients,
  selectClients,
  selectClientsStatus,
  selectTotalClients,
} from "./clientsSlice";
import { ClientStatus } from "./constants";
import { CLIENTS } from "../../routes";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const columns: GridColDef[] = [
  { field: "name", headerName: "Nombre", width: 230 },
  { field: "address", headerName: "Dirección", width: 230 },
  {
    field: "actions",
    type: "actions",
    width: 80,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<ArrowForwardIcon />}
        label="Ver"
        onClick={() => window.open(`${CLIENTS}/${params.id}`, "_blank")}
      />,
    ],
    filterable: false,
    sortable: false,
  },
];

const ClientSelector = ({
  open,
  selectedClient,
  toggleModal,
  onSelectExistentClient,
  error,
  canUpdate,
}: {
  open: boolean;
  selectedClient: ClientType | null;
  toggleModal: () => void;
  onSelectExistentClient: (client: ClientType) => void;
  error: boolean;
  canUpdate?: boolean;
}) => {
  const dispatch = useAppDispatch();

  const [pageModel, setPageModel] = useState({ page: 0, pageSize: 10 });
  const [currentClient, setCurrentClient] = useState<ClientType>();
  const [search, setSearch] = useState("");
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>();

  const loading = useAppSelector(selectClientsStatus) === StatusType.loading;
  const clients = useAppSelector(selectClients);
  const total = useAppSelector(selectTotalClients);

  const profile = useAppSelector(selectProfile);
  const isAdmin = useMemo(
    () =>
      (profile?.roles?.includes(Role.admin) ||
        profile?.roles?.includes(Role.customerService)) ??
      false,
    [profile],
  );

  const onConfirm = () => {
    if (currentClient) {
      onSelectExistentClient(currentClient);
      toggleModal();
    }
  };

  const onRowSelectionModelChange = (
    newRowSelectionModel: GridRowSelectionModel,
  ) => {
    if (newRowSelectionModel.length)
      setRowSelectionModel([
        newRowSelectionModel[newRowSelectionModel.length - 1],
      ]);
    else setRowSelectionModel([]);

    setCurrentClient(
      clients.find(
        (client) =>
          client._id === newRowSelectionModel[newRowSelectionModel.length - 1],
      ),
    );
  };

  const getClientsPage = (newFilters?: GetClientsFilters) => {
    const filters: GetClientsFilters = {
      page: newFilters?.page || pageModel.page + 1,
      pageSize: newFilters?.pageSize || pageModel.pageSize,
      status: ClientStatus.active,
    };

    if (profile && !isAdmin) {
      filters.sellers = [profile._id];
    }

    if (newFilters?.search !== undefined) filters.search = newFilters.search;
    else if (search) filters.search = search;

    dispatch(getClients(filters));
  };

  const onFilterModelChange = (model: GridFilterModel) => {
    const newSearchTerm = model.quickFilterValues?.length
      ? model.quickFilterValues[0]
      : "";
    setSearch(newSearchTerm);
    getClientsPage({
      search: newSearchTerm,
    });
  };

  const onPaginationModelChange = (model: GridPaginationModel) => {
    setPageModel({ page: model.page, pageSize: model.pageSize });
    getClientsPage({ page: model.page + 1, pageSize: model.pageSize });
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if (open) getClientsPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Paper
        elevation={1}
        sx={{ padding: 3, borderRadius: 5, margin: "20px 0" }}
      >
        <Typography variant="h6">Cliente *</Typography>
        {selectedClient && selectedClient?.name?.length > 0 ? (
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <Typography variant="subtitle1" style={{ margin: "20px 0" }}>
              <strong>{selectedClient.name}</strong> - {selectedClient.address}
            </Typography>
          </Stack>
        ) : null}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: "10px",
          }}
        >
          {selectedClient && selectedClient._id && (
            <Button
              onClick={() => window.open(`${CLIENTS}/${selectedClient._id}`)}
              fullWidth
              variant="outlined"
            >
              Ver cliente
            </Button>
          )}
          {canUpdate && (
            <Button
              onClick={toggleModal}
              fullWidth
              disableRipple
              variant="contained"
            >
              {selectedClient?.name ? "Cambiar" : "Seleccionar"} cliente
            </Button>
          )}
        </Box>
        {error && !selectedClient && (
          <Typography
            sx={{
              color: "#d32f2f",
              fontSize: "0.75rem",
              fontWeight: 400,
              marginTop: "7px",
            }}
          >
            Por favor seleccione un cliente
          </Typography>
        )}
      </Paper>
      <Dialog
        open={open}
        onClose={toggleModal}
        maxWidth="lg"
        sx={{
          // display: { width: { sm: "block", md: "flex" } },
          // justifyContent: "center",
          // margin: "auto",
          "& .MuiDialog-paper": {
            width: { xs: "100%", sm: "80%", md: "60%" },
            maxWidth: "600px",
          },
        }}
      >
        <Container
          component="main"
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginTop: 4,
            marginBottom: 4,
          }}
        >
          <Typography variant="h6" marginBottom={2}>
            Seleccionar cliente
          </Typography>

          <div style={{ height: 300, width: "100%" }}>
            <DataGrid
              filterMode="server"
              onFilterModelChange={onFilterModelChange}
              paginationMode="server"
              pagination={true}
              paginationModel={pageModel}
              onPaginationModelChange={onPaginationModelChange}
              loading={loading}
              rows={clients}
              columns={columns}
              disableColumnMenu
              rowCount={total}
              pageSizeOptions={[1, 10, 15]}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              onRowSelectionModelChange={onRowSelectionModelChange}
              rowSelectionModel={rowSelectionModel}
              slots={{ toolbar: CustomToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </div>

          <Box
            sx={{
              display: "flex",
              alignItems: "stretch",
              marginLeft: "auto",
              marginTop: 2,
            }}
          >
            <Button
              variant="text"
              sx={{ marginRight: 1 }}
              onClick={toggleModal}
            >
              Cancelar
            </Button>
            <Button
              disabled={!rowSelectionModel?.length}
              variant="contained"
              onClick={onConfirm}
            >
              Seleccionar cliente
            </Button>
          </Box>
        </Container>
      </Dialog>
    </>
  );
};

export default ClientSelector;
