import { Add, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { StatusType } from "../../app/constants";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import formValidations from "../../utils/formValidations";
import { ClientBrokerType, GetClientBrokersFilters } from "./clientBrokersAPI";
import {
  createClientBroker,
  getClientBrokers,
  selectClientBrokers,
  selectClientBrokersStatus,
  selectTotalClientBrokers,
} from "./clientBrokersSlice";

const initialBroker = {
  name: "",
  email: "",
  phone: "",
};

const NewBroker = ({
  newBroker,
  handleOnChange,
  onConfirm,
  showErrors,
}: {
  newBroker: ClientBrokerType;
  onConfirm: () => void;
  showErrors: boolean;
  handleOnChange: (label: string, value: string) => void;
}) => {
  const errorName =
    showErrors && !formValidations.validateName(newBroker?.name);
  const errorEmail =
    showErrors && !formValidations.validateEmail(newBroker?.email || "");
  const errorPhone =
    showErrors && !formValidations.validatePhone(newBroker?.phone || "");

  return (
    <Box margin="20px 0" width="100%">
      <Typography variant="h6">Crear nuevo despachante</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
        }}
      >
        <TextField
          value={newBroker?.name}
          margin="normal"
          required
          fullWidth
          id="name"
          label="Nombre"
          name="name"
          autoFocus
          type="text"
          onChange={(e) => handleOnChange("name", e.target.value)}
          error={errorName}
          helperText={errorName ? "Por favor ingrese un nombre" : ""}
          sx={{ marginRight: 1 }}
        />
        <TextField
          value={newBroker?.email}
          margin="normal"
          fullWidth
          id="email"
          label="Correo electrónico"
          name="name"
          autoFocus
          type="email"
          onChange={(e) => handleOnChange("email", e.target.value)}
          error={errorEmail}
          helperText={errorEmail ? "Por favor ingrese un correo válido" : ""}
          sx={{ marginRight: 1 }}
        />
        <TextField
          value={newBroker?.phone}
          margin="normal"
          fullWidth
          id="phone"
          label="Teléfono"
          name="phone"
          autoFocus
          type="text"
          onChange={(e) => handleOnChange("phone", e.target.value)}
          error={errorPhone}
          helperText={errorPhone ? "Por favor ingrese un teléfono válido" : ""}
          sx={{ marginRight: 1 }}
        />
        <Button
          aria-label="create"
          onClick={onConfirm}
          disabled={!formValidations.validateName(newBroker?.name || "")}
        >
          <Add /> Crear
        </Button>
      </Box>
    </Box>
  );
};

const columns: GridColDef[] = [
  { field: "name", headerName: "Nombre", width: 230 },
  { field: "email", headerName: "Correo electrónico", width: 230 },
  { field: "phone", headerName: "Teléfono", width: 230 },
];

const ClientBrokerSelector = ({
  open,
  selectedBroker,
  toggleModal,
  onSelectExistentBroker,
  canUpdate,
}: {
  open: boolean;
  selectedBroker: ClientBrokerType;
  toggleModal: () => void;
  onSelectExistentBroker: (broker: ClientBrokerType) => void;
  canUpdate?: boolean;
}) => {
  const dispatch = useAppDispatch();

  const [pageModel, setPageModel] = useState({ page: 0, pageSize: 10 });
  const [currentBroker, setCurrentBroker] = useState<ClientBrokerType>();
  const [search, setSearch] = useState("");
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>();
  const [newBroker, setNewBroker] = useState<ClientBrokerType>({
    ...initialBroker,
  });

  const loading =
    useAppSelector(selectClientBrokersStatus) === StatusType.loading;
  const clientBrokers = useAppSelector(selectClientBrokers);
  const total = useAppSelector(selectTotalClientBrokers);

  const handleOnChange = (label: string, value: string) => {
    setNewBroker((broker) => ({ ...broker, [label]: value }));
  };

  const onConfirm = () => {
    if (currentBroker) {
      onSelectExistentBroker(currentBroker);
      toggleModal();
    }
  };

  const onDeleteSelectedBroker = () => {
    onSelectExistentBroker({ ...initialBroker });
    setCurrentBroker(undefined);
    setRowSelectionModel([]);
  };

  const onConfirmNewBroker = () => {
    const body: ClientBrokerType = { name: newBroker.name };
    if (
      newBroker.email?.length &&
      formValidations.validateEmail(newBroker.email)
    ) {
      body.email = newBroker.email;
    }
    if (
      newBroker.phone?.length &&
      formValidations.validatePhone(newBroker.phone)
    ) {
      body.phone = newBroker.phone;
    }
    if (formValidations.validateName(newBroker.name))
      dispatch(createClientBroker(body)).then((value) => {
        if (value.meta.requestStatus === "fulfilled") {
          handleOnChange("selectedClientBroker", value.payload);
          setNewBroker({ ...initialBroker });
          toggleModal();
        }
      });
  };

  const onRowSelectionModelChange = (
    newRowSelectionModel: GridRowSelectionModel,
  ) => {
    if (newRowSelectionModel.length)
      setRowSelectionModel([
        newRowSelectionModel[newRowSelectionModel.length - 1],
      ]);
    else setRowSelectionModel([]);

    setCurrentBroker(
      clientBrokers.find(
        (broker) =>
          broker.id === newRowSelectionModel[newRowSelectionModel.length - 1],
      ),
    );
  };

  const getClientBrokersPage = (newFilters?: GetClientBrokersFilters) => {
    const filters: GetClientBrokersFilters = {
      page: newFilters?.page || pageModel.page + 1,
      pageSize: newFilters?.pageSize || pageModel.pageSize,
    };

    if (newFilters?.search !== undefined) filters.search = newFilters.search;
    else if (search) filters.search = search;

    dispatch(getClientBrokers(filters));
  };

  const onFilterModelChange = (model: GridFilterModel) => {
    const newSearchTerm = model.quickFilterValues?.length
      ? model.quickFilterValues[0]
      : "";
    setSearch(newSearchTerm);
    getClientBrokersPage({
      search: newSearchTerm,
    });
  };

  const onPaginationModelChange = (model: GridPaginationModel) => {
    setPageModel({ page: model.page, pageSize: model.pageSize });
    getClientBrokersPage({ page: model.page + 1, pageSize: model.pageSize });
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if (open) getClientBrokersPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Paper
        elevation={1}
        sx={{ padding: 3, borderRadius: 5, margin: "20px 0" }}
      >
        <Typography variant="h6">Despachante</Typography>
        {selectedBroker?.name.length > 0 ? (
          <Stack direction="row" sx={{ alignItems: "center" }}>
            <Typography variant="subtitle1" style={{ margin: "20px 0" }}>
              <strong>{selectedBroker.name}</strong> - {selectedBroker.email}
            </Typography>
            <IconButton onClick={onDeleteSelectedBroker}>
              <Delete />
            </IconButton>
          </Stack>
        ) : null}
        {canUpdate && (
          <Button
            onClick={toggleModal}
            fullWidth
            disableRipple
            variant="outlined"
          >
            {selectedBroker?.name ? "Cambiar" : "Seleccionar"} despachante
          </Button>
        )}
      </Paper>
      <Dialog open={open} onClose={toggleModal} maxWidth="lg">
        <Container
          component="main"
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginTop: 4,
            marginBottom: 4,
          }}
        >
          <Typography variant="h6" marginBottom={2}>
            Seleccionar despachante
          </Typography>

          <div style={{ height: 300, width: "100%" }}>
            <DataGrid
              filterMode="server"
              onFilterModelChange={onFilterModelChange}
              paginationMode="server"
              pagination={true}
              paginationModel={pageModel}
              onPaginationModelChange={onPaginationModelChange}
              loading={loading}
              rows={clientBrokers}
              columns={columns}
              disableColumnMenu
              rowCount={total}
              pageSizeOptions={[1, 10, 15]}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              onRowSelectionModelChange={onRowSelectionModelChange}
              rowSelectionModel={rowSelectionModel}
              slots={{ toolbar: CustomToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </div>

          <Box
            sx={{
              display: "flex",
              alignItems: "stretch",
              marginLeft: "auto",
              marginTop: 2,
            }}
          >
            <Button
              variant="text"
              sx={{ marginRight: 1 }}
              onClick={toggleModal}
            >
              Cancelar
            </Button>
            <Button
              disabled={!rowSelectionModel?.length}
              variant="contained"
              onClick={onConfirm}
            >
              Seleccionar despachante
            </Button>
          </Box>

          <Divider
            orientation="horizontal"
            sx={{ width: "100%", marginTop: 2 }}
          />
          <NewBroker
            handleOnChange={handleOnChange}
            newBroker={newBroker}
            showErrors={false}
            onConfirm={onConfirmNewBroker}
          />
        </Container>
      </Dialog>
    </>
  );
};

export default ClientBrokerSelector;
