import { useEffect, useMemo } from "react";
import type {} from "@mui/x-data-grid/themeAugmentation";
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  List,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { CargoStatus, StatusType } from "../../app/constants";
import {
  getAlerts,
  getMetrics,
  selectAlerts,
  selectAlertsStatus,
  selectMetrics,
  selectMetricsStatus,
} from "./dashboardSlice";
import { GetAlertsFilters } from "./dashboardAPI";

import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { AlertItem } from "./components/AlertItem";
import CreationsChart from "./components/CreationsChart";
import CargoPieChart from "./components/CargoPieChart";
import WidgetDashboard from "./components/WidgetDashboard";
import {
  CARGO_STATUS_ARRIVED_COLOR,
  CARGO_STATUS_DEPOSIT_COLOR,
  CARGO_STATUS_PENDING_COLOR,
  CARGO_STATUS_RESERVED_COLOR,
  CARGO_STATUS_SHIPPED_COLOR,
  MEDIA_QUERY_MOBILE,
} from "../../utils/constants";
import HorizontalChart from "./components/HorizontalChart";
import { selectProfile } from "../signIn/signInSlice";
import { Role } from "../users/constants";
import {
  getCargos,
  selectCargos,
  selectCargosStatus,
} from "../cargos/cargosSlice";
import NextCargosPerEta from "./components/NextCargosPerEta";
import { useNavigate } from "react-router-dom";
import { CARGOS, CLIENTS } from "../../routes";
import { ClientsWithoutNewCargos } from "./components/ClientsWithoutNewCargos";

export default function Dashboard() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE);

  const profile = useAppSelector(selectProfile);
  const isAdmin = useMemo(
    () => profile?.roles?.includes(Role.admin) ?? false,
    [profile],
  );
  const isSeller = useMemo(
    () =>
      !(
        profile?.roles?.includes(Role.admin) ||
        profile?.roles?.includes(Role.customerService)
      ),
    [profile],
  );

  const loading = useAppSelector(selectAlertsStatus) === StatusType.loading;
  const alerts = useAppSelector(selectAlerts);

  const metrics = useAppSelector(selectMetrics);
  const cargos = useAppSelector(selectCargos);

  const loadingMetrics =
    useAppSelector(selectMetricsStatus) === StatusType.loading;
  const loadingCargos =
    useAppSelector(selectCargosStatus) === StatusType.loading;

  const loadingDashboard = loadingMetrics || loadingCargos || !profile;

  const pageModel = { page: 1, pageSize: 20 };

  const getAlertsPage = (newFilters?: GetAlertsFilters) =>
    dispatch(
      getAlerts({
        page: pageModel.page,
        pageSize: pageModel.pageSize,
        ...newFilters,
      }),
    );

  const getDashboard = () => {
    const now = new Date();
    let seller;
    if (isSeller) {
      //if user is seller, just his cargos
      seller = profile?._id;
    }

    dispatch(getMetrics());
    dispatch(
      getCargos({
        page: 1,
        pageSize: 10,
        minEta: new Date(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
        ).toISOString(),
        sortField: "eta",
        sortValue: "asc",
        seller,
      }),
    );
  };

  useEffect(() => {
    if (loading) return;
    getAlertsPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!profile) return;
    getDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const dateFrom3d = new Date();
  dateFrom3d.setDate(dateFrom3d.getDate() - 3);
  dateFrom3d.setHours(0, 0, 0);

  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        minHeight: "calc(100vh - 50px)",
        height: isMobile ? "calc(100vh)" : "fit-content",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          marginTop: 10,
          gap: "20px",
        }}
      >
        <Box
          sx={{
            display: isMobile ? "none" : "flex",
            flexDirection: "column",
            width: { xs: "100%", sm: "70%" },
            justifyContent: "start",
            alignItems: "center",
            alignSelf: "flex-start",
            height: "100%",
          }}
        >
          {loadingDashboard && (
            <CircularProgress sx={{ placeSelf: "center", marginTop: "20px" }} />
          )}
          {!loadingDashboard && (
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Card
                  elevation={2}
                  sx={{
                    height: "420px",
                    width: "100%",
                    overflowY: "auto",
                  }}
                  onClick={() => navigate(CARGOS)}
                >
                  <CardHeader
                    title={`${isSeller ? "Tus " : ""}Próximas Cargas`}
                    subheader={"Próximas cargas por ETA (mayores a hoy)"}
                  />
                  <NextCargosPerEta rows={cargos} />
                </Card>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  justifyContent: "space-between",
                }}
              >
                <CargoPieChart
                  title={`${isSeller ? "Tus " : ""}Cargas`}
                  subheader="Cargas abiertas separadas por estado"
                  chartData={[
                    {
                      label: "Pendiente",
                      value: metrics?.cargo.pendingCount ?? 0,
                    },
                    {
                      label: "En depósito",
                      value: metrics?.cargo.depositCount ?? 0,
                    },
                    {
                      label: "Reservado",
                      value: metrics?.cargo.reservedCount ?? 0,
                    },
                    {
                      label: "Embarcado",
                      value: metrics?.cargo.shippedCount ?? 0,
                    },
                    {
                      label: "Arribado",
                      value: metrics?.cargo.arrivedCount ?? 0,
                    },
                  ]}
                  chartColors={[
                    CARGO_STATUS_PENDING_COLOR,
                    CARGO_STATUS_DEPOSIT_COLOR,
                    CARGO_STATUS_RESERVED_COLOR,
                    CARGO_STATUS_SHIPPED_COLOR,
                    CARGO_STATUS_ARRIVED_COLOR,
                  ]}
                  sellerId={isSeller && profile ? profile._id : undefined}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                    gap: "20px",
                    justifyContent: "space-between",
                  }}
                >
                  {isSeller ? (
                    <>
                      <WidgetDashboard
                        title="Clientes activos"
                        value={metrics?.client.activeClients ?? 0}
                        icon="active"
                        color="primary"
                        onClick={() => navigate(`${CLIENTS}?status=active`)}
                      />

                      <WidgetDashboard
                        title="Clientes potenciales"
                        value={metrics?.client.totalPotentialClients ?? 0}
                        icon="potential"
                        color="warning"
                        onClick={() => navigate(`${CLIENTS}?status=potential`)}
                      />
                    </>
                  ) : (
                    <>
                      <WidgetDashboard
                        title={
                          metrics?.cargo.newCargosLast3d !== 1
                            ? "Cargas nuevas"
                            : "Carga nueva"
                        }
                        value={metrics?.cargo.newCargosLast3d ?? 0}
                        icon="active"
                        color="primary"
                        onClick={() => navigate(`${CARGOS}`)}
                      />
                      <WidgetDashboard
                        title="Reservado Booking"
                        value={metrics?.cargo.reservedCount ?? 0}
                        icon="potential"
                        color="warning"
                        onClick={() =>
                          navigate(`${CARGOS}?status=${CargoStatus.reserved}`)
                        }
                      />
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", sm: "30%" },
            justifyContent: "start",
            alignItems: "center",
            alignSelf: "flex-start",
            height: "100%",
          }}
        >
          {loading && (
            <CircularProgress sx={{ placeSelf: "center", marginTop: "20px" }} />
          )}
          {!loading && (
            <Card
              elevation={2}
              sx={{
                minHeight: "400px",
                height: "100%",
                width: "100%",
                textAlign: "center",
                overflowY: "scroll",
              }}
            >
              {!loading && !alerts.length && (
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    flexDirection: "column",
                    width: "100%",
                    height: isMobile ? "calc(100vh - 200px)" : 683,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "25%",
                    gap: "15px",
                  }}
                >
                  <NotificationsOffIcon fontSize="large" color="primary" />{" "}
                  <Typography sx={{ maxWidth: "180px" }}>
                    No tienes notificaciones en este momento
                  </Typography>
                </Box>
              )}
              {!loading && alerts.length > 0 && (
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      display: "flex",
                      marginLeft: "15px",
                      marginTop: "10px",
                    }}
                  >
                    Alertas
                  </Typography>
                  <List
                    sx={{
                      width: "100%",
                      height: 725,
                      bgcolor: "background.paper",
                      textAlign: "center",
                      overflow: "scroll",
                      paddingBottom: 0,
                      paddingTop: 0,
                    }}
                  >
                    {alerts.map((alert) => (
                      <AlertItem alert={alert} />
                    ))}
                  </List>
                </Box>
              )}
            </Card>
          )}
        </Box>
      </Box>
      {!loadingDashboard && !isMobile && (
        <>
          {isAdmin ? (
            <>
              <Card
                elevation={2}
                sx={{
                  minHeight: "400px",
                  height: "100%",
                  width: "100%",
                  overflowY: "scroll",
                }}
              >
                <CreationsChart
                  title="Creaciones mensuales"
                  subheader={`Creaciones en los últimos 6 meses`}
                  chartLabels={
                    metrics?.cargo.newCargosPerDay.map((cpd) => {
                      const date = new Date(cpd.date);
                      return `${
                        date.getUTCMonth() + 1
                      }/${date.getUTCFullYear()}`;
                    }) ?? []
                  }
                  chartData={[
                    {
                      name: "Nuevas Cargas",
                      type: "area",
                      fill: "gradient",
                      data: metrics?.cargo.newCargosPerDay.map(
                        (cpd) => cpd.amount,
                      ),
                    },
                    {
                      name: "Nuevos Clientes",
                      type: "area",
                      fill: "gradient",
                      data: metrics?.client.newClientsPerDay.map(
                        (cpd) => cpd.amount,
                      ),
                    },
                  ]}
                />
              </Card>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  width: "100%",
                  gap: "20px",
                  height: "500px",
                }}
              >
                <HorizontalChart
                  title="Clientes con mas cargas"
                  subheader="Top 5 clientes por cantidad de cargas"
                  chartData={
                    metrics?.cargo.cargosPerClient.map((data) => ({
                      label: data.name,
                      value: data.cargoCount,
                    })) ?? []
                  }
                />
                <HorizontalChart
                  title="Agentes con mas cargas"
                  subheader="Top 5 agentes por cantidad de cargas"
                  chartData={
                    metrics?.cargo.cargosPerAgent.map((data) => ({
                      label: data.name,
                      value: data.cargoCount,
                    })) ?? []
                  }
                />
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
                gap: "20px",
                height: "500px",
              }}
            >
              <HorizontalChart
                title="Clientes con mas cargas"
                subheader="Top 5 clientes por cantidad de cargas"
                chartData={
                  (metrics?.cargo?.cargosPerClient || []).map((data) => ({
                    label: data?.name,
                    value: data?.cargoCount,
                  })) ?? []
                }
              />
              <Card
                elevation={2}
                sx={{
                  height: "500px",
                  width: "100%",
                  overflowY: "auto",
                }}
                onClick={() => navigate(CARGOS)}
              >
                <CardHeader
                  title="Clientes con cargas viejas"
                  subheader={
                    "Clientes que en 2 meses no han tenido cargas nuevas"
                  }
                />
                <ClientsWithoutNewCargos
                  clientsWithoutNewCargos={
                    metrics?.cargo?.clientWithoutNewCargos
                  }
                />
              </Card>
            </Box>
          )}
        </>
      )}
    </Container>
  );
}
