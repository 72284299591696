import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  title: {
    fontSize: 19,
    fontWeight: 900,
    lineHeight: 1.16,
    letterSpacing: 0.09,
    color: "black",
  },

  section1: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    marginBottom: 40,
  },

  numberContainer: {
    border: "2px solid black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 200,
  },
});

export default styles;
