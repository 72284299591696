import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import FlightIcon from "@mui/icons-material/Flight";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AirlineStopsIcon from "@mui/icons-material/AirlineStops";
import { Chip } from "@mui/material";

export enum StatusType {
  idle = "idle",
  loading = "loading",
  failed = "failed",
  succeeded = "succeeded",
}

export enum AlertTypeEnum {
  LAST_DAY = "last_day",
  SHIPPED = "shipped",
  ETD = "etd",
  ETA = "eta",
  DEPARTURE_DATE = "departure_date",
  CONNECTION_ETD = "connection_etd",
  CONNECTION_ETA = "connection_eta",
}

export const getAlertChipFromType = (type: string) => {
  if (type === AlertTypeEnum.LAST_DAY)
    return <Chip size="small" label={"CREADO AYER"} />;
  else if (type === AlertTypeEnum.SHIPPED)
    return <Chip size="small" color="primary" label={"EMBARCADO"} />;
  else if (
    type === AlertTypeEnum.ETD ||
    type === AlertTypeEnum.ETA ||
    type === AlertTypeEnum.DEPARTURE_DATE
  )
    return (
      <Chip
        size="small"
        color="info"
        label={type === AlertTypeEnum.ETA ? "ETA" : "ETD"}
      />
    );
  else
    return (
      <Chip
        size="small"
        color="warning"
        label={
          type === AlertTypeEnum.CONNECTION_ETD
            ? "ETD DE CONEXIÓN"
            : "ETA DE CONEXIÓN"
        }
      />
    );
};

export enum CargoTypeEnum {
  IM = "IM",
  EM = "EM",
  IA = "IA",
  EA = "EA",
  IT = "IT",
  ET = "ET",
  DA = "DA",
}

export enum IncoTerm {
  EXW = "EXW",
  FCA = "FCA",
  FAS = "FAS",
  FOB = "FOB",
  CFR = "CFR",
  CIF = "CIF",
  CPT = "CPT",
  CIP = "CIP",
  DPU = "DPU",
  DAP = "DAP",
  DDP = "DDP",
}

export enum FinanceTypeEnum {
  sale = "Sale",
  cost = "Cost",
}

export enum MblType {
  original = "Original",
  express = "Express",
}

export enum ContainerType {
  "LCL" = "LCL",
  "20FT" = "20FT",
  "40FT" = "40FT",
  "40NOR" = "40NOR",
  "20FlatRack" = "20FlatRack",
  "40FlatRack" = "40FlatRack",
  "OpenTop" = "OpenTop",
  "RollOnRollOff" = "RollOnRollOff",
}

export enum CargoMaritimeTerminal {
  Montecon = "Montecon",
  TCP = "TCP",
}

export enum CargoStatus {
  pending = "pending",
  reserved = "reserved",
  shipped = "shipped",
  arrived = "arrived",
  closed = "closed",
  cancelled = "cancelled",
  deposit = "deposit",
}

export const getCargoTypeName = (type: string) => {
  if (type === CargoTypeEnum.IM) return "Importación Marítima";
  if (type === CargoTypeEnum.EM) return "Exportación Marítima";
  if (type === CargoTypeEnum.IA) return "Importación Aérea";
  if (type === CargoTypeEnum.EA) return "Exportación Aérea";
  if (type === CargoTypeEnum.IT) return "Importación Terrestre";
  if (type === CargoTypeEnum.ET) return "Exportación Terrestre";
  if (type === CargoTypeEnum.DA) return "Despacho Aduanero";
};

export const isMaritimeCargo = (type: string | null) => {
  if (type === CargoTypeEnum.IM || type === CargoTypeEnum.EM) return true;

  return false;
};

export const isAerealCargo = (type: string | null) => {
  if (type === CargoTypeEnum.IA || type === CargoTypeEnum.EA) return true;

  return false;
};

export const isGroundCargo = (type: string | null) => {
  if (type === CargoTypeEnum.IT || type === CargoTypeEnum.ET) return true;

  return false;
};

export const isCustomsCargo = (type: string | null) => {
  if (type === CargoTypeEnum.DA) return true;

  return false;
};

export const getCargoIconFromType = (type: string) => {
  if (isMaritimeCargo(type)) return <DirectionsBoatIcon />;
  else if (isAerealCargo(type)) return <FlightIcon />;
  else if (isGroundCargo(type)) return <LocalShippingIcon />;
  else
    return (
      <div style={{ height: 30 }}>
        <img
          src="/img/cargos/aduanero.png"
          alt="aduanero"
          style={{ objectFit: "cover", height: "100%" }}
        />
        ;
      </div>
    );
};

export const getCargoStatusName = (status: string) => {
  if (status === CargoStatus.pending) return "Pendiente";
  if (status === CargoStatus.reserved) return "Reservado";
  if (status === CargoStatus.shipped) return "Embarcado";
  if (status === CargoStatus.arrived) return "Arribado";
  if (status === CargoStatus.closed) return "Cerrado";
  if (status === CargoStatus.cancelled) return "Cancelado";
  if (status === CargoStatus.deposit) return "En deposito";
};
