import { Dispatch, SetStateAction, useState } from "react";
import formValidations from "../../utils/formValidations";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ERROR_EMAIL_TAKEN, Role } from "./constants";
import {
  updateUser,
  selectUpdateUsersStatus,
  createUser,
  selectCreateUsersStatus,
} from "./usersSlice";
import { useNavigate } from "react-router-dom";
import { USERS } from "../../routes";
import UserForm from "./UserForm";
import { Alert, Container, Dialog, Snackbar, Typography } from "@mui/material";
import { NewUserBodyType } from "./usersAPI";
import { useMemo } from "react";

export default function UpdateUser({
  open,
  onClose,
  setCurrentUser,
  currentUser,
  isCreate,
}: {
  open: boolean;
  onClose: (updateTable?: boolean) => void;
  setCurrentUser: Dispatch<SetStateAction<NewUserBodyType>>;
  currentUser: NewUserBodyType;
  isCreate?: boolean;
}) {
  const navigate = useNavigate();

  const createStatus = useAppSelector(selectCreateUsersStatus);
  const updateStatus = useAppSelector(selectUpdateUsersStatus);
  const status = useMemo(
    () => (isCreate ? createStatus : updateStatus),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createStatus, updateStatus],
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formError, setFormError] = useState({
    name: false,
    email: false,
    password: false,
    role: false,
  });

  const dispatch = useAppDispatch();

  const handleOnChange = (label: string, value: string | string[]) => {
    setCurrentUser((data) => ({ ...data, [label]: value }));
    setFormError((errors) => ({ ...errors, [label]: false }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validName =
      currentUser.name.length && formValidations.validateName(currentUser.name);

    const validEmail =
      currentUser.email.length &&
      formValidations.validateEmail(currentUser.email);

    const validPassword =
      (currentUser.password?.length &&
        formValidations.validatePassword(currentUser.password)) ||
      !currentUser.password?.length;

    const validRole = Object.values(Role).includes(
      currentUser.roles[0] as Role,
    );

    if (!validName) {
      setFormError((errors) => ({ ...errors, name: true }));
    }

    if (isCreate && !validEmail) {
      setFormError((errors) => ({ ...errors, email: true }));
    }

    if (!validRole) {
      setFormError((errors) => ({ ...errors, role: true }));
    }

    if (!validPassword && currentUser.password?.length) {
      setFormError((errors) => ({ ...errors, password: true }));
    }

    if (validName && validPassword && validRole) {
      if (isCreate) {
        if (validEmail) {
          dispatch(createUser(currentUser)).then((value) => {
            if (value.meta.requestStatus === "fulfilled") {
              navigate(USERS);
              onClose(true);
              setOpenSnackbar(true);
            } else {
              setOpenErrorSnackbar(true);
              if (value.payload === ERROR_EMAIL_TAKEN)
                setErrorMessage("El email ya está en uso");
            }
          });
        }
      } else {
        if (!currentUser.password?.length) delete currentUser.password;
        dispatch(updateUser(currentUser)).then((value) => {
          if (value.meta.requestStatus === "fulfilled") {
            navigate(USERS);
            onClose(true);
            setOpenSnackbar(true);
          }
        });
      }
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleCloseErrorSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorSnackbar(false);
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {isCreate ? "Usuario creado" : "Usuario modificado"}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseErrorSnackbar}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          <Typography variant="subtitle2">
            Ocurrió un error al crear el usuario
          </Typography>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={() => onClose()}>
        <Container
          component="main"
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginTop: 4,
            marginBottom: 4,
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            {isCreate ? "Crear usuario" : "Modificar usuario"}
          </Typography>
          <UserForm
            status={status}
            currentUser={currentUser}
            formError={formError}
            handleOnChange={handleOnChange}
            handleSubmit={handleSubmit}
            isUpdate={!isCreate}
            onCancel={() => onClose()}
          />
        </Container>
      </Dialog>
    </>
  );
}
