import { SxProps, Typography } from "@mui/material";
import { Theme } from "@mui/system";

export const TextSubtitle = ({
  style,
  label,
}: {
  style?: SxProps<Theme>;
  label: string | undefined;
}) => {
  return (
    <Typography
      sx={{ fontSize: { sm: 12, xl: 16 }, fontWeight: "400", ...style }}
    >
      {label || "N/A"}
    </Typography>
  );
};
