import { Box, Button, TextField } from "@mui/material";
import { NewCityBodyType } from "./citiesAPI";
import { StatusType } from "../../app/constants";
import LoadingButton from "../shared/LoadingButton";

export default function CityForm({
  currentCity,
  handleSubmit,
  handleOnChange,
  formError,
  status,
  isUpdate,
  onCancel,
}: {
  status: StatusType;
  currentCity: NewCityBodyType;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleOnChange: (label: string, value: string | string[]) => void;
  formError: {
    cityName: boolean;
    cityCountry: boolean;
    code: boolean;
  };
  isUpdate?: boolean;
  onCancel: (update?: boolean) => void;
}) {
  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        value={currentCity.cityName}
        margin="normal"
        required
        fullWidth
        id="cityName"
        label="Nombre"
        name="cityName"
        autoFocus
        type="text"
        onChange={(e) => handleOnChange("cityName", e.target.value)}
        error={formError.cityName}
        helperText={
          formError.cityName ? "Por favor ingrese un nombre válido" : ""
        }
      />

      <TextField
        value={currentCity.cityCountry}
        margin="normal"
        required
        fullWidth
        id="cityCountry"
        label="País"
        name="cityCountry"
        type="text"
        onChange={(e) => handleOnChange("cityCountry", e.target.value)}
        error={formError.cityCountry}
        helperText={
          formError.cityCountry ? "Por favor ingrese un país válido" : ""
        }
      />

      <TextField
        value={currentCity.code}
        margin="normal"
        required
        fullWidth
        name="code"
        label="Código Internacional"
        type="text"
        id="code"
        onChange={(e) => handleOnChange("code", e.target.value)}
        error={formError.code}
        helperText={formError.code ? "Por favor ingrese un código válido" : ""}
      />

      <Box sx={{ display: "flex" }}>
        <Button
          type="button"
          onClick={() => onCancel(false)}
          sx={{ flex: 1, mt: 3, mb: 2, marginRight: 2 }}
        >
          Cancelar
        </Button>
        <LoadingButton
          sx={{ flex: 1 }}
          loading={status === StatusType.loading}
          text={isUpdate ? "Modificar ciudad" : "Crear ciudad"}
          type="submit"
        />
      </Box>
    </Box>
  );
}
