import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { StatusType } from "../../app/constants";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { UserType, GetUsersFilters } from "./usersAPI";
import {
  getUsers,
  selectUsers,
  selectUsersStatus,
  selectTotalUsers,
} from "./usersSlice";

const columns: GridColDef[] = [
  { field: "name", headerName: "Nombre", width: 230 },
  { field: "email", headerName: "Correo electrónico", width: 230 },
];

const SellerSelector = ({
  open,
  toggleModal,
  selectedSellers,
  onSelectExistentSellers,
  canUpdate,
}: {
  open: boolean;
  toggleModal: () => void;
  selectedSellers: UserType[];
  onSelectExistentSellers: (seller: UserType[]) => void;
  canUpdate?: boolean;
}) => {
  const dispatch = useAppDispatch();

  const [pageModel, setPageModel] = useState({ page: 0, pageSize: 10 });
  const [currentSellers, setCurrentSellers] =
    useState<UserType[]>(selectedSellers);
  const [search, setSearch] = useState("");
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>();

  const loading = useAppSelector(selectUsersStatus) === StatusType.loading;
  const users = useAppSelector(selectUsers);
  const total = useAppSelector(selectTotalUsers);

  const onConfirm = () => {
    if (currentSellers?.length) {
      onSelectExistentSellers(currentSellers);
      toggleModal();
    }
  };

  const onDeleteSelectedSellers = (id: string) => {
    const newSellers = selectedSellers?.filter((seller) => {
      return seller._id !== id;
    });

    onSelectExistentSellers(newSellers || []);
    setCurrentSellers(newSellers);
    setRowSelectionModel(newSellers?.map((seller) => seller._id));
  };

  const onRowSelectionModelChange = (
    newRowSelectionModel: GridRowSelectionModel,
  ) => {
    setRowSelectionModel(newRowSelectionModel);

    setCurrentSellers(
      users.filter((user) => newRowSelectionModel.includes(user._id)),
    );
  };

  const getUsersPage = (newFilters?: GetUsersFilters) => {
    const filters: GetUsersFilters = {
      page: newFilters?.page || pageModel.page + 1,
      pageSize: newFilters?.pageSize || pageModel.pageSize,
    };

    if (newFilters?.search !== undefined) filters.search = newFilters.search;
    else if (search) filters.search = search;

    dispatch(getUsers(filters));
  };

  const onFilterModelChange = (model: GridFilterModel) => {
    const newSearchTerm = model.quickFilterValues?.length
      ? model.quickFilterValues[0]
      : "";
    setSearch(newSearchTerm);
    getUsersPage({
      search: newSearchTerm,
    });
  };

  const onPaginationModelChange = (model: GridPaginationModel) => {
    setPageModel({ page: model.page, pageSize: model.pageSize });
    getUsersPage({ page: model.page + 1, pageSize: model.pageSize });
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if (open) {
      getUsersPage();
      setRowSelectionModel(selectedSellers?.map((seller) => seller._id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Paper
        elevation={1}
        sx={{
          padding: 3,
          borderRadius: 5,
          margin: "20px 0",
        }}
      >
        <Typography variant="h6">Vendedores</Typography>
        {selectedSellers?.length > 0 &&
          selectedSellers.map((seller) => (
            <Stack
              direction="row"
              sx={{ alignItems: "center" }}
              key={seller._id}
            >
              <Typography variant="subtitle1" style={{ margin: "10px 0" }}>
                <strong>{seller.name}</strong> - {seller.email}
              </Typography>

              {canUpdate && (
                <IconButton onClick={() => onDeleteSelectedSellers(seller._id)}>
                  <Delete />
                </IconButton>
              )}
            </Stack>
          ))}

        {canUpdate && (
          <Button
            onClick={toggleModal}
            fullWidth
            disableRipple
            variant="outlined"
          >
            {selectedSellers.length ? "Cambiar" : "Seleccionar"} vendedores
          </Button>
        )}
      </Paper>
      <Dialog
        open={open}
        onClose={toggleModal}
        maxWidth="lg"
        sx={{
          // display: { width: { sm: "block", md: "flex" } },
          // justifyContent: "center",
          // margin: "auto",
          "& .MuiDialog-paper": {
            width: { xs: "100%", sm: "80%", md: "60%" },
            maxWidth: "600px",
          },
        }}
      >
        <Container
          component="main"
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginTop: 4,
            marginBottom: 4,
            // width: "100%",
          }}
        >
          <Typography variant="h6" marginBottom={2}>
            Seleccionar vendedores
          </Typography>

          <div style={{ height: 300, width: "100%" }}>
            <DataGrid
              filterMode="server"
              onFilterModelChange={onFilterModelChange}
              paginationMode="server"
              pagination={true}
              paginationModel={pageModel}
              onPaginationModelChange={onPaginationModelChange}
              loading={loading}
              rows={users}
              columns={columns}
              disableColumnMenu
              rowCount={total}
              pageSizeOptions={[1, 10, 15]}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              checkboxSelection
              onRowSelectionModelChange={onRowSelectionModelChange}
              rowSelectionModel={rowSelectionModel}
              slots={{ toolbar: CustomToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          </div>

          <Box
            sx={{
              display: "flex",
              alignItems: "stretch",
              marginLeft: "auto",
              marginTop: 2,
            }}
          >
            <Button
              variant="text"
              sx={{ marginRight: 1 }}
              onClick={toggleModal}
            >
              Cancelar
            </Button>
            <Button
              disabled={!rowSelectionModel?.length}
              variant="contained"
              onClick={onConfirm}
            >
              Seleccionar vendedores
            </Button>
          </Box>
        </Container>
      </Dialog>
    </>
  );
};

export default SellerSelector;
