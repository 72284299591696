import { FC, useState } from "react";
import { Box, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { CargoGroundType, NewCargoBodyType } from "../../cargosAPI";
import ClientSelector from "../../../clients/ClientSelector";
import AgentSelector from "../../../agents/AgentSelector";
import { ClientType } from "../../../clients/clientsAPI";
import { AgentType } from "../../../agents/agentsAPI";
import RoutesForm from "./components/RoutesForm";
import { CommodityForm } from "./components/CommodityForm";
import Finance from "../shared/Finance/Finance";
import { DownloadCargoPdfButtons } from "../../DownloadView/DownloadCargoPdfButtons";
import { CargoFollow } from "../shared/CargoFollowForm/CargoFollow";
import { CargoRating } from "../shared/CargoRating";
import { CargoStatusForm } from "../shared/CargoStatusForm";

interface GroundCargoFormProps {
  currentCargo: NewCargoBodyType;
  handleOnChange: (label: string, value: string | any | null) => void;

  canUpdate?: boolean;
  formError: {
    type: boolean;
    incoterm: boolean;
    client: boolean;
    agent: boolean;
    departureCity: boolean;
    destinationCity: boolean;
    originCity: boolean;
    shipper: boolean;
    finance: boolean;
  };
  isUpdate: boolean;
  currentGroundCargo: CargoGroundType;
  handleGroundCargoOnChange: (label: string, value: any) => void;
  selectedAgent: AgentType | null;
  setSelectedAgent: (agent: AgentType) => void;
  selectedClient: ClientType | null;
  setSelectedClient: (client: ClientType) => void;
}

const GroundCargoForm: FC<GroundCargoFormProps> = ({
  currentCargo,
  handleOnChange,
  canUpdate,
  formError,
  isUpdate,
  currentGroundCargo,
  handleGroundCargoOnChange,
  selectedAgent,
  setSelectedAgent,
  selectedClient,
  setSelectedClient,
}) => {
  const [openSelectClient, setOpenSelectClient] = useState(false);

  const [openSelectAgent, setOpenSelectAgent] = useState(false);

  const toggleSelectClientModal = () => {
    setOpenSelectClient(!openSelectClient);
  };

  const toggleSelectAgentModal = () => {
    setOpenSelectAgent(!openSelectAgent);
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <TextField
          value={currentCargo.shipper}
          margin="normal"
          fullWidth
          name="shipper"
          label="Shipper"
          required
          type="text"
          id="shipper"
          disabled={!canUpdate}
          onChange={(e) => handleOnChange("shipper", e.target.value)}
          error={formError.shipper}
          helperText={formError.shipper ? "Por favor ingrese un shipper" : ""}
        />

        <TextField
          value={currentCargo.consignee}
          margin="normal"
          fullWidth
          name="consignee"
          label="Consignatario"
          type="text"
          disabled={!canUpdate}
          id="consignee"
          onChange={(e) => handleOnChange("consignee", e.target.value)}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <Box flex={1}>
          <ClientSelector
            open={openSelectClient}
            toggleModal={toggleSelectClientModal}
            selectedClient={selectedClient}
            onSelectExistentClient={setSelectedClient}
            error={formError.client}
            canUpdate={canUpdate}
          />
        </Box>
        <Box flex={1}>
          <AgentSelector
            open={openSelectAgent}
            toggleModal={toggleSelectAgentModal}
            selectedAgent={selectedAgent}
            onSelectExistentAgent={setSelectedAgent}
            error={formError.agent}
            canUpdate={canUpdate}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <CargoStatusForm
          canUpdate={canUpdate}
          currentStatus={currentGroundCargo.status}
          handleStatusChange={handleGroundCargoOnChange}
        />

        <TextField
          value={currentGroundCargo.crt}
          margin="normal"
          fullWidth
          name="crt"
          label="Número de CRT"
          type="text"
          id="crt"
          inputProps={{
            form: {
              autocomplete: "off",
            },
          }}
          disabled={!canUpdate}
          onChange={(e) => handleGroundCargoOnChange("crt", e.target.value)}
        />

        <TextField
          value={currentGroundCargo.dua}
          margin="normal"
          fullWidth
          name="dua"
          label="Número de DUA"
          type="text"
          id="dua"
          inputProps={{
            form: {
              autocomplete: "off",
            },
          }}
          disabled={!canUpdate}
          onChange={(e) => handleGroundCargoOnChange("dua", e.target.value)}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          marginTop: "10px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <DatePicker
            label="Fecha de salida"
            value={currentGroundCargo.departureDate}
            disabled={!canUpdate}
            onChange={(value) =>
              handleGroundCargoOnChange &&
              handleGroundCargoOnChange("departureDate", value)
            }
            sx={{ width: "100%" }}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <DatePicker
            label="Fecha de cruce"
            value={currentGroundCargo.crossDate}
            disabled={!canUpdate}
            onChange={(value) =>
              handleGroundCargoOnChange &&
              handleGroundCargoOnChange("crossDate", value)
            }
            sx={{ width: "100%" }}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <DatePicker
            label="Fecha de entrega"
            value={currentGroundCargo.deliveryDate}
            disabled={!canUpdate}
            onChange={(value) =>
              handleGroundCargoOnChange &&
              handleGroundCargoOnChange("deliveryDate", value)
            }
            sx={{ width: "100%" }}
          />
        </Box>
      </Box>
      <RoutesForm
        currentCargo={currentCargo}
        formError={formError}
        handleOnChange={handleOnChange}
        canUpdate={canUpdate}
      />
      <CommodityForm
        currentCargo={currentCargo}
        handleOnChange={handleOnChange}
        canUpdate={canUpdate}
      />

      <Finance
        finance={currentCargo.currentFinance || []}
        setFinance={(finance) =>
          handleOnChange && handleOnChange("currentFinance", finance)
        }
        financeError={formError.finance}
        canUpdate={canUpdate}
      />

      {isUpdate && (
        <DownloadCargoPdfButtons
          currentCargo={currentCargo}
          cargoClient={selectedClient ?? undefined}
        />
      )}

      <CargoFollow
        follows={currentCargo.follows}
        handleOnChange={handleOnChange}
      />

      <CargoRating
        currentCargo={currentCargo}
        handleOnChange={handleOnChange}
        canUpdate={canUpdate}
      />
    </Box>
  );
};

export default GroundCargoForm;
