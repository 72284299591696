import { Text, View } from "@react-pdf/renderer";

import { IRowElement } from "../../../interfaces/RowElementInterface";
import styles from "./styles";

export const RowElement = ({ values }: { values: IRowElement }) => {
  return (
    <View style={styles.tableRow}>
      <View style={{ width: "50%" }}>
        <View style={styles.rowElement}>
          <Text style={styles.rowElementTitle}>{values.leftColumn.title}:</Text>
          <Text style={styles.rowElementSubtitle}>
            {values.leftColumn.subtitle}
          </Text>
        </View>
      </View>
      <View style={{ width: "50%" }}>
        <View style={styles.rowElement}>
          <Text style={styles.rowElementTitle}>
            {values.rigthColumn.title}:
          </Text>
          <Text style={styles.rowElementSubtitle}>
            {values.rigthColumn.subtitle}
          </Text>
        </View>
      </View>
    </View>
  );
};
