import { Font, Text, Image, View } from "@react-pdf/renderer";

import styles from "./styles";

export const TemplateHeader = ({ title }: { title: string }) => {
  Font.register({
    family: "Open Sans",
    fonts: [
      {
        src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
      },
      {
        src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
        fontWeight: 600,
      },
      {
        src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
        fontWeight: 800,
      },
    ],
  });

  return (
    <View style={styles.header}>
      <Image src="/img/tailored-logo-complete.png" style={styles.logo} />
      <Text style={styles.logoText}>{title}</Text>
    </View>
  );
};
