import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusType } from "../../app/constants";
import { RootState } from "../../app/store";
import cargosAPI, {
  CargoType,
  GetCargosFilters,
  NewCargoBodyType,
} from "./cargosAPI";

export interface CargosState {
  getCargosStatus: StatusType;
  cargos: CargoType[];
  totalCargos: number;

  getCargoByIdStatus: StatusType;

  createCargoStatus: StatusType;
  updateCargoStatus: StatusType;
}

const initialState: CargosState = {
  getCargosStatus: StatusType.idle,
  cargos: [],
  totalCargos: 0,

  getCargoByIdStatus: StatusType.idle,

  createCargoStatus: StatusType.idle,
  updateCargoStatus: StatusType.idle,
};

export const getCargos = createAsyncThunk(
  "cargos/getCargos",
  async (filters: GetCargosFilters) => {
    const response = await cargosAPI.getCargos(filters);

    return response.data;
  },
);

export const getCargoByIdOrNumber = createAsyncThunk(
  "cargos/getCargoByIdOrNumber",
  async (id: string) => {
    const response = await cargosAPI.getCargoByIdOrNumber(id);
    return response.data;
  },
);

export const createCargo = createAsyncThunk(
  "cargos/createCargo",
  async (body: NewCargoBodyType, { rejectWithValue }) => {
    try {
      const response = await cargosAPI.createCargo(body);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.message);
    }
  },
);

export const updateCargo = createAsyncThunk(
  "cargos/updateCargo",
  async (body: NewCargoBodyType, { rejectWithValue }) => {
    try {
      const response = await cargosAPI.updateCargo(body);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.message);
    }
  },
);

export const cargosSlice = createSlice({
  name: "cargos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCargos.pending, (state) => {
        state.getCargosStatus = StatusType.loading;
      })
      .addCase(getCargos.fulfilled, (state, action) => {
        state.getCargosStatus = StatusType.succeeded;
        state.totalCargos = action.payload.totalDocs;
        state.cargos = action.payload.docs.map((doc: CargoType) => ({
          ...doc,
          id: doc._id,
        }));
      })
      .addCase(getCargos.rejected, (state) => {
        state.getCargosStatus = StatusType.failed;
      })

      .addCase(getCargoByIdOrNumber.pending, (state) => {
        state.getCargoByIdStatus = StatusType.loading;
      })
      .addCase(getCargoByIdOrNumber.fulfilled, (state) => {
        state.getCargoByIdStatus = StatusType.succeeded;
      })
      .addCase(getCargoByIdOrNumber.rejected, (state) => {
        state.getCargoByIdStatus = StatusType.failed;
      })

      .addCase(createCargo.pending, (state) => {
        state.createCargoStatus = StatusType.loading;
      })
      .addCase(createCargo.fulfilled, (state) => {
        state.createCargoStatus = StatusType.succeeded;
      })
      .addCase(createCargo.rejected, (state) => {
        state.createCargoStatus = StatusType.failed;
      })

      .addCase(updateCargo.pending, (state) => {
        state.updateCargoStatus = StatusType.loading;
      })
      .addCase(updateCargo.fulfilled, (state) => {
        state.updateCargoStatus = StatusType.succeeded;
      })
      .addCase(updateCargo.rejected, (state) => {
        state.updateCargoStatus = StatusType.failed;
      });
  },
});

export const selectCargosStatus = (state: RootState) =>
  state.cargos.getCargosStatus;
export const selectCargoByIdStatus = (state: RootState) =>
  state.cargos.getCargoByIdStatus;
export const selectTotalCargos = (state: RootState) => state.cargos.totalCargos;
export const selectCargos = (state: RootState) => state.cargos.cargos;

export const selectCreateCargosStatus = (state: RootState) =>
  state.cargos.createCargoStatus;
export const selectUpdateCargosStatus = (state: RootState) =>
  state.cargos.updateCargoStatus;

export default cargosSlice.reducer;
