import type {} from "@mui/x-data-grid/themeAugmentation";
import {
  Avatar,
  Box,
  Button,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  getAlertChipFromType,
  getCargoIconFromType,
} from "../../../app/constants";
import { AlertType } from "../dashboardAPI";
import { CARGOS } from "../../../routes";

import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { markAlertAsRead } from "../dashboardSlice";
import { selectProfile } from "../../signIn/signInSlice";
import { useMemo } from "react";
import { Role } from "../../users/constants";

export const AlertItem = ({ alert }: { alert: AlertType }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);

  const isSeller = useMemo(
    () =>
      !(
        profile?.roles?.includes(Role.admin) ||
        profile?.roles?.includes(Role.customerService)
      ),
    [profile],
  );

  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();

    navigate(`${CARGOS}/${alert.cargo._id}`);
  };

  const markAsRead = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();

    dispatch(markAlertAsRead(alert._id));
  };

  return (
    <ListItemButton key={alert._id} divider onClick={onClick}>
      <ListItemAvatar>
        <Avatar
          sx={{
            color: "primary.main",
            bgcolor: "rgb(230, 247, 255)",
          }}
        >
          {alert?.cargo?.type && getCargoIconFromType(alert.cargo.type)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box display="flex" sx={{ gap: "10px" }}>
            <Typography variant="subtitle2" sx={{ alignSelf: "center" }}>
              Carga {alert?.cargo?.type}-{alert?.cargo?.number}
            </Typography>
          </Box>
        }
        secondary={new Date(alert.createdAt).toLocaleDateString()}
      />

      <ListItemSecondaryAction>
        <Stack alignItems="end">
          {getAlertChipFromType(alert.type)}
          <Button></Button>
          {!isSeller && (
            <NotificationsOffIcon
              color="primary"
              fontSize={"small"}
              onClick={markAsRead}
              sx={{ margin: "0", marginTop: "-5px" }}
            />
          )}
        </Stack>
      </ListItemSecondaryAction>
    </ListItemButton>
  );
};
