import React, { useState } from "react";
import { Box, Divider, Tab, Tabs, Typography } from "@mui/material";

import { NewCargoBodyType } from "../../../cargosAPI";
import { Route } from "./components/Route";
import { Commodity } from "./components/Commodity";
import { FinanceTable } from "../shared/FinanceTable";
import { CargoFollowGrid } from "../../../CargoForm/shared/CargoFollowForm/components/CargoFollowGrid";
import { TextTitle } from "../shared/TextTitle";
import { TextSubtitle } from "../shared/TextSubtitle";
import { FollowForm } from "../shared/FollowForm";
import { FinanceTypeEnum } from "../../../../../app/constants";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      width={"100%"}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

export const CustomCargoDisplay = ({
  currentCargo,
  cargoId,
  canUpdate,
}: {
  currentCargo: NewCargoBodyType;
  cargoId: string;
  canUpdate: boolean;
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [cargoFollows, setCargoFollows] = useState(currentCargo.follows || []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: { xs: 4, lg: 8 },
        maxHeight: "100%",
        width: "100%",
      }}
    >
      <Box
        flex={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          maxHeight: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            gap: 4,
          }}
        >
          <Box>
            <TextTitle label="Estado de carga" />
            <TextSubtitle label={currentCargo.ground?.status} />
          </Box>
          <Box>
            <TextTitle label="Número de conocimiento" />
            <TextSubtitle label={currentCargo.customs?.knowledgeNumber} />
          </Box>
          <Box>
            <TextTitle label="Shipper" />
            <TextSubtitle label={currentCargo?.shipper} />
          </Box>

          <Box>
            <TextTitle label="Consignatario" />
            <TextSubtitle label={currentCargo?.consignee} />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 4,
          }}
        >
          <Box>
            <TextTitle label="Número de DUA" />
            <TextSubtitle label={currentCargo.customs?.duaNumber} />
          </Box>
          <Box>
            <TextTitle label="Despachante" />
            <TextSubtitle label={currentCargo.customs?.dispatcher} />
          </Box>
        </Box>

        <Box
          sx={{ display: "flex", flexDirection: "column", maxHeight: "30vh" }}
        >
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", flex: "0 0 auto" }}
          >
            <Tabs
              scrollButtons={"auto"}
              allowScrollButtonsMobile
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Rutas"
                id="simple-tab-0"
                aria-controls="simple-tabpanel-0"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingLeft: 0,
                  fontSize: { sm: 12, xl: 14 },
                }}
              />
              <Tab
                label="Commodity"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingLeft: 0,
                  fontSize: { sm: 12, xl: 14 },
                }}
              />
              <Tab
                label="Ventas"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingLeft: 0,
                  fontSize: { sm: 12, xl: 14 },
                }}
              />
              <Tab
                label="Costos"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingLeft: 0,
                  fontSize: { sm: 12, xl: 14 },
                }}
              />
            </Tabs>
          </Box>
          <Box
            sx={{
              display: "flex",
              overflow: "auto",
              flex: "0 1 auto",
            }}
          >
            <TabPanel value={value} index={0}>
              <Route currentCargo={currentCargo} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Commodity currentCargo={currentCargo} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <FinanceTable
                finances={
                  currentCargo.finance?.filter(
                    (x) => x.type === FinanceTypeEnum.sale,
                  ) || []
                }
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <FinanceTable
                finances={
                  currentCargo.finance?.filter(
                    (x) => x.type === FinanceTypeEnum.cost,
                  ) || []
                }
              />
            </TabPanel>
          </Box>
        </Box>
      </Box>

      <Divider sx={{ display: { md: "none" }, borderBottomWidth: 3 }} />
      <Box
        flex={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          maxHeight: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 3,
              width: "40vh",
            }}
          >
            <Box width={"50%"}>
              <TextTitle label="Client" />
              <TextSubtitle label={currentCargo.client} />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "column", maxHeight: "60%" }}
        >
          <Typography
            sx={{ fontSize: 18, fontWeight: "700", flex: "0 0 auto" }}
          >
            Comentarios
          </Typography>
          <Box sx={{ overflow: "auto", flex: "0 1 auto" }}>
            <CargoFollowGrid follows={cargoFollows} />
          </Box>
          {canUpdate && (
            <Box sx={{ flex: "0 0 auto" }}>
              <FollowForm cargoId={cargoId} setCargoFollows={setCargoFollows} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
