import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusType } from "../../app/constants";
import { RootState } from "../../app/store";
import dashboardAPI, {
  AlertType,
  GetAlertsFilters,
  MetricsType,
} from "./dashboardAPI";

export interface UsersState {
  getAlertsStatus: StatusType;
  alerts: AlertType[];
  totalAlerts: number;

  getMetricsStatus: StatusType;
  metrics?: MetricsType;

  markAlertAsReadStatus: StatusType;
}

const initialState: UsersState = {
  getAlertsStatus: StatusType.idle,
  alerts: [],
  totalAlerts: 0,

  getMetricsStatus: StatusType.idle,
  metrics: undefined,

  markAlertAsReadStatus: StatusType.idle,
};

export const getAlerts = createAsyncThunk(
  "dashboard/getAlerts",
  async (filters: GetAlertsFilters) => {
    const response = await dashboardAPI.getAlerts(filters);
    return response.data;
  },
);

export const markAlertAsRead = createAsyncThunk(
  "dashboard/markAlertAsRead",
  async (alertId: string, { dispatch }) => {
    const response = await dashboardAPI.markAlertAsRead(alertId);
    if (response.status === 200) {
      dispatch(getAlerts({ page: 1, pageSize: 20 }));
    }
    return response.data;
  },
);

export const getMetrics = createAsyncThunk("dashboard/getMetrics", async () => {
  const response = await dashboardAPI.getMetrics();
  return response.data;
});

export const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAlerts.pending, (state) => {
        state.getAlertsStatus = StatusType.loading;
      })
      .addCase(getAlerts.fulfilled, (state, action) => {
        state.getAlertsStatus = StatusType.succeeded;
        state.totalAlerts = action.payload.totalDocs;
        state.alerts = action.payload.docs.map((doc: AlertType) => ({
          ...doc,
          id: doc._id,
        }));
      })
      .addCase(getAlerts.rejected, (state) => {
        state.getAlertsStatus = StatusType.failed;
      })

      .addCase(getMetrics.pending, (state) => {
        state.getMetricsStatus = StatusType.loading;
      })
      .addCase(getMetrics.fulfilled, (state, action) => {
        state.getMetricsStatus = StatusType.succeeded;
        state.metrics = action.payload;
      })
      .addCase(getMetrics.rejected, (state) => {
        state.getMetricsStatus = StatusType.failed;
      });
  },
});

export const selectAlertsStatus = (state: RootState) =>
  state.dashboard.getAlertsStatus;
export const selectTotalAlerts = (state: RootState) =>
  state.dashboard.totalAlerts;
export const selectAlerts = (state: RootState) => state.dashboard.alerts;

export const selectMetricsStatus = (state: RootState) =>
  state.dashboard.getMetricsStatus;
export const selectMetrics = (state: RootState) => state.dashboard.metrics;

export default alertsSlice.reducer;
