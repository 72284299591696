import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 14,
    color: "#4C265C",
    fontStyle: "normal",
    fontWeight: "normal",
    marginBottom: 15,
    border: "1px solid black",
  },
  rowElement: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
    marginVertical: 5,
  },
  rowElementTitle: {
    fontSize: 8,
    fontFamily: "Open Sans",
    fontWeight: 800,
    lineHeight: 1.16,
    marginBottom: 5,
    color: "black",
  },
  rowElementSubtitle: {
    fontSize: 9,
    fontWeight: 400,
    textTransform: "uppercase",
    color: "black",
  },
});

export default styles;
