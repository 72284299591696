export enum ClientStatus {
  active = "active",
  inactive = "inactive",
  potential = "potential",
}

export const getStatusName = (status: string) => {
  if (status === ClientStatus.active) return "Activo";
  if (status === ClientStatus.inactive) return "Inactivo";
  if (status === ClientStatus.potential) return "Potencial";
};
