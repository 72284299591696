import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  GridRowId,
  GridSortModel,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid";
import type {} from "@mui/x-data-grid/themeAugmentation";
import { Button, Chip, Container, MenuItem, Modal, Box } from "@mui/material";
import { Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  CargoStatus,
  CargoTypeEnum,
  getCargoIconFromType,
  getCargoStatusName,
  getCargoTypeName,
  StatusType,
} from "../../app/constants";
import CustomFilterSection from "../shared/FiltersSection";
import cargosAPI, { CargoType, GetCargosFilters } from "./cargosAPI";
import {
  getCargos,
  selectCargos,
  selectCargosStatus,
  selectTotalCargos,
} from "./cargosSlice";
import { CARGOS, CREATE_CARGO } from "../../routes";
import { selectProfile } from "../signIn/signInSlice";
import { Role } from "../users/constants";
import { getUsers, selectUsers } from "../users/usersSlice";
import { getClients, selectClients } from "../clients/clientsSlice";
import {
  CARGO_STATUS_ARRIVED_COLOR,
  CARGO_STATUS_CANCELLED_COLOR,
  CARGO_STATUS_CLOSED_COLOR,
  CARGO_STATUS_DEPOSIT_COLOR,
  CARGO_STATUS_PENDING_COLOR,
  CARGO_STATUS_RESERVED_COLOR,
  CARGO_STATUS_SHIPPED_COLOR,
} from "../../utils/constants";
import { getAgents, selectAgents } from "../agents/agentsSlice";
import { DateTime } from "luxon";
import { exportDataToExcel } from "../../utils/export-data-to-excel";
import { UserType } from "../users/usersAPI";
import { CityType } from "../cities/citiesAPI";
import { CargoModal } from "./CargoModal/CargoModal";
import { ClientType } from "../clients/clientsAPI";
import { getCargoRef } from "../../utils/cargo";
import StatusFilter from "./CustomFilters/StatusFilter";

const CreateButtonStyle: CSSProperties = {
  marginBottom: 20,
  paddingLeft: 15,
  paddingRight: 15,
};

export default function DataTable() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loading = useAppSelector(selectCargosStatus) === StatusType.loading;
  const cargos = useAppSelector(selectCargos);
  const total = useAppSelector(selectTotalCargos);
  const profile = useAppSelector(selectProfile);
  const sellers = useAppSelector(selectUsers);
  const clients = useAppSelector(selectClients);
  const agents = useAppSelector(selectAgents);

  const [searchParams, setSearchParams] = useSearchParams();

  const [pageModel, setPageModel] = useState({ page: 0, pageSize: 10 });
  const [search, setSearch] = useState("");
  const [sortModel, setSortModel] = useState<any>();
  const [cargoId, setCargoId] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const isAdmin = useMemo(
    () =>
      (profile?.roles?.includes(Role.admin) ||
        profile?.roles?.includes(Role.customerService)) ??
      false,
    [profile],
  );

  const canUpdate = useCallback(
    (cargoClient?: ClientType) => {
      return (
        isAdmin ||
        (profile && (cargoClient?.sellers as string[])?.includes(profile._id))
      );
    },
    [isAdmin, profile],
  );

  const [filters, setFilters] = useState<{
    statusFilter: string[];
    filterSellers: string[];
    filterClients: string[];
    filterTypes?: string;
    filterAgents: string[];
    filterMinEta: DateTime | undefined;
    filterMaxEta: DateTime | undefined;
  }>({
    statusFilter: searchParams.get("status")
      ? [searchParams.get("status")!]
      : [],
    filterSellers: profile && !isAdmin ? [profile?._id] : [],
    filterClients: [],
    filterTypes: "all",
    filterAgents: [],
    filterMinEta: searchParams.get("mineta")
      ? DateTime.fromISO(searchParams.get("mineta")!)
      : undefined,
    filterMaxEta: undefined,
  });

  const getCargosPage = (newFilters?: GetCargosFilters) => {
    const {
      filterAgents,
      filterClients,
      filterMaxEta,
      filterMinEta,
      filterSellers,
      statusFilter,
      filterTypes,
    } = filters;

    const minEtaFilter = filterMinEta ? filterMinEta.toISODate() : undefined;
    const maxEtaFilter = filterMaxEta
      ? filterMaxEta.plus({ days: 1 }).toISODate()
      : undefined;

    dispatch(
      getCargos({
        page: pageModel.page + 1,
        pageSize: pageModel.pageSize,
        search,
        status: statusFilter.length ? statusFilter : undefined,
        seller: filterSellers.length ? filterSellers[0] : undefined,
        client: filterClients.length ? filterClients[0] : undefined,
        agent: filterAgents.length ? filterAgents[0] : undefined,
        minEta: minEtaFilter ?? undefined,
        maxEta: maxEtaFilter ?? undefined,
        sortField: sortModel?.length > 0 ? sortModel[0].field : undefined,
        sortValue: sortModel?.length > 0 ? sortModel[0].sort : undefined,
        type: filterTypes === "all" ? undefined : filterTypes,
        ...newFilters,
      }),
    );
  };

  useEffect(() => {
    getCargosPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sortModel]);

  const getSellersPage = (search_val?: string) =>
    dispatch(
      getUsers({
        page: pageModel.page + 1,
        pageSize: 5,
        search: search_val,
      }),
    );

  const getClientsPage = (search_val?: string) =>
    dispatch(
      getClients({
        page: pageModel.page + 1,
        pageSize: 5,
        search: search_val,
      }),
    );

  const watchCargoInfo = (id: string) => {
    setCargoId(id);
    setOpenModal(true);
  };

  const getAgentsPage = (search_val?: string) =>
    dispatch(
      getAgents({
        page: pageModel.page + 1,
        pageSize: 5,
        search: search_val,
      }),
    );

  const resetFilters = () => {
    setFilters({
      filterAgents: [],
      filterClients: [],
      filterTypes: "all",
      filterMaxEta: undefined,
      filterMinEta: undefined,
      filterSellers: profile && !isAdmin ? [profile?._id] : [],
      statusFilter: [],
    });
    setSearch("");

    navigate(CARGOS);
  };

  const onClickAddButton = () => {
    navigate(CREATE_CARGO);
  };

  const updateCargo = (id: GridRowId) => {
    navigate(`${id}`);
  };

  const columns: GridColDef[] = [
    {
      field: "type",
      headerName: "",
      renderCell: (params) => {
        const row: CargoType = params.row;
        return getCargoIconFromType(row.type);
      },
      width: 60,
      filterable: false,
      sortable: true,
    },
    {
      field: "cargoRef",
      valueGetter: (params) => {
        const row: CargoType = params.row;
        return row.cargoRef ?? getCargoRef(row);
      },
      headerName: "Ref Carga",
      width: 105,
      filterable: false,
      sortable: true,
    },
    {
      field: "client.name",
      valueGetter: (params) => {
        const row: CargoType = params.row;
        return row.client?.name;
      },
      headerName: "Cliente",
      width: 200,
      filterable: false,
      sortable: true,
    },
    {
      field: "consignee",
      headerName: "Consignatario",
      width: 150,
      editable: false,
      filterable: false,
      sortable: true,
    },
    {
      field: "shipper",
      headerName: "Shipper",
      width: 150,
      editable: false,
      filterable: false,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Estado",
      width: 150,
      sortComparator: gridStringOrNumberComparator,
      valueGetter: (params) => {
        const row: CargoType = params.row;
        const status = row.status;

        return status;
      },
      renderCell: (params) => {
        const row: CargoType = params.row;
        const status = row.status;

        switch (status) {
          case CargoStatus.pending:
            return (
              <Chip
                label={getCargoStatusName(CargoStatus.pending)}
                sx={{ backgroundColor: CARGO_STATUS_PENDING_COLOR }}
              />
            );

          case CargoStatus.reserved:
            return (
              <Chip
                label={getCargoStatusName(CargoStatus.reserved)}
                sx={{ backgroundColor: CARGO_STATUS_RESERVED_COLOR }}
              />
            );

          case CargoStatus.shipped:
            return (
              <Chip
                label={getCargoStatusName(CargoStatus.shipped)}
                sx={{ backgroundColor: CARGO_STATUS_SHIPPED_COLOR }}
              />
            );

          case CargoStatus.arrived:
            return (
              <Chip
                label={getCargoStatusName(CargoStatus.arrived)}
                sx={{ backgroundColor: CARGO_STATUS_ARRIVED_COLOR }}
              />
            );
          case CargoStatus.closed:
            return (
              <Chip
                label={getCargoStatusName(CargoStatus.closed)}
                sx={{ backgroundColor: CARGO_STATUS_CLOSED_COLOR }}
              />
            );
          case CargoStatus.deposit:
            return (
              <Chip
                label={getCargoStatusName(CargoStatus.deposit)}
                sx={{ backgroundColor: CARGO_STATUS_DEPOSIT_COLOR }}
              />
            );
          case CargoStatus.cancelled:
            return (
              <Chip
                label={getCargoStatusName(CargoStatus.cancelled)}
                sx={{ backgroundColor: CARGO_STATUS_CANCELLED_COLOR }}
              />
            );

          default:
            return "N/A";
        }
      },
      editable: false,
      filterable: false,
      sortable: true,
    },
    {
      field: "agent.name",
      valueGetter: (params) => {
        const row: CargoType = params.row;
        return row.agent?.name ?? "N/A";
      },
      headerName: "Agente",
      width: 200,
      editable: false,
      filterable: false,
      sortable: true,
    },
    {
      field: "agentReference",
      valueGetter: (params) => {
        const row: CargoType = params.row;
        return !row.agent?.name ? "N/A" : row.agentReference;
      },
      headerName: "Referencia Agente",
      width: 180,
      editable: false,
      filterable: false,
      sortable: true,
    },
    {
      field: "eta",
      valueGetter: (params) => {
        const row: CargoType = params.row;
        return row.eta ? new Date(row.eta).toLocaleDateString() : "N/A";
      },
      headerName: "ETA",
      width: 200,
      editable: false,
      filterable: false,
      sortable: true,
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        ...(canUpdate((params.row as CargoType).client)
          ? [
              <GridActionsCellItem
                icon={<Edit />}
                label="Modificar"
                onClick={() => updateCargo(params.id)}
              />,
            ]
          : []),
        <GridActionsCellItem
          icon={<VisibilityIcon />}
          label="Modificar"
          onClick={() => watchCargoInfo(params.id.toString())}
        />,
      ],
      filterable: false,
      sortable: true,
    },
  ];

  const onPaginationModelChange = (model: GridPaginationModel) => {
    setPageModel({ page: model.page, pageSize: model.pageSize });
    getCargosPage({ page: model.page + 1, pageSize: model.pageSize });
  };

  const handleStatusChange = (status: string[]) => {
    setFilters((current) => ({ ...current, statusFilter: status }));
  };

  useEffect(() => {
    if (search) {
      getCargosPage({
        search: search,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    getSellersPage();
    getClientsPage();
    getAgentsPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSellerInputChange = (input: string) => {
    getSellersPage(input);
  };
  const handleClientInputChange = (input: string) => {
    getClientsPage(input);
  };
  const handleAgentInputChange = (input: string) => {
    getAgentsPage(input);
  };

  const onSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
  };

  const getCargoDataToExcel = async () => {
    const {
      filterAgents,
      filterClients,
      filterMaxEta,
      filterMinEta,
      filterSellers,
      statusFilter,
    } = filters;

    const minEtaFilter = filterMinEta ? filterMinEta.toISODate() : undefined;
    const maxEtaFilter = filterMaxEta
      ? filterMaxEta.plus({ days: 1 }).toISODate()
      : undefined;

    const cargoFilters: GetCargosFilters = {
      search,
      status: statusFilter.length ? statusFilter : undefined,
      seller: filterSellers.length ? filterSellers[0] : undefined,
      client: filterClients.length ? filterClients[0] : undefined,
      agent: filterAgents.length ? filterAgents[0] : undefined,
      minEta: minEtaFilter ?? undefined,
      maxEta: maxEtaFilter ?? undefined,
      sortField: sortModel?.length > 0 ? sortModel[0].field : undefined,
      sortValue: sortModel?.length > 0 ? sortModel[0].value : undefined,
    };

    const apiData = await cargosAPI.getCargoDataToExport(cargoFilters);

    const customHeadings = apiData?.data?.docs?.map((cargo: CargoType) => {
      let status;
      let etd;
      let eta = (cargo.eta as string)?.split("T")[0];
      let vessel;
      let mbl;
      let container;
      let containerType;
      if (cargo.type === CargoTypeEnum.IM || cargo.type === CargoTypeEnum.EM) {
        status = cargo.maritime?.status;
        etd = (cargo.maritime?.etd as string)?.split("T")[0];
        vessel = cargo.maritime?.vessel;
        mbl = cargo.maritime?.mbl.number;
        container = cargo.maritime?.containers.map((c) => c.number).join(", ");
        containerType = cargo.maritime?.containers
          .map((c) => c.type)
          .join(", ");
      } else if (
        cargo.type === CargoTypeEnum.IA ||
        cargo.type === CargoTypeEnum.EA
      ) {
        status = cargo.aereal?.status;
        etd = (cargo.aereal?.etd as string)?.split("T")[0];
        vessel = "N/A";
        mbl = "N/A";
        container = "N/A";
        containerType = "N/A";
      } else if (
        cargo.type === CargoTypeEnum.IT ||
        cargo.type === CargoTypeEnum.ET
      ) {
        status = cargo.ground?.status;
        etd = (cargo.ground?.departureDate as string)?.split("T")[0];
        vessel = "N/A";
        mbl = "N/A";
        container = "N/A";
        containerType = "N/A";
      } else {
        etd = "N/A";
        eta = "N/A";
        vessel = "N/A";
        mbl = "N/A";
        container = "N/A";
        containerType = "N/A";
      }

      return {
        "Vendedor/es": (cargo.client?.sellers as UserType[])
          ?.map((s: UserType) => s.name)
          .join(", "),
        Código: `${cargo.type}-${cargo.number}`,
        CNEE: cargo.consignee,
        Shipper: cargo.shipper,
        ETD: etd,
        ETA: eta,
        Status: status ? getCargoStatusName(status) : "N/A",
        Origen: (cargo.cities.departure as CityType)?.cityName,
        Destino: (cargo.cities.destination as CityType)?.cityName,
        Carrier: cargo.responsible,
        Buque: vessel,
        MBL: mbl,
        Contenedor: container,
        Bultos: cargo.commodity.quantities?.package ?? "",
        Kilos: cargo.commodity.quantities?.weight ?? "",
        CBM: cargo.commodity.quantities?.height ?? "",
        "T. Carga": containerType,
      };
    });

    exportDataToExcel(
      customHeadings,
      `cargas-${new Date().toISOString().split("T")[0]}`,
    );
  };

  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        height: "calc(100vh - 115px)",
        marginTop: 8,
      }}
    >
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <CargoModal cargoId={cargoId} />
      </Modal>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          aria-label="add"
          style={CreateButtonStyle}
          onClick={onClickAddButton}
        >
          <AddIcon />
          <span>Crear</span>
        </Button>
        <Button
          variant="contained"
          color="primary"
          aria-label="add"
          style={CreateButtonStyle}
          onClick={getCargoDataToExcel}
        >
          <FileDownloadIcon />
          <span>Exportar</span>
        </Button>
      </Box>

      <div style={{ height: "72%", width: "100%" }}>
        <CustomFilterSection
          searchValue={search}
          setSearchValue={setSearch}
          // status filter
          hasStatusFilter
          customStatusComponent={
            <StatusFilter
              setStatusFilter={handleStatusChange}
              statusFilter={filters.statusFilter}
            />
          }
          //  seller filter
          hasSellerFilter
          sellers={sellers}
          setSellerFilter={(e) =>
            setFilters((current) => ({ ...current, filterSellers: e }))
          }
          defaultSeller={isAdmin ? undefined : profile}
          handleSellerInputChange={handleSellerInputChange}
          // agent filter
          hasAgentFilter
          agents={agents}
          setAgentsFilter={(e) =>
            setFilters((current) => ({ ...current, filterAgents: e }))
          }
          handleAgentsInputChange={handleAgentInputChange}
          //  client filter
          hasClientFilter
          clients={clients}
          setClientsFilter={(e) =>
            setFilters((current) => ({ ...current, filterClients: e }))
          }
          handleClientsInputChange={handleClientInputChange}
          // eta filter
          hasEtaFilter
          setFilterMinEta={(e) =>
            setFilters((current) => ({ ...current, filterMinEta: e }))
          }
          setFilterMaxEta={(e) =>
            setFilters((current) => ({ ...current, filterMaxEta: e }))
          }
          resetFilters={resetFilters}
          clientFilter={filters.filterClients}
          agentFilter={filters.filterAgents}
          sellerFilter={filters.filterSellers}
          minEtaFilter={filters.filterMinEta}
          maxEtaFilter={filters.filterMaxEta}
          //  type filter
          hasTypeFilter
          typeFilter={filters.filterTypes}
          setTypesFilter={(e) =>
            setFilters((current) => ({ ...current, filterTypes: e }))
          }
          types={Object.entries(CargoTypeEnum).map(([, value]) => (
            <MenuItem value={value}>{getCargoTypeName(value)}</MenuItem>
          ))}
        />
        <DataGrid
          sx={{
            borderRadius: "0px 0px 5px 5px",
            border: "1px lightgray solid",
            borderTop: "unset",
            "& .MuiDataGrid-columnHeaderTitleContainer": { padding: "0 10px" },
            "& .MuiDataGrid-cell": { padding: "0 20px" },
            "& .MuiDataGrid-row": { cursor: "pointer" },
          }}
          filterMode="server"
          paginationMode="server"
          pagination={true}
          paginationModel={pageModel}
          onPaginationModelChange={onPaginationModelChange}
          loading={loading}
          rows={cargos}
          columns={columns}
          disableColumnMenu
          rowCount={total}
          pageSizeOptions={[5, 10, 25]}
          onRowClick={(params) => watchCargoInfo(params.id.toString())}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
        />
      </div>
    </Container>
  );
}
