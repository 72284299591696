import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusType } from "../../app/constants";
import { RootState } from "../../app/store";
import clientsAPI, {
  GetClientsFilters,
  ClientBodyType,
  ClientType,
} from "./clientsAPI";

export interface ClientsState {
  getClientsStatus: StatusType;
  clients: ClientType[];
  totalClients: number;

  createClientStatus: StatusType;
  updateClientStatus: StatusType;
  getClientByIdStatus: StatusType;
}

const initialState: ClientsState = {
  getClientsStatus: StatusType.idle,
  clients: [],
  totalClients: 0,

  createClientStatus: StatusType.idle,
  updateClientStatus: StatusType.idle,
  getClientByIdStatus: StatusType.idle,
};

export const getClients = createAsyncThunk(
  "clients/getClients",
  async (filters: GetClientsFilters) => {
    const response = await clientsAPI.getClients(filters);
    return response.data;
  },
);

export const createClient = createAsyncThunk(
  "clients/createClient",
  async (body: ClientBodyType, { rejectWithValue }) => {
    try {
      const response = await clientsAPI.createClient(body);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.message);
    }
  },
);

export const updateClient = createAsyncThunk(
  "clients/updateClient",
  async (body: ClientBodyType) => {
    const response = await clientsAPI.updateClient(body);
    return response.data;
  },
);

export const getClientById = createAsyncThunk(
  "clients/getClientById",
  async (id: string) => {
    const response = await clientsAPI.getClientById(id);
    return response.data;
  },
);

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClients.pending, (state) => {
        state.getClientsStatus = StatusType.loading;
      })
      .addCase(getClients.fulfilled, (state, action) => {
        state.getClientsStatus = StatusType.succeeded;
        state.totalClients = action.payload.totalDocs;
        state.clients = action.payload.docs.map((doc: ClientType) => ({
          ...doc,
          id: doc._id,
        }));
      })
      .addCase(getClients.rejected, (state) => {
        state.getClientsStatus = StatusType.failed;
      })

      .addCase(createClient.pending, (state) => {
        state.createClientStatus = StatusType.loading;
      })
      .addCase(createClient.fulfilled, (state) => {
        state.createClientStatus = StatusType.succeeded;
      })
      .addCase(createClient.rejected, (state) => {
        state.createClientStatus = StatusType.failed;
      })

      .addCase(updateClient.pending, (state) => {
        state.updateClientStatus = StatusType.loading;
      })
      .addCase(updateClient.fulfilled, (state) => {
        state.updateClientStatus = StatusType.succeeded;
      })
      .addCase(updateClient.rejected, (state) => {
        state.updateClientStatus = StatusType.failed;
      })

      .addCase(getClientById.pending, (state) => {
        state.getClientByIdStatus = StatusType.loading;
      })
      .addCase(getClientById.fulfilled, (state) => {
        state.getClientByIdStatus = StatusType.succeeded;
      })
      .addCase(getClientById.rejected, (state) => {
        state.getClientByIdStatus = StatusType.failed;
      });
  },
});

// export const {} = clientsSlice.actions;

export const selectClientsStatus = (state: RootState) =>
  state.clients.getClientsStatus;
export const selectTotalClients = (state: RootState) =>
  state.clients.totalClients;
export const selectClients = (state: RootState) => state.clients.clients;

export const selectCreateClientsStatus = (state: RootState) =>
  state.clients.createClientStatus;
export const selectUpdateClientsStatus = (state: RootState) =>
  state.clients.updateClientStatus;
export const selectGetClientByIdStatus = (state: RootState) =>
  state.clients.getClientByIdStatus;

export default clientsSlice.reducer;
