import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { CargoStatus, getCargoStatusName } from "../../../../app/constants";

export const CargoStatusForm = ({
  currentStatus,
  handleStatusChange,
  canUpdate,
}: {
  currentStatus: string | null | undefined;
  canUpdate?: boolean;
  handleStatusChange: (label: string, value: any) => void;
}) => {
  return (
    <FormControl fullWidth margin="normal">
      <InputLabel id="type-label">Estado de la carga</InputLabel>
      <Select
        labelId="status"
        id="status"
        value={currentStatus ?? ""}
        label="Estado de la carga"
        disabled={!canUpdate}
        onChange={(e) =>
          handleStatusChange &&
          handleStatusChange("status", e.target.value ? e.target.value : null)
        }
      >
        <MenuItem value={CargoStatus.pending}>
          {getCargoStatusName(CargoStatus.pending)}
        </MenuItem>
        <MenuItem value={CargoStatus.reserved}>
          {getCargoStatusName(CargoStatus.reserved)}
        </MenuItem>
        <MenuItem value={CargoStatus.shipped}>
          {getCargoStatusName(CargoStatus.shipped)}
        </MenuItem>
        <MenuItem value={CargoStatus.arrived}>
          {getCargoStatusName(CargoStatus.arrived)}
        </MenuItem>
        <MenuItem value={CargoStatus.closed}>
          {getCargoStatusName(CargoStatus.closed)}
        </MenuItem>
        <MenuItem value={CargoStatus.cancelled}>
          {getCargoStatusName(CargoStatus.cancelled)}
        </MenuItem>
        <MenuItem value={CargoStatus.deposit}>
          {getCargoStatusName(CargoStatus.deposit)}
        </MenuItem>
      </Select>
    </FormControl>
  );
};
