import ReactApexChart from "react-apexcharts";
// @mui
import { useTheme, styled } from "@mui/material/styles";
import { Card, CardHeader } from "@mui/material";
// components
import { useChart } from "./Charts";

import { useNavigate } from "react-router-dom";
import { CARGOS } from "../../../routes";
import { CargoStatus } from "../../../app/constants";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const StyledChartWrapper = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

export default function CargoPieChart({
  title,
  subheader,
  chartColors,
  chartData,
  sellerId,
}: {
  title: string;
  subheader: string;
  chartColors: string[];
  chartData: any[];
  sellerId?: string;
}) {
  const theme = useTheme();
  const navigate = useNavigate();

  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = useChart({
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: {
      floating: true,
      horizontalAlign: "center",
    },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName: any) => Number(seriesName),
        title: {
          formatter: (seriesName: any) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
    chart: {
      events: {
        dataPointSelection: (e: any, context: any, config: any) => {
          const clickedPie = config.w.config.labels[config.dataPointIndex];
          switch (clickedPie) {
            case "Pendiente":
              navigate(
                `${CARGOS}?status=${CargoStatus.pending}${
                  sellerId ? `&seller=${sellerId}` : ""
                }`,
              );
              break;
            case "En depósito":
              navigate(
                `${CARGOS}?status=${CargoStatus.deposit}${
                  sellerId ? `&seller=${sellerId}` : ""
                }`,
              );
              break;
            case "Reservado":
              navigate(
                `${CARGOS}?status=${CargoStatus.reserved}${
                  sellerId ? `&seller=${sellerId}` : ""
                }`,
              );
              break;
            case "Embarcado":
              navigate(
                `${CARGOS}?status=${CargoStatus.shipped}${
                  sellerId ? `&seller=${sellerId}` : ""
                }`,
              );
              break;
            case "Arribado":
              navigate(
                `${CARGOS}?status=${CargoStatus.arrived}${
                  sellerId ? `&seller=${sellerId}` : ""
                }`,
              );
              break;
          }
        },
      },
    },
  });

  return (
    <Card sx={{ height: "100%", width: "50%" }}>
      <CardHeader title={title} subheader={subheader} />

      <StyledChartWrapper
        dir="ltr"
        sx={{
          height: "50%",
          marginTop: "5px",
          display: "flex",
          justifyContent: "center",
          marginBottom: 0,
          "appexcharts-legend": {
            top: "0px !important",
          },
        }}
      >
        <ReactApexChart
          type="pie"
          series={chartSeries}
          options={chartOptions}
          height={260}
        />
      </StyledChartWrapper>
    </Card>
  );
}
