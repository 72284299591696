import { DateTime } from "luxon";

import { CityType } from "../../../../../cities/citiesAPI";
import { NewCargoBodyType } from "../../../../cargosAPI";
import { DownloadPdfType } from "../../../PdfView";
import { IRowElement } from "../../../interfaces/RowElementInterface";

export const getCommonRowValues = (
  cargo: NewCargoBodyType,
  downloadType: DownloadPdfType,
) => {
  let tempRowVavlue: IRowElement[] = [
    {
      leftColumn: {
        title: "Shipper / Embarcador",
        subtitle: cargo.shipper || "-",
      },
      rigthColumn: {
        title: "Consignee / Consignatario",
        subtitle: cargo.consignee || "-",
      },
    },
  ];

  if (downloadType === DownloadPdfType.ReservaAerea) {
    tempRowVavlue = [
      ...tempRowVavlue,
      ...[
        {
          leftColumn: {
            title: "Airport Of Loading / Aeropuerto de embarque",
            subtitle:
              `${(cargo.cities.departure as CityType).cityName}, ${
                (cargo.cities.departure as CityType).cityCountry
              }` || "-",
          },
          rigthColumn: {
            title: "ETD / Fecha estimada de salida",
            subtitle:
              (cargo.aereal?.etd as DateTime)?.toFormat("dd/MM/yyyy") || "-",
          },
        },

        {
          leftColumn: {
            title: "Airpot Of destination / Aeropuerto de destino",
            subtitle:
              `${(cargo.cities.destination as CityType).cityName}, ${
                (cargo.cities.destination as CityType).cityCountry
              }` || "-",
          },
          rigthColumn: {
            title: "ETA / Fecha estimada de llegada",
            subtitle:
              (cargo.aereal?.eta as DateTime)?.toFormat("dd/MM/yyyy") || "-",
          },
        },
        {
          leftColumn: {
            title: "Airline / Linea Aérea",
            subtitle: cargo.responsible || "-",
          },
          rigthColumn: {
            title: "Flight number / Número de vuelo",
            subtitle: cargo.aereal?.flightNumber || "-",
          },
        },
        {
          leftColumn: {
            title: "Chargeable weight / Peso taxable",
            subtitle: cargo.commodity?.quantities?.weight?.toString() || "-",
          },
          rigthColumn: {
            title: "Gross weight / Peso bruto",
            subtitle:
              cargo.commodity?.quantities?.grossWeight?.toString() || "-",
          },
        },
        {
          leftColumn: {
            title: "MAWB",
            subtitle: cargo.aereal?.mawb || "-",
          },
          rigthColumn: {
            title: "HAWB",
            subtitle: cargo.aereal?.hawb || "-",
          },
        },
      ],
    ];
  }

  if (downloadType === DownloadPdfType.SalidaAerea) {
    tempRowVavlue = [
      ...tempRowVavlue,
      ...[
        {
          leftColumn: {
            title: "Airport Of Loading / Aeropuerto de embarque",
            subtitle:
              `${(cargo.cities.departure as CityType).cityName}, ${
                (cargo.cities.departure as CityType).cityCountry
              }` || "-",
          },
          rigthColumn: {
            title: "ATD / Fecha de salida",
            subtitle:
              (cargo.aereal?.etd as DateTime)?.toFormat("dd/MM/yyyy") || "-",
          },
        },

        {
          leftColumn: {
            title: "Airport of destination / Aeropuerto de destino",
            subtitle:
              `${(cargo.cities.destination as CityType).cityName}, ${
                (cargo.cities.destination as CityType).cityCountry
              }` || "-",
          },
          rigthColumn: {
            title: "ETA / Fecha estimada de llegada",
            subtitle:
              (cargo.aereal?.eta as DateTime)?.toFormat("dd/MM/yyyy") || "-",
          },
        },
        {
          leftColumn: {
            title: "Airline / Linea Aérea",
            subtitle: cargo.responsible || "-",
          },
          rigthColumn: {
            title: "Flight number / Número de vuelo",
            subtitle: cargo.aereal?.flightNumber || "-",
          },
        },
        {
          leftColumn: {
            title: "Chargeable weight / Peso taxable",
            subtitle: cargo.commodity?.quantities?.weight?.toString() || "-",
          },
          rigthColumn: {
            title: "Gross weight / Peso bruto",
            subtitle:
              cargo.commodity?.quantities?.grossWeight?.toString() || "-",
          },
        },
        {
          leftColumn: {
            title: "MAWB",
            subtitle: cargo.aereal?.mawb || "-",
          },
          rigthColumn: {
            title: "HAWB",
            subtitle: cargo.aereal?.hawb || "-",
          },
        },
      ],
    ];
  }

  return tempRowVavlue;
};
