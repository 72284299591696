import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import { FC } from "react";
import { CaratulaProps } from "../../../interfaces/PdfInterface";

import styles from "./styles";
import { getCargoRef } from "../../../../../../utils/cargo";
import { CargoType } from "../../../../cargosAPI";
import { CaratulaFooter } from "../shared/CaratulaFooter/CaratulaFooter";
import { getCargoTypeName } from "../../../../../../app/constants";
import { CityType } from "../../../../../cities/citiesAPI";
import { DateTime } from "luxon";
import { UserType } from "../../../../../users/usersAPI";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
  ],
});

export const CaratulaTerrestre: FC<CaratulaProps> = ({
  cargo,
  cargoClient,
}) => {
  return (
    <Document
      title={`Caratula ${cargo.cargoRef ?? getCargoRef(cargo as CargoType)}`}
      author="Tailored Logistics"
      creator="Tailored Logistics"
      producer="Tailored Logistics"
    >
      <Page size="A4" style={styles.page}>
        <View
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: "30%",
            paddingVertical: 5,
          }}
        >
          <Image src="/img/tailored-logo-complete.png" />
        </View>
        {/* ROW 1 */}

        <View style={{ display: "flex", gap: 5, flexDirection: "row" }}>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              flex: 2 / 3,
            }}
          >
            <View
              style={[
                styles.container,
                { display: "flex", flexDirection: "column", gap: 2 },
              ]}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 5,
                }}
              >
                <View style={{ flex: 1 }}>
                  <Text style={styles.subtitle}>Servicio</Text>
                  <Text style={[styles.fillingText]}> </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.subtitle}>Tipo de Embarque</Text>
                  <Text style={[styles.fillingText]}>
                    {getCargoTypeName(cargo.type!)}
                  </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.subtitle}>FCL / LCL</Text>
                  <Text style={[styles.fillingText]}>
                    {cargo.incoterm || " "}
                  </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={styles.subtitle}>Condición de Pago</Text>
                  <Text style={[styles.fillingText]}> </Text>
                </View>
              </View>
              <View>
                <Text style={styles.subtitle}>Cliente</Text>
                <Text style={[styles.fillingText]}>
                  {cargoClient?.name || " "}
                </Text>
              </View>
            </View>
            <View style={[styles.container]}>
              <Text style={styles.subtitle}>Shipper / Embarcador</Text>
              <Text style={[styles.fillingText]}>{cargo.shipper || " "}</Text>
            </View>
          </View>
          <View
            style={[
              styles.container,
              {
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flex: 1 / 3,
                gap: 2,
              },
            ]}
          >
            <View style={{ flex: 1 }}>
              <Text style={styles.subtitle}>Nro de File</Text>
              <View
                style={[
                  styles.fillingText,
                  {
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                ]}
              >
                <Text style={{ fontSize: 20 }}>
                  {cargo.cargoRef ?? getCargoRef(cargo as CargoType)}
                </Text>
              </View>
            </View>
            <View>
              <Text style={styles.subtitle}>Vendedor</Text>
              <Text style={[styles.fillingText]}>
                {cargoClient?.sellers && cargoClient?.sellers.length > 0
                  ? (cargoClient?.sellers as UserType[])
                      .map((x) => x.name)
                      .join(", ")
                  : " "}
              </Text>
            </View>
          </View>
        </View>

        {/* ROW 2 */}

        <View
          style={[
            styles.container,
            { display: "flex", flexDirection: "row", gap: 5 },
          ]}
        >
          <View style={{ flex: 2 / 3 }}>
            <Text style={styles.subtitle}>Cnee / Consignatario</Text>
            <Text style={styles.fillingText}>{cargo.consignee || " "}</Text>
          </View>
          <View style={{ flex: 1 / 3 }}>
            <Text style={styles.subtitle}>Obs</Text>
            <Text style={styles.fillingText}> </Text>
          </View>
        </View>

        {/* ROW 3 */}

        <View style={[styles.container]}>
          <View
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={styles.title}>Datos del Embarque</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 5,
              marginTop: 5,
            }}
          >
            <View
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <View>
                <Text style={styles.subtitle}>CRT</Text>
                <Text style={styles.fillingText}>
                  {cargo.ground?.crt || " "}
                </Text>
              </View>

              <View>
                <Text style={styles.subtitle}>POL Lugar de Embarque</Text>
                <Text style={styles.fillingText}>
                  {cargo.cities.departure
                    ? `${(cargo.cities?.departure as CityType)?.cityName}, ${
                        (cargo.cities?.departure as CityType)?.cityCountry
                      }`
                    : " "}
                </Text>
              </View>
              <View>
                <Text style={styles.subtitle}>Agente / Transportista </Text>
                <Text style={styles.fillingText}>
                  {cargo.cities.origin
                    ? `${(cargo.cities?.origin as CityType)?.cityName}, ${
                        (cargo.cities?.origin as CityType)?.cityCountry
                      }`
                    : " "}
                </Text>
              </View>
              <View>
                <Text style={styles.subtitle}>Fecha de Cruce</Text>
                <Text style={styles.fillingText}>
                  {(cargo.ground?.crossDate as DateTime)?.toFormat(
                    "dd/MM/yyyy",
                  ) || " "}
                </Text>
              </View>
              <View style={{ width: "40%" }}>
                <Text style={styles.subtitle}>ETD</Text>
                <Text style={styles.fillingText}>
                  {(cargo.ground?.departureDate as DateTime)?.toFormat(
                    "dd/MM/yyyy",
                  ) || " "}
                </Text>
              </View>
            </View>
            <View
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <View>
                <Text style={styles.subtitle}>DUA</Text>
                <Text style={styles.fillingText}>
                  {cargo.ground?.dua || " "}
                </Text>
              </View>
              <View>
                <Text style={styles.subtitle}> POD Lugar de Destino</Text>
                <Text style={styles.fillingText}>
                  {cargo.cities.destination
                    ? `${(cargo.cities?.destination as CityType)?.cityName}, ${
                        (cargo.cities?.destination as CityType)?.cityCountry
                      }`
                    : " "}
                </Text>
              </View>
              <View>
                <Text style={styles.subtitle}>Aduana de Cruce</Text>
                <Text style={styles.fillingText}>
                  {cargo.cities.crossingCustoms || " "}
                </Text>
              </View>
              <View>
                <Text style={styles.subtitle}> </Text>
                <Text
                  style={[styles.fillingText, { border: "1px solid white" }]}
                >
                  {" "}
                </Text>
              </View>
              <View style={{ width: "40%" }}>
                <Text style={styles.subtitle}>ETA</Text>
                <Text style={styles.fillingText}>
                  {(cargo.ground?.deliveryDate as DateTime)?.toFormat(
                    "dd/MM/yyyy",
                  ) || " "}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* ROW 4 */}

        <View style={[styles.container]}>
          <View
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={styles.title}>Datos de la carga</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 5,
              marginTop: 5,
            }}
          >
            <View
              style={{
                flex: 2 / 3,
                display: "flex",
                flexDirection: "row",
                gap: 5,
              }}
            >
              <View
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <View>
                  <Text style={styles.subtitle}>Bultos</Text>
                  <Text style={styles.fillingText}>
                    {cargo.commodity?.quantities?.package || " "}
                  </Text>
                </View>
                <View>
                  <Text style={styles.subtitle}>Volumen</Text>
                  <Text style={styles.fillingText}>
                    {cargo.commodity?.quantities?.height || " "}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <View>
                  <Text style={styles.subtitle}>Peso bruto</Text>
                  <Text style={styles.fillingText}>
                    {cargo.commodity?.quantities?.grossWeight || " "}
                  </Text>
                </View>
                <View>
                  <Text style={styles.subtitle}>Peso Cargable</Text>
                  <Text style={styles.fillingText}>
                    {cargo.commodity?.quantities?.weight || " "}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                flex: 1 / 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View style={{ width: "50%" }}>
                <Image src="/img/cargos/ground.png" />
              </View>
            </View>
          </View>
        </View>
        <CaratulaFooter />
      </Page>
    </Document>
  );
};
