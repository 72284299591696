import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Snackbar, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { DateTime } from "luxon";

import {
  CargoAerealType,
  CargoFollowType,
  CargoGroundType,
  CargoMaritimeType,
  CargoType,
  NewCargoBodyType,
} from "../../../cargosAPI";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getCargoByIdOrNumber, updateCargo } from "../../../cargosSlice";
import { selectProfile } from "../../../../signIn/signInSlice";
import { CityType } from "../../../../cities/citiesAPI";
import {
  isAerealCargo,
  isGroundCargo,
  isMaritimeCargo,
} from "../../../../../app/constants";
import { UserType } from "../../../../users/usersAPI";

export const FollowForm = ({
  cargoId,
  setCargoFollows,
}: {
  cargoId: string;
  setCargoFollows: (values: CargoFollowType[]) => void;
}) => {
  const [comment, setComment] = useState("");
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState({
    error: false,
    message: "",
  });
  const [currentCargo, setCurrentCargo] = useState<NewCargoBodyType | null>(
    null,
  );

  useEffect(() => {
    if (cargoId)
      dispatch(getCargoByIdOrNumber(cargoId)).then((value) => {
        if (value.meta.requestStatus === "fulfilled") {
          const cargo: CargoType = value.payload;

          let maritimeCargo: CargoMaritimeType | undefined = undefined;
          if (isMaritimeCargo(cargo.type) && cargo.maritime) {
            maritimeCargo = {
              ...cargo.maritime,
              eta:
                typeof cargo.maritime?.eta === "string"
                  ? DateTime.fromISO(cargo.maritime.eta)
                  : undefined,
              etd:
                typeof cargo.maritime?.etd === "string"
                  ? DateTime.fromISO(cargo.maritime.etd)
                  : undefined,
              connections: cargo.maritime.connections.map((connection) => ({
                ...connection,
                eta:
                  typeof connection?.eta === "string"
                    ? DateTime.fromISO(connection.eta)
                    : undefined,
                etd:
                  typeof connection?.etd === "string"
                    ? DateTime.fromISO(connection.etd)
                    : undefined,
              })),
            };
          }

          let aerealCargo: CargoAerealType | undefined = undefined;
          if (isAerealCargo(cargo.type) && cargo.aereal) {
            aerealCargo = {
              ...cargo.aereal,
              eta:
                typeof cargo.aereal?.eta === "string"
                  ? DateTime.fromISO(cargo.aereal.eta)
                  : undefined,
              etd:
                typeof cargo.aereal?.etd === "string"
                  ? DateTime.fromISO(cargo.aereal.etd)
                  : undefined,
            };
          }

          let groundCargo: CargoGroundType | undefined = undefined;
          if (isGroundCargo(cargo.type) && cargo.ground) {
            groundCargo = {
              ...cargo.ground,
              departureDate:
                typeof cargo.ground?.departureDate === "string"
                  ? DateTime.fromISO(cargo.ground.departureDate)
                  : undefined,
              crossDate:
                typeof cargo.ground?.crossDate === "string"
                  ? DateTime.fromISO(cargo.ground.crossDate)
                  : undefined,
              deliveryDate:
                typeof cargo.ground?.deliveryDate === "string"
                  ? DateTime.fromISO(cargo.ground.deliveryDate)
                  : undefined,
            };
          }

          const cargoUpdate: NewCargoBodyType = {
            _id: cargo._id,
            number: cargo.number,
            client: cargo.client?._id,
            type: cargo.type,
            incoterm: cargo.incoterm,
            commodity: cargo.commodity,
            consignee: cargo.consignee,
            shipper: cargo.shipper,
            responsible: cargo.responsible,
            agent: cargo.agent?._id,
            finance: cargo.finance,
            currentFinance: cargo.finance.map((finance, i) => ({
              ...finance,
              id: i,
            })),
            cities: {
              ...cargo.cities,
              departure: (cargo.cities?.departure as CityType)?._id,
              destination: (cargo.cities?.destination as CityType)?._id,
              origin: (cargo.cities?.origin as CityType)?._id,
            },
            maritime: maritimeCargo,
            aereal: aerealCargo,
            ground: groundCargo,
            customs: cargo.customs,
            ranking: cargo.ranking,
            follows: cargo.follows,

            agentReference: cargo.agentReference,
            eta: cargo.eta,
          };

          setCurrentCargo(cargoUpdate);
        } else {
          //TODO: handle error
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargoId, dispatch]);

  const handleCloseSnackbar = (
    _event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleCloseErrorSnackbar = (
    _event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorSnackbar({
      error: true,
      message: "Ocurrió un error al actualizar los seguimientos",
    });
  };

  const handleSubmit = () => {
    if (!comment.length) {
      setOpenErrorSnackbar({
        error: true,
        message: "El seguimiento no puede ser vacío",
      });
      return;
    }
    const newCargoFollow: CargoFollowType = {
      comment,
      date: DateTime.now(),
      user: profile as UserType,
    };

    if (currentCargo) {
      dispatch(
        updateCargo({
          ...currentCargo,
          _id: cargoId,
          follows: [...(currentCargo?.follows ?? []), newCargoFollow].map(
            (follow) => ({
              ...follow,
              user: (follow.user as UserType)._id,
            }),
          ),
        }),
      ).then((value) => {
        if (value.meta.requestStatus === "fulfilled") {
          setCargoFollows([...(currentCargo?.follows ?? []), newCargoFollow]);
          setOpenSnackbar(true);
        } else if (value.meta.requestStatus === "rejected") {
          setOpenErrorSnackbar({
            error: true,
            message: "Ocurrió un error al actualizar los seguimientos",
          });
        }
      });
    }
  };
  return (
    <Box>
      <Box>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="success"
            sx={{ width: "100%" }}
          >
            Carga actualizada
          </Alert>
        </Snackbar>
        <Snackbar
          open={openErrorSnackbar.error}
          autoHideDuration={3000}
          onClose={handleCloseErrorSnackbar}
        >
          <Alert
            onClose={handleCloseErrorSnackbar}
            severity="error"
            sx={{ width: "100%" }}
          >
            {openErrorSnackbar.message}
          </Alert>
        </Snackbar>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          width: "100%",
          flexDirection: { xs: "column", md: "row" },
          marginBottom: { xs: 2, lg: 0 },
          justifyContent: "space-between",
        }}
      >
        <TextField
          value={comment}
          margin="normal"
          required
          fullWidth
          id="comment"
          label="Comentario"
          name="comment"
          onChange={(e) => setComment(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          aria-label="add"
          sx={{ paddingLeft: 2, paddingRight: 2 }}
          onClick={handleSubmit}
        >
          <AddIcon />
          <span>Agregar comentario</span>
        </Button>
      </Box>
    </Box>
  );
};
