import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusType } from "../../app/constants";
import signInAPI, { ProfileUpdateType, SignInBodyType } from "./signInAPI";
import jwt_decode from "jwt-decode";
import { RootState } from "../../app/store";
import { UserType } from "../users/usersAPI";

export interface SignInState {
  value: number;
  status: StatusType;
  roles: string[];
  statusProfile: StatusType;
  profile?: UserType;
}

const initialState: SignInState = {
  value: 0,
  status: StatusType.idle,
  roles: [],
  statusProfile: StatusType.idle,
};

export const getProfile = createAsyncThunk("signIn/getProfile", async () => {
  const response = await signInAPI.getProfile();
  return response.data;
});

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (body: ProfileUpdateType, { rejectWithValue }) => {
    try {
      const response = await signInAPI.updateProfile(body);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.message);
    }
  },
);

export const signInWithMail = createAsyncThunk(
  "signIn/signIn",
  async (body: SignInBodyType, { dispatch, rejectWithValue }) => {
    try {
      const response = await signInAPI.signIn(body);
      const token = response.data?.access_token;
      if (token) {
        signInAPI.setAuthToken(token);
        dispatch(getProfile());
      }
      return token;
    } catch (err: any) {
      return rejectWithValue(err?.data?.message);
    }
  },
);

export const signInSlice = createSlice({
  name: "signIn",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signInWithMail.pending, (state) => {
        state.status = StatusType.loading;
      })
      .addCase(signInWithMail.fulfilled, (state, action) => {
        const decodedToken: { roles: string[] } = jwt_decode(action.payload);
        state.roles = decodedToken.roles;
        state.status = StatusType.succeeded;
      })
      .addCase(signInWithMail.rejected, (state) => {
        state.status = StatusType.failed;
      })

      .addCase(getProfile.pending, (state) => {
        state.statusProfile = StatusType.loading;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.statusProfile = StatusType.succeeded;
      })
      .addCase(getProfile.rejected, (state) => {
        state.statusProfile = StatusType.failed;
      })

      .addCase(updateProfile.pending, (state) => {
        state.statusProfile = StatusType.loading;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.statusProfile = StatusType.succeeded;
      })
      .addCase(updateProfile.rejected, (state) => {
        state.statusProfile = StatusType.failed;
      });
  },
});

// export const {} = signInSlice.actions;

export const selectSignInStatus = (state: RootState) => state.signIn.status;
export const selectProfile = (state: RootState) => state.signIn.profile;
export const selectStatusProfile = (state: RootState) =>
  state.signIn.statusProfile;

export default signInSlice.reducer;
