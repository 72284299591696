import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    color: "black",
    padding: 40,
  },
  content: {
    flexGrow: 1,
    marginTop: 20,
  },
  section2: {
    display: "flex",
    paddingTop: 5,
    paddingBottom: 5,
  },
  table: {
    display: "flex",
    flexGrow: 1,
  },
});

export default styles;
