import API from "../../app/apiConfig";
import { AlertTypeEnum, CargoTypeEnum } from "../../app/constants";
import { transformObjectToQueryString } from "../../utils/transformObjectToQueryString";

const baseAlerts = "/alerts";
const baseMetrics = "/dashboard";

export type GetAlertsFilters = {
  page?: number;
  pageSize?: number;
};

export type AlertCargoType = {
  _id: string;
  number: string;
  type: CargoTypeEnum;
  password?: string;
  roles: string[];
};

export type AlertType = {
  _id: string;
  type: AlertTypeEnum;
  cargo: AlertCargoType;
  createdAt: Date;
};

export type SinglePerArrayValue = {
  _id: string;
  name: string;
  cargoCount: number;
};

export type ClientWithoutNewCargos = {
  _id: string;
  name: string;
  date: string;
};

export type MetricsCargoType = {
  newCargosLast6: number;
  newCargosLast3d: number;
  newCargosPerDay: [
    {
      date: Date;
      amount: number;
    },
  ];
  arrivedCount: number;
  depositCount: number;
  pendingCount: number;
  reservedCount: number;
  shippedCount: number;
  cargosPerClient: SinglePerArrayValue[];
  cargosPerAgent: SinglePerArrayValue[];
  clientWithoutNewCargos: ClientWithoutNewCargos[];
};

export type MetricsClientType = {
  newClientsLast6: number;
  newClientsPerDay: [
    {
      date: Date;
      amount: number;
    },
  ];
  activeClients: number;
  totalPotentialClients: number;
};

export type MetricsType = {
  cargo: MetricsCargoType;
  client: MetricsClientType;
};

function getAlerts(filters: GetAlertsFilters) {
  const filterString = transformObjectToQueryString(filters);

  return API.get(`${baseAlerts}?${filterString}`);
}

function markAlertAsRead(alertId: string) {
  return API.put(`${baseAlerts}/${alertId}`);
}

function getMetrics() {
  return API.get(`${baseMetrics}`);
}

const alertsAPI = { getAlerts, markAlertAsRead, getMetrics };
export default alertsAPI;
