import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    color: "black",
    padding: 40,
    justifyContent: "space-between",
  },
  content: {
    flexGrow: 1,
    marginTop: 20,
  },
  section2: {
    display: "flex",
    paddingTop: 5,
    paddingBottom: 5,
  },
  table: {
    display: "flex",
    flexGrow: 1,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 14,
    color: "#4C265C",
    fontStyle: "normal",
    fontWeight: "normal",
    marginBottom: 20,
    border: "1px solid black",
  },
  rowElement: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
    marginVertical: 5,
  },
  rowElementTitle: {
    fontSize: 8,
    fontFamily: "Open Sans",
    fontWeight: 800,
    marginBottom: 5,
    color: "black",
  },
  rowElementSubtitle: {
    fontSize: 9,
    fontWeight: 400,
    textTransform: "uppercase",
    color: "black",
  },
});

export default styles;
