// material-ui
import {
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  CargoStatus,
  CargoTypeEnum,
  getCargoIconFromType,
  getCargoStatusName,
} from "../../../app/constants";
import {
  CARGO_STATUS_ARRIVED_COLOR,
  CARGO_STATUS_CANCELLED_COLOR,
  CARGO_STATUS_CLOSED_COLOR,
  CARGO_STATUS_DEPOSIT_COLOR,
  CARGO_STATUS_PENDING_COLOR,
  CARGO_STATUS_RESERVED_COLOR,
  CARGO_STATUS_SHIPPED_COLOR,
} from "../../../utils/constants";
import { CargoType } from "../../cargos/cargosAPI";
import { useNavigate } from "react-router-dom";
import { CARGOS } from "../../../routes";
import { getCargoRef } from "../../../utils/cargo";

// ==============================|| ORDER TABLE - HEADER CELL ||============================== //

const headCells = [
  {
    id: "logo",
    disablePadding: false,
    label: "",
  },
  {
    id: "code",
    disablePadding: false,
    label: "Ref Carga",
  },
  {
    id: "client",
    disablePadding: false,
    label: "Cliente",
  },
  {
    id: "agent",
    disablePadding: false,
    label: "Agente",
  },
  {
    id: "status",
    disablePadding: false,
    label: "Estado",
  },
  {
    id: "eta",
    disablePadding: false,
    label: "ETA",
  },
];

function OrderTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// ==============================|| ORDER TABLE - STATUS ||============================== //

const OrderStatus = ({ row }: { row: CargoType }) => {
  let status;
  if (row.type === CargoTypeEnum.IM || row.type === CargoTypeEnum.EM) {
    status = row.maritime?.status;
  } else if (row.type === CargoTypeEnum.IA || row.type === CargoTypeEnum.EA) {
    status = row.aereal?.status;
  } else if (row.type === CargoTypeEnum.IT || row.type === CargoTypeEnum.ET) {
    status = row.ground?.status;
  }

  let color;

  switch (status) {
    case CargoStatus.pending:
      color = CARGO_STATUS_PENDING_COLOR;
      break;
    case CargoStatus.reserved:
      color = CARGO_STATUS_RESERVED_COLOR;
      break;
    case CargoStatus.arrived:
      color = CARGO_STATUS_ARRIVED_COLOR;
      break;
    case CargoStatus.cancelled:
      color = CARGO_STATUS_CANCELLED_COLOR;
      break;
    case CargoStatus.closed:
      color = CARGO_STATUS_CLOSED_COLOR;
      break;
    case CargoStatus.deposit:
      color = CARGO_STATUS_DEPOSIT_COLOR;
      break;
    case CargoStatus.shipped:
      color = CARGO_STATUS_SHIPPED_COLOR;
      break;
    default:
      color = CARGO_STATUS_PENDING_COLOR;
      break;
  }

  return (
    <Chip
      label={status ? getCargoStatusName(status) : ""}
      sx={{ backgroundColor: color }}
    />
  );
};

// ==============================|| ORDER TABLE ||============================== //

export default function NextCargosPerEta({ rows }: { rows: CargoType[] }) {
  const navigate = useNavigate();

  return (
    <Box>
      <TableContainer
        sx={{
          width: "100%",
          overflowX: "auto",
          position: "relative",
          display: "block",
          maxWidth: "100%",
          "& td, & th": { whiteSpace: "nowrap" },
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          sx={{
            "& .MuiTableCell-root:first-child": {
              pl: 2,
            },
            "& .MuiTableCell-root:last-child": {
              pr: 3,
            },
          }}
        >
          <OrderTableHead />
          <TableBody>
            {rows.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  aria-checked={false}
                  tabIndex={-1}
                  key={`table-dashboard-row-${index}`}
                  selected={false}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(`${CARGOS}/${row._id}`);
                  }}
                >
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    align="left"
                  >
                    {getCargoIconFromType(row.type)}
                  </TableCell>
                  <TableCell align="center">
                    {row.cargoRef ?? getCargoRef(row)}
                  </TableCell>
                  <TableCell align="center">{`${row.client?.name}`}</TableCell>
                  <TableCell align="center">{`${row.agent?.name}`}</TableCell>
                  <TableCell align="center">
                    <OrderStatus row={row} />
                  </TableCell>
                  <TableCell align="center">{`${
                    row.eta ? new Date(row.eta).toLocaleDateString() : ""
                  }`}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
