import { CSSProperties, useEffect, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  GridRowId,
} from "@mui/x-data-grid";
import type {} from "@mui/x-data-grid/themeAugmentation";
import { Button, CircularProgress, Container } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getUserById,
  getUsers,
  selectTotalUsers,
  selectUsers,
  selectGetUserByIdStatus,
  selectUsersStatus,
} from "./usersSlice";
import { StatusType } from "../../app/constants";
import { GetUsersFilters, NewUserBodyType } from "./usersAPI";
import UpdateUser from "./UpdateUser";
import { Role, RoleToRoleName } from "./constants";
import DeleteUser from "./DeleteUser";
import { selectProfile } from "../signIn/signInSlice";
import CustomFilterSection from "../shared/FiltersSection";

const CreateButtonStyle: CSSProperties = {
  marginBottom: 20,
  paddingLeft: 15,
  paddingRight: 15,
};

const initialUser: NewUserBodyType = {
  name: "",
  email: "",
  password: "",
  roles: [Role.seller],
};

export default function Users() {
  const dispatch = useAppDispatch();

  const loading = useAppSelector(selectUsersStatus) === StatusType.loading;
  const users = useAppSelector(selectUsers);
  const total = useAppSelector(selectTotalUsers);
  const profile = useAppSelector(selectProfile);

  const [currentUser, setCurrentUser] = useState({ ...initialUser });
  const [pageModel, setPageModel] = useState({ page: 0, pageSize: 10 });
  const [search, setSearch] = useState("");
  const [userToUpdate, setUserToUpdate] = useState("");
  const [openUpdate, setOpenUpdate] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const getUserStatus = useAppSelector(selectGetUserByIdStatus);

  const onClickAddButton = () => {
    setIsUpdateModal(false);
    setOpenUpdate(true);
  };

  const getUsersPage = (newFilters?: GetUsersFilters) =>
    dispatch(
      getUsers({
        page: pageModel.page + 1,
        pageSize: pageModel.pageSize,
        search,
        ...newFilters,
      }),
    );

  const onCloseDelete = () => {
    setOpenDelete(false);
    setCurrentUser({ ...initialUser });
    getUsersPage();
  };

  const updateUser = (id: GridRowId) => {
    setUserToUpdate(id.toString());
    if (id && getUserStatus !== StatusType.loading)
      dispatch(getUserById(id.toString())).then((value) => {
        if (value.meta.requestStatus === "fulfilled") {
          setIsUpdateModal(true);
          setOpenUpdate(true);
          setCurrentUser(value.payload);
        }
      });
  };

  const onCloseUpdate = (updateUsers = false) => {
    setOpenUpdate(false);
    setCurrentUser({ ...initialUser });
    if (isUpdateModal) {
      setUserToUpdate("");
    }
    if (updateUsers) getUsersPage();
  };

  const deleteUser = (id: GridRowId) => {
    setOpenDelete(true);
    const selectedUser = users.find((user) => user._id === id);
    if (selectedUser) setCurrentUser(selectedUser);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nombre",
      width: 230,
      filterable: false,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Correo electrónico",
      width: 230,
      filterable: false,
      sortable: false,
    },
    {
      field: "roles",
      headerName: "Roles",
      width: 130,
      valueFormatter: ({ value }: { value: string[] }) =>
        RoleToRoleName(value[0]),
      filterable: false,
      sortable: false,
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) =>
        params.id !== profile?._id
          ? [
              <GridActionsCellItem
                disabled={getUserStatus === StatusType.loading}
                icon={
                  getUserStatus === StatusType.loading &&
                  params.id === userToUpdate ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Edit />
                  )
                }
                label="Modificar"
                onClick={() => updateUser(params.id)}
              />,
              <GridActionsCellItem
                icon={<Delete />}
                label="Eliminar"
                onClick={() => deleteUser(params.id)}
              />,
            ]
          : [],
      filterable: false,
      sortable: false,
    },
  ];

  const onPaginationModelChange = (model: GridPaginationModel) => {
    setPageModel({ page: model.page, pageSize: model.pageSize });
    getUsersPage({ page: model.page + 1, pageSize: model.pageSize });
  };

  useEffect(() => {
    if (loading) return;
    getUsersPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUsersPage({
      search: search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        height: "calc(100vh - 115px)",
        marginTop: 8,
      }}
    >
      <Button
        variant="contained"
        color="primary"
        aria-label="add"
        style={CreateButtonStyle}
        onClick={onClickAddButton}
      >
        <AddIcon />
        <span>Crear usuario</span>
      </Button>

      <div style={{ height: "74%", width: "100%" }}>
        <CustomFilterSection searchValue={search} setSearchValue={setSearch} />
        <DataGrid
          sx={{
            borderRadius: "0px 0px 5px 5px",
            border: "1px lightgray solid",
            borderTop: "unset",
            "& .MuiDataGrid-columnHeaderTitleContainer": { padding: "0 10px" },
            "& .MuiDataGrid-cell": { padding: "0 20px" },
            "& .MuiDataGrid-row": { cursor: "pointer" },
          }}
          filterMode="server"
          paginationMode="server"
          pagination={true}
          paginationModel={pageModel}
          onPaginationModelChange={onPaginationModelChange}
          loading={loading}
          rows={users}
          columns={columns}
          disableColumnMenu
          rowCount={total}
          pageSizeOptions={[5, 10, 25]}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          onRowClick={(params) => updateUser(params.id)}
          rowSelection={false}
        />
      </div>

      <UpdateUser
        currentUser={currentUser}
        open={openUpdate}
        onClose={onCloseUpdate}
        setCurrentUser={setCurrentUser}
        isCreate={!isUpdateModal}
      />

      <DeleteUser
        currentUser={currentUser}
        onClose={onCloseDelete}
        open={openDelete}
      />
    </Container>
  );
}
