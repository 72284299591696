import { FC, useMemo } from "react";
import { Page, View, Document, Text } from "@react-pdf/renderer";

import styles from "./styles";
import { CargoRefContainer, RowElement, TemplateHeader } from "../shared";
import { PdfInterface } from "../../interfaces/PdfInterface";
import { CityType } from "../../../../cities/citiesAPI";
import { DateTime } from "luxon";
import { TemplateFooter } from "../shared/Footer/TemplateFooter";
import { getCargoRef } from "../../../../../utils/cargo";
import { CargoType } from "../../../cargosAPI";

export const AvisoCruce: FC<PdfInterface> = ({ cargo }) => {
  const rowValues = useMemo(() => {
    return [
      {
        leftColumn: {
          title: "Shipper / Embarcador",
          subtitle: cargo.shipper || "-",
        },
        rigthColumn: {
          title: "Consignee / Consignatario:",
          subtitle: cargo.consignee || "-",
        },
      },
      {
        leftColumn: {
          title: "Aduana de cruce",
          subtitle: cargo.cities.crossingCustoms || "-",
        },
        rigthColumn: {
          title: "Fecha de cruce",
          subtitle:
            (cargo.ground?.crossDate as DateTime)?.toFormat("dd/MM/yyyy") ||
            "-",
        },
      },

      {
        leftColumn: {
          title: "Lugar de descarga",
          subtitle:
            `${(cargo.cities.destination as CityType).cityName}, ${
              (cargo.cities.destination as CityType).cityCountry
            }` || "-",
        },
        rigthColumn: {
          title: "Fecha estimada de descarga",
          subtitle:
            (cargo.ground?.deliveryDate as DateTime)?.toFormat("dd/MM/yyyy") ||
            "-",
        },
      },
      {
        leftColumn: {
          title: "Número de CRT",
          subtitle: cargo.ground?.crt || "-",
        },
        rigthColumn: {
          title: "Número de DUA",
          subtitle: cargo.ground?.dua || "-",
        },
      },
    ];
  }, [
    cargo.cities.crossingCustoms,
    cargo.cities.destination,
    cargo.consignee,
    cargo.ground?.crossDate,
    cargo.ground?.crt,
    cargo.ground?.deliveryDate,
    cargo.ground?.dua,
    cargo.shipper,
  ]);

  return (
    <Document
      title={`Aviso Cruce ${cargo.type}-${cargo.number}`}
      author="Tailored Logistics"
      creator="Tailored Logistics"
      producer="Tailored Logistics"
    >
      <Page size="A4" style={styles.page}>
        <View>
          <TemplateHeader title="Aviso Desconsolidacion" />
          <View style={styles.content}>
            <CargoRefContainer
              cargoRef={cargo.cargoRef ?? getCargoRef(cargo as CargoType)}
            />
          </View>
          <View style={styles.section2}>
            <View style={styles.table}>
              {rowValues.map((values) => (
                <RowElement values={values} />
              ))}
              <View style={styles.tableRow}>
                <View style={{ flex: 1 }}>
                  <View style={styles.rowElement}>
                    <Text style={styles.rowElementTitle}>
                      Descripción de la mercadería
                    </Text>
                    <Text style={styles.rowElementSubtitle}>
                      {cargo.commodity?.description ?? "-"}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{ flex: 1 }}>
                  <View style={styles.rowElement}>
                    <Text style={styles.rowElementTitle}>Bultos</Text>
                    <Text style={styles.rowElementSubtitle}>
                      {cargo.commodity?.quantities?.package ?? "-"}
                    </Text>
                  </View>
                </View>
                <View style={{ flex: 1 }}>
                  <View style={styles.rowElement}>
                    <Text style={styles.rowElementTitle}>Kilos</Text>
                    <Text style={styles.rowElementSubtitle}>
                      {cargo.commodity?.quantities?.weight ?? "-"}
                    </Text>
                  </View>
                </View>
                <View style={{ flex: 1 }}>
                  <View style={styles.rowElement}>
                    <Text style={styles.rowElementTitle}>Volumen</Text>
                    <Text style={styles.rowElementSubtitle}>
                      {cargo.commodity?.quantities?.height ?? "-"}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <TemplateFooter />
      </Page>
    </Document>
  );
};
