import { CSSProperties, useEffect, useState, useMemo } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  GridRowId,
} from "@mui/x-data-grid";
import type {} from "@mui/x-data-grid/themeAugmentation";
import { Box, Button, Chip, Container, MenuItem } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { CLIENTS, CREATE_CLIENT } from "../../routes";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { StatusType } from "../../app/constants";
import { selectProfile } from "../signIn/signInSlice";
import { ClientType, GetClientsFilters } from "./clientsAPI";
import { ClientStatus, getStatusName } from "./constants";
import {
  getClients,
  selectClients,
  selectClientsStatus,
  selectGetClientByIdStatus,
  selectTotalClients,
} from "./clientsSlice";
import { Role } from "../users/constants";
import CustomFilterSection from "../shared/FiltersSection";
import { UserType } from "../users/usersAPI";
import { getUsers, selectUsers } from "../users/usersSlice";
import clientAPI from "./clientsAPI";
import { exportDataToExcel } from "../../utils/export-data-to-excel";

const CreateButtonStyle: CSSProperties = {
  marginBottom: 20,
  paddingLeft: 15,
  paddingRight: 15,
};

export default function ClientsList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const loading = useAppSelector(selectClientsStatus) === StatusType.loading;
  const clients = useAppSelector(selectClients);
  const total = useAppSelector(selectTotalClients);
  const getClientStatus = useAppSelector(selectGetClientByIdStatus);
  const profile = useAppSelector(selectProfile);

  const [pageModel, setPageModel] = useState({ page: 0, pageSize: 10 });
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState(
    searchParams.get("status") ?? "all",
  );
  const isAdmin = useMemo(
    () =>
      (profile?.roles?.includes(Role.admin) ||
        profile?.roles?.includes(Role.customerService)) ??
      false,
    [profile],
  );
  const [filterSellers, setFilterSellers] = useState<string[]>(
    profile && !isAdmin ? [profile?._id] : [],
  );

  const sellers = useAppSelector(selectUsers);
  const getSellersPage = (search_val?: string) =>
    dispatch(
      getUsers({
        page: pageModel.page + 1,
        pageSize: 5,
        search: search_val,
      }),
    );

  const onClickAddButton = () => {
    navigate(CREATE_CLIENT);
  };

  const getClientsDataToExcel = async () => {
    const filters: GetClientsFilters = {};

    if (filterSellers.length) filters.sellers = filterSellers;

    if (search) filters.search = search;

    if (statusFilter !== undefined) filters.status = statusFilter;

    const apiData = await clientAPI.getAllClientData(filters);

    const customHeadings = apiData?.data?.docs?.map((client) => ({
      Nombre: client.name,
      Dirección: client?.address ?? "-",
      Despachante: client.clientBroker?.name ?? "-",
      Contactos:
        client.contacts.reduce(
          (acumulator, current, index) =>
            index === 0 ? acumulator : `${acumulator}, ${current.email}`,
          client.contacts[0]?.email ?? "",
        ) ?? "-",
      Notas: client.notes,
      Vendedores:
        client.sellers.reduce(
          (acumulator, current, index) =>
            index === 0 ? acumulator : `${acumulator}, ${current.email}`,
          client.sellers[0]?.email ?? "",
        ) ?? "-",
      Estado: client.statuses[client.statuses.length - 1].status,
      RUT: client.taxId,
    }));

    exportDataToExcel(
      customHeadings,
      `clientes-${new Date().toISOString().split("T")[0]}`,
    );
  };

  const getClientsPage = (newFilters?: GetClientsFilters) => {
    const filters: GetClientsFilters = {
      page: newFilters?.page || pageModel.page + 1,
      pageSize: newFilters?.pageSize || pageModel.pageSize,
    };

    if (newFilters?.sellers?.length) filters.sellers = newFilters?.sellers;
    else if (filterSellers.length) filters.sellers = filterSellers;

    if (newFilters?.search !== undefined) filters.search = newFilters.search;
    else if (search) filters.search = search;

    if (newFilters?.status !== undefined) filters.status = newFilters.status;
    else if (statusFilter) filters.status = statusFilter;

    dispatch(getClients(filters));
  };

  useEffect(() => {
    getClientsPage({
      search: search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const updateClient = (id: GridRowId) => {
    navigate(`${CLIENTS}/${id}`);
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nombre",
      width: 200,
      filterable: false,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Estado",
      valueGetter: (params) => {
        const row: ClientType = params.row;
        return getStatusName(row.statuses[row.statuses?.length - 1].status);
      },
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={
            params.value === "Activo"
              ? "success"
              : params.value === "Potencial"
              ? "warning"
              : "error"
          }
        />
      ),
      width: 130,
      filterable: false,
      sortable: false,
    },
    {
      field: "address",
      headerName: "Dirección",
      width: 200,
      filterable: false,
      sortable: false,
    },
    {
      field: "sellers",
      headerName: "Vendedores",
      width: 200,
      renderCell: (params) => (
        <>{params.value?.map((s: UserType) => s.name).join(", ")}</>
      ),
      filterable: false,
      sortable: false,
    },
    {
      field: "clientBroker",
      headerName: "Despachante",
      width: 200,
      renderCell: (params) => <>{params.value?.name}</>,
      filterable: false,
      sortable: false,
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) =>
        params.id !== profile?._id
          ? [
              <GridActionsCellItem
                disabled={getClientStatus === StatusType.loading}
                icon={<VisibilityIcon />}
                label="Ver"
                onClick={() => updateClient(params.id)}
              />,
            ]
          : [],
      filterable: false,
      sortable: false,
    },
  ];

  const onPaginationModelChange = (model: GridPaginationModel) => {
    setPageModel({ page: model.page, pageSize: model.pageSize });
    getClientsPage({ page: model.page + 1, pageSize: model.pageSize });
  };

  const handleStatusChange = (status: string) => {
    setStatusFilter(status);
    getClientsPage({ status });
  };

  useEffect(() => {
    if (loading) return;
    getClientsPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getClientsPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSellers]);

  useEffect(() => {
    setFilterSellers(profile && !isAdmin ? [profile?._id] : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    getSellersPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSellerInputChange = (input: string) => {
    getSellersPage(input);
  };

  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        height: "calc(100vh - 115px)",
        marginTop: 8,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          aria-label="add"
          style={CreateButtonStyle}
          onClick={onClickAddButton}
        >
          <AddIcon />
          <span>Crear</span>
        </Button>
        <Button
          variant="contained"
          color="primary"
          aria-label="add"
          style={CreateButtonStyle}
          onClick={getClientsDataToExcel}
        >
          <FileDownloadIcon />
          <span>Exportar</span>
        </Button>
      </Box>
      <div style={{ height: "74%", width: "100%" }}>
        <CustomFilterSection
          searchValue={search}
          setSearchValue={setSearch}
          hasStatusFilter
          statusFilter={statusFilter}
          setStatusFilter={handleStatusChange}
          statuses={[
            <MenuItem value={ClientStatus.potential}>
              {getStatusName(ClientStatus.potential)}
            </MenuItem>,
            <MenuItem value={ClientStatus.active}>
              {getStatusName(ClientStatus.active)}
            </MenuItem>,
            <MenuItem value={ClientStatus.inactive}>
              {getStatusName(ClientStatus.inactive)}
            </MenuItem>,
          ]}
          hasSellerFilter={true}
          sellers={sellers}
          setSellerFilter={setFilterSellers}
          defaultSeller={isAdmin ? undefined : profile}
          handleSellerInputChange={handleSellerInputChange}
          sellerFilter={filterSellers}
        />
        <DataGrid
          sx={{
            borderRadius: "0px 0px 5px 5px",
            border: "1px lightgray solid",
            borderTop: "unset",
            "& .MuiDataGrid-columnHeaderTitleContainer": { padding: "0 10px" },
            "& .MuiDataGrid-cell": { padding: "0 20px" },
            "& .MuiDataGrid-row": { cursor: "pointer" },
          }}
          rowSelection={false}
          filterMode="server"
          paginationMode="server"
          pagination={true}
          paginationModel={pageModel}
          onPaginationModelChange={onPaginationModelChange}
          loading={loading}
          rows={clients}
          columns={columns}
          disableColumnMenu
          rowCount={total}
          pageSizeOptions={[5, 10, 25]}
          onRowClick={(params) => updateClient(params.id)}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
        />
      </div>
    </Container>
  );
}
