import { Box, TextField } from "@mui/material";
import { CargoCustomsType } from "../../../cargosAPI";

export default function CrossingCustomsCargoForm({
  currentCrossingCustomCargo,
  handleOnChange,
  canUpdate,
}: {
  currentCrossingCustomCargo: CargoCustomsType;
  handleOnChange: (label: string, value: string | any | null) => void;

  canUpdate?: boolean;
}) {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <TextField
          value={currentCrossingCustomCargo.knowledgeNumber}
          margin="normal"
          fullWidth
          name="knowledgeNumber"
          label="Número de conocimiento"
          type="text"
          disabled={!canUpdate}
          id="knowledgeNumber"
          onChange={(e) => handleOnChange("knowledgeNumber", e.target.value)}
        />
        <TextField
          value={currentCrossingCustomCargo.duaNumber}
          margin="normal"
          fullWidth
          name="duaNumber"
          label="Número de DUA"
          type="text"
          disabled={!canUpdate}
          id="duaNumber"
          onChange={(e) => handleOnChange("duaNumber", e.target.value)}
        />
        <TextField
          value={currentCrossingCustomCargo.dispatcher}
          margin="normal"
          fullWidth
          name="dispatcher"
          label="Despachante"
          type="text"
          disabled={!canUpdate}
          id="dispatcherName"
          onChange={(e) => handleOnChange("dispatcher", e.target.value)}
        />
      </Box>
    </Box>
  );
}
