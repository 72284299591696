// @mui
import { alpha, styled } from "@mui/material/styles";
import { Box, Card, Typography } from "@mui/material";

import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import { MouseEventHandler } from "react";

// ----------------------------------------------------------------------

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

export default function WidgetDashboard({
  title,
  value,
  icon,
  color = "primary",
  onClick = undefined,
}: {
  title: string;
  value: number;
  icon: string;
  color: string;
  onClick?: MouseEventHandler<any>;
}) {
  return (
    <Card
      onClick={onClick}
      sx={{
        boxShadow: 0,
        textAlign: "center",
        width: "100%",
        height: "48%",
        display: "flex",
        gap: "10px",
        alignItems: "center",
        cursor: "pointer",
        color: (theme: any) => theme.palette[color].darker,
        backgroundImage: (theme: any) =>
          `linear-gradient(135deg, ${alpha(
            theme.palette[color].dark,
            0.22,
          )} 0%, ${alpha(theme.palette[color].dark, 0.44)} 100%)`,
      }}
    >
      <Box
        display="flex"
        // flexGrow={1}
        flexDirection="column"
        marginLeft="10%"
        width="25%"
        alignItems="end"
      >
        <StyledIcon
          sx={{
            display: "flex",
            margin: "unset",
            boxShadow: "0px 0px 20px 3px",
            color: (theme: any) => theme.palette[color].dark,
            backgroundImage: (theme: any) =>
              `linear-gradient(135deg, ${alpha(
                theme.palette[color].dark,
                0,
              )} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
          }}
        >
          {icon === "active" ? <BusinessCenterIcon /> : <WorkHistoryIcon />}
        </StyledIcon>
      </Box>

      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        alignItems="center"
        marginRight="10%"
      >
        <Box width="fit-content">
          <Typography variant="h4">{value}</Typography>

          <Typography variant="body1" sx={{ opacity: 0.72 }}>
            {title}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}
