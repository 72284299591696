export enum Role {
  admin = "admin",
  seller = "seller",
  customerService = "customerService",
}

export function RoleToRoleName(role: string): string {
  if (role === Role.admin) {
    return "Administrador";
  }

  if (role === Role.customerService) {
    return "Customer Service";
  }

  return "Vendedor";
}

export const ERROR_EMAIL_TAKEN = "Email already taken";
