import { useState } from "react";
import formValidations from "../../utils/formValidations";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { selectStatusProfile, updateProfile } from "../signIn/signInSlice";
import LoadingButton from "../shared/LoadingButton";
import { StatusType } from "../../app/constants";

export default function UpdatePassword({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const status = useAppSelector(selectStatusProfile);

  const defaultError = "Ha ocurrido un error. Por favor, intente nuevamente.";

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorSnackbarReason, setErrorSnackbarReason] = useState(defaultError);

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [form, setForm] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
  });

  const [formError, setFormError] = useState({
    oldPassword: false,
    newPassword: false,
    newPasswordRepeat: false,
  });

  const handleOnClose = () => {
    setForm({
      oldPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
    });
    setFormError({
      oldPassword: false,
      newPassword: false,
      newPasswordRepeat: false,
    });
    onClose();
  };

  const dispatch = useAppDispatch();

  const handleOnChange = (label: string, value: string | string[]) => {
    setForm((data) => ({ ...data, [label]: value }));
    setFormError((errors) => ({ ...errors, [label]: false }));
  };

  const handleSubmit = () => {
    const validOldPassword =
      form.oldPassword.length &&
      formValidations.validatePassword(form.oldPassword);

    const validNewPassword =
      form.newPassword.length &&
      formValidations.validatePassword(form.newPassword);

    const validNewPasswordRepeat = form.newPassword === form.newPasswordRepeat;

    if (!validOldPassword) {
      setFormError((errors) => ({ ...errors, oldPassword: true }));
    }

    if (!validNewPassword) {
      setFormError((errors) => ({ ...errors, newPassword: true }));
    }

    if (validNewPassword && !validNewPasswordRepeat) {
      setFormError((errors) => ({ ...errors, newPasswordRepeat: true }));
    }

    if (validNewPassword && validNewPasswordRepeat && validOldPassword) {
      dispatch(
        updateProfile({
          oldPassword: form.oldPassword,
          password: form.newPassword,
        }),
      ).then((value) => {
        if (value.meta.requestStatus === "fulfilled") {
          handleOnClose();
          setOpenSnackbar(true);
        } else if (value.meta.requestStatus === "rejected") {
          if (value?.payload === "Old password is incorrect") {
            setErrorSnackbarReason("La contraseña actual no es correcta");
          } else {
            setErrorSnackbarReason(defaultError);
          }
          setOpenErrorSnackbar(true);
        }
      });
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleCloseErrorSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorSnackbar(false);
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Contraseña actualizada
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseErrorSnackbar}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorSnackbarReason}
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={handleOnClose}>
        <Container
          component="main"
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginTop: 4,
            marginBottom: 4,
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Cambiar contraseña
          </Typography>
          <Box sx={{ mt: 1 }}>
            <TextField
              value={form.oldPassword}
              margin="normal"
              required
              fullWidth
              name="oldPassword"
              label="Contraseña Actual"
              type={showOldPassword ? "text" : "password"}
              id="oldPassword"
              autoComplete="old-password"
              onChange={(e) => handleOnChange("oldPassword", e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowOldPassword(!showOldPassword)}
                      onMouseDown={() => setShowOldPassword(!showOldPassword)}
                    >
                      {showOldPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={formError.oldPassword}
              helperText={
                formError.oldPassword ? "Por favor ingrese una contraseña" : ""
              }
            />

            <TextField
              value={form.newPassword}
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="Nueva Contraseña"
              type={showNewPassword ? "text" : "password"}
              id="newPassword"
              autoComplete="current-password"
              onChange={(e) => handleOnChange("newPassword", e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      onMouseDown={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={formError.newPassword}
              helperText={
                formError.newPassword
                  ? "Por favor ingrese una contraseña de al menos 8 caracteres de largo"
                  : ""
              }
            />

            <TextField
              value={form.newPasswordRepeat}
              margin="normal"
              required
              fullWidth
              name="newPasswordRepeat"
              label="Repetir nueva contraseña"
              type={showNewPassword ? "text" : "password"}
              id="newPasswordRepeat"
              autoComplete="current-password"
              onChange={(e) =>
                handleOnChange("newPasswordRepeat", e.target.value)
              }
              error={formError.newPasswordRepeat}
              helperText={
                formError.newPasswordRepeat
                  ? "Las contraseñas no coinciden"
                  : ""
              }
            />

            <Box sx={{ display: "flex" }}>
              <Button
                type="button"
                onClick={handleOnClose}
                sx={{ flex: 1, mt: 3, mb: 2, marginRight: 2 }}
              >
                Cancelar
              </Button>
              <LoadingButton
                sx={{ flex: 1 }}
                loading={status === StatusType.loading}
                text="Cambiar contraseña"
                onClick={handleSubmit}
              />
            </Box>
          </Box>
        </Container>
      </Dialog>
    </>
  );
}
