import { CargoType } from "../features/cargos/cargosAPI";

export const getCargoRef = (cargo: CargoType): string => {
  const { type, createdAt, number } = cargo;

  const createdAtYear = `${new Date(createdAt).getUTCFullYear()}`.substring(2);
  const createdAtMonth = new Date(createdAt).getUTCMonth();

  const cargoRef = `${type}${createdAtYear}${
    createdAtMonth < 10 ? "0" + createdAtMonth : createdAtMonth
  }${number}`;
  return cargoRef;
};
