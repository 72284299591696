import ReactApexChart from "react-apexcharts";
// @mui
import { Card, CardHeader, Box } from "@mui/material";
// components
import { useChart } from "./Charts";

// ----------------------------------------------------------------------

export default function CreationsChart({
  title,
  subheader,
  chartLabels,
  chartData,
}: {
  title: string;
  subheader: string;
  chartLabels: string[];
  chartData: any;
}) {
  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: "16%" } },
    fill: { type: chartData.map((i: any) => i.fill) },
    labels: chartLabels,
    xaxis: { type: "string" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y: any) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)} creaciones`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card sx={{ width: "100%" }}>
      <CardHeader title={title} subheader={subheader} />

      <Box sx={{ p: 3, pb: 1, pt: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={chartData}
          options={chartOptions}
          height={300}
        />
      </Box>
    </Card>
  );
}
