import {
  Box,
  Button,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  List,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { TransitionGroup } from "react-transition-group";
import { Delete } from "@mui/icons-material";
import { MEDIA_QUERY_MOBILE } from "../../../../../utils/constants";
import { ContainerType } from "../../../../../app/constants";
import { ExtendedCargoMaritimeContainerType } from "../../../cargosAPI";

const emptyContainer = { type: undefined, seal: "", number: "", id: 0 };

const Container = ({
  container,
  handleOnChange,
  onDelete,
  readOnly,
  isMobile,
  error,
}: {
  container: ExtendedCargoMaritimeContainerType;
  readOnly?: boolean;
  handleOnChange: (label: string, value: string, id: number) => void;
  onDelete: () => void;
  isMobile: boolean;
  error: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "start",
        flexDirection: isMobile ? "column" : "row",
        height: isMobile ? "unset" : "100px",
        gap: "5px",
      }}
    >
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <FormControl margin="normal">
          <InputLabel id="container-type-label">Tipo de contenedor*</InputLabel>
          <Select
            labelId="container-type"
            id="container-type"
            value={container.type ?? ""}
            label="Tipo de contenedor*"
            onChange={(e) =>
              handleOnChange("type", e.target.value, container.id)
            }
          >
            <MenuItem value={ContainerType.LCL}>LCL</MenuItem>
            <MenuItem value={ContainerType["20FT"]}>20FT</MenuItem>
            <MenuItem value={ContainerType["40FT"]}>40FT</MenuItem>
            <MenuItem value={ContainerType["40NOR"]}>40NOR</MenuItem>
            <MenuItem value={ContainerType["20FlatRack"]}>
              20 Flat Rack
            </MenuItem>
            <MenuItem value={ContainerType["40FlatRack"]}>
              40 Flat Rack
            </MenuItem>
            <MenuItem value={ContainerType.OpenTop}>Open Top</MenuItem>
            <MenuItem value={ContainerType.RollOnRollOff}>
              Roll on Roll off
            </MenuItem>
          </Select>
        </FormControl>
        {error && !container.type && (
          <Typography
            sx={{
              color: "#d32f2f",
              fontSize: "0.75rem",
              fontWeight: 400,
            }}
          >
            Por favor ingrese un tipo
          </Typography>
        )}
      </Box>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <TextField
          value={container.seal}
          margin="normal"
          id="seal"
          label="Precinto"
          name="name"
          type="seal"
          onChange={(e) => handleOnChange("seal", e.target.value, container.id)}
          InputProps={{
            readOnly: readOnly,
          }}
        />
      </Box>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <TextField
          value={container.number}
          margin="normal"
          id="number"
          label="Número"
          name="number"
          type="text"
          onChange={(e) =>
            handleOnChange("number", e.target.value, container.id)
          }
          InputProps={{
            readOnly: readOnly,
          }}
        />
      </Box>
      {!readOnly && (
        <IconButton
          aria-label="delete"
          onClick={onDelete}
          sx={{
            marginTop: isMobile ? "unset" : "25px",
            alignSelf: isMobile ? "center" : "unset",
          }}
        >
          <Delete />
        </IconButton>
      )}
    </Box>
  );
};

const Containers = ({
  containers,
  setContainers,
  containerError,
  canUpdate,
}: {
  containers: ExtendedCargoMaritimeContainerType[];
  setContainers: (containers: ExtendedCargoMaritimeContainerType[]) => void;
  containerError: boolean;
  canUpdate?: boolean;
}) => {
  const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE);
  const [currentId, setId] = useState(containers.length + 1);

  const handleOnChange = (label: string, value: string, id: number) => {
    if (containers)
      setContainers(
        containers?.map((container) =>
          container.id === id ? { ...container, [label]: value } : container,
        ),
      );
  };

  const addContainer = () => {
    setContainers([...containers, { ...emptyContainer, id: currentId }]);
    setId(currentId + 1);
  };

  const onDelete = (id: number) =>
    setContainers(containers.filter((container) => container.id !== id));

  return (
    <Paper elevation={1} sx={{ padding: 3, borderRadius: 5, margin: "20px 0" }}>
      <Typography variant="h6">Contenedores *</Typography>
      <List>
        <TransitionGroup>
          {containers.map((container) => (
            <Collapse key={container.id}>
              <Container
                container={container}
                handleOnChange={handleOnChange}
                onDelete={() => onDelete(container.id!)}
                isMobile={isMobile}
                error={containerError}
              />
            </Collapse>
          ))}
          {canUpdate && (
            <Button onClick={addContainer} fullWidth variant="outlined">
              Agregar contenedor
            </Button>
          )}
        </TransitionGroup>
      </List>
      {containerError && containers.length <= 0 && (
        <Typography
          sx={{
            color: "#d32f2f",
            fontSize: "0.75rem",
            fontWeight: 400,
            marginTop: "7px",
          }}
        >
          Por favor agregue un contenedor
        </Typography>
      )}
    </Paper>
  );
};

export default Containers;
