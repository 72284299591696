import { DownloadPdfType } from "../../../PdfView";

export const getCommonTitle = (downloadPdfType: DownloadPdfType) => {
  switch (downloadPdfType) {
    case DownloadPdfType.ReservaAerea:
      return "AVISO DE RESERVA AÉREA";
    case DownloadPdfType.SalidaAerea:
      return "CONFIRMACION DE SALIDA AÉREA";
    default:
      return "";
  }
};

export const getPdfTitle = (downloadPdfType: DownloadPdfType) => {
  switch (downloadPdfType) {
    case DownloadPdfType.ReservaAerea:
      return "Reserva Aérea";
    case DownloadPdfType.SalidaAerea:
      return "Confirmación de Salida Aérea";
    default:
      return "";
  }
};
