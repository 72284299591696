import { Autocomplete, FormControl, TextField } from "@mui/material";

import { ExtendedCargoFinanceType } from "../../../../cargosAPI";
import { FinanceConcept } from "../../../../constants/cargoContants";
import { FinanceTypeEnum } from "../../../../../../app/constants";

export const ConceptForm = ({
  row,
  handleOnChange,
}: {
  row: ExtendedCargoFinanceType;
  type: FinanceTypeEnum;

  handleOnChange: (label: string, value: string | boolean, id: number) => void;
}) => {
  return (
    <FormControl fullWidth margin="normal">
      <Autocomplete
        disablePortal
        id="concept"
        freeSolo
        options={Object.values(FinanceConcept)}
        onInputChange={(_e, val) =>
          handleOnChange && handleOnChange("concept", val || "", row.id)
        }
        isOptionEqualToValue={(option, value) => option === value}
        defaultValue={row.concept}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label={"Concepto"}
          />
        )}
      />
    </FormControl>
  );
};
