import { Text, View } from "@react-pdf/renderer";

import styles from "./styles";

export const CargoRefContainer = ({ cargoRef }: { cargoRef: string }) => {
  return (
    <View style={styles.section1}>
      <Text
        style={{
          fontSize: 12,
          fontWeight: 900,
          marginRight: 5,
          alignSelf: "center",
        }}
      >
        Referencia:
      </Text>
      <View style={styles.numberContainer}>
        <Text style={styles.title}>{cargoRef}</Text>
      </View>
    </View>
  );
};
