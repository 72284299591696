import axios from "axios";
import { SIGN_IN } from "../routes";

export const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: API_URL,
  headers: { common: { Accept: "application/json" } },
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.code === "ECONNABORTED") {
      return Promise.reject({
        status: 504,
        data: {
          Success: false,
          Message: "Timeout",
        },
      });
    }
    if (error.response.status === 401) {
      localStorage.removeItem("authToken");
      if (window.location.pathname !== SIGN_IN) window.location.assign(SIGN_IN);
    }
    return Promise.reject(error.response ? error.response : error);
  },
);

export default instance;
