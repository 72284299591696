import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusType } from "../../app/constants";
import { RootState } from "../../app/store";
import citiesAPI, {
  GetCitiesFilters,
  CityType,
  NewCityBodyType,
} from "./citiesAPI";

export interface CitiesState {
  getCitiesStatus: StatusType;
  cities: CityType[];
  totalCities: number;

  createCityStatus: StatusType;
  updateCityStatus: StatusType;
  deleteCityStatus: StatusType;
}

const initialState: CitiesState = {
  getCitiesStatus: StatusType.idle,
  cities: [],
  totalCities: 0,

  createCityStatus: StatusType.idle,
  updateCityStatus: StatusType.idle,
  deleteCityStatus: StatusType.idle,
};

export const getCities = createAsyncThunk(
  "cities/getCities",
  async (filters: GetCitiesFilters) => {
    const response = await citiesAPI.getCities(filters);
    return response.data;
  },
);

export const createCity = createAsyncThunk(
  "cities/createCity",
  async (body: NewCityBodyType, { rejectWithValue }) => {
    try {
      const response = await citiesAPI.createCity(body);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.message);
    }
  },
);

export const updateCity = createAsyncThunk(
  "cities/updateCity",
  async (body: NewCityBodyType, { rejectWithValue }) => {
    try {
      const response = await citiesAPI.updateCity(body);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.message);
    }
  },
);

export const deleteCity = createAsyncThunk(
  "cities/deleteCity",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await citiesAPI.deleteCity(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.message);
    }
  },
);

export const citiesSlice = createSlice({
  name: "cities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCities.pending, (state) => {
        state.getCitiesStatus = StatusType.loading;
      })
      .addCase(getCities.fulfilled, (state, action) => {
        state.getCitiesStatus = StatusType.succeeded;
        state.totalCities = action.payload.totalDocs;
        state.cities = action.payload.docs.map((doc: CityType) => ({
          ...doc,
          id: doc._id,
        }));
      })
      .addCase(getCities.rejected, (state) => {
        state.getCitiesStatus = StatusType.failed;
      })

      .addCase(createCity.pending, (state) => {
        state.createCityStatus = StatusType.loading;
      })
      .addCase(createCity.fulfilled, (state) => {
        state.createCityStatus = StatusType.succeeded;
      })
      .addCase(createCity.rejected, (state) => {
        state.createCityStatus = StatusType.failed;
      })

      .addCase(updateCity.pending, (state) => {
        state.updateCityStatus = StatusType.loading;
      })
      .addCase(updateCity.fulfilled, (state) => {
        state.updateCityStatus = StatusType.succeeded;
      })
      .addCase(updateCity.rejected, (state) => {
        state.updateCityStatus = StatusType.failed;
      })

      .addCase(deleteCity.pending, (state) => {
        state.deleteCityStatus = StatusType.loading;
      })
      .addCase(deleteCity.fulfilled, (state) => {
        state.deleteCityStatus = StatusType.succeeded;
      })
      .addCase(deleteCity.rejected, (state) => {
        state.deleteCityStatus = StatusType.failed;
      });
  },
});

export const selectCitiesStatus = (state: RootState) =>
  state.cities.getCitiesStatus;
export const selectTotalCities = (state: RootState) => state.cities.totalCities;
export const selectCities = (state: RootState) => state.cities.cities;

export const selectCreateCitiesStatus = (state: RootState) =>
  state.cities.createCityStatus;
export const selectUpdateCitiesStatus = (state: RootState) =>
  state.cities.updateCityStatus;

export const selectDeleteCitiesStatus = (state: RootState) =>
  state.cities.deleteCityStatus;

export default citiesSlice.reducer;
