import {
  Box,
  Button,
  Paper,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import { StatusType } from "../../app/constants";
import LoadingButton from "../shared/LoadingButton";
import { ExtendedContactType, NewAgentBodyType } from "./agentsAPI";
import Contacts from "./Contacts";

export default function AgentForm({
  currentAgent,
  handleSubmit,
  handleOnChange,
  formError,
  status,
  isUpdate,
  onCancel,
}: {
  status: StatusType;
  currentAgent: NewAgentBodyType;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleOnChange: (
    label: string,
    value: string | string[] | ExtendedContactType[],
  ) => void;
  formError: {
    name: boolean;
    contacts: boolean;
    country: boolean;
  };
  isUpdate?: boolean;
  onCancel: (update?: boolean) => void;
}) {
  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        value={currentAgent.name}
        margin="normal"
        required
        fullWidth
        id="name"
        label="Nombre"
        name="name"
        autoFocus
        type="text"
        onChange={(e) => handleOnChange("name", e.target.value)}
        error={formError.name}
        helperText={formError.name ? "Por favor ingrese un nombre válido" : ""}
      />

      <TextField
        value={currentAgent.country}
        margin="normal"
        required
        fullWidth
        name="country"
        label="País"
        type="text"
        id="country"
        onChange={(e) => handleOnChange("country", e.target.value)}
        error={formError.country}
        helperText={
          formError.country ? "Por favor ingrese un código válido" : ""
        }
      />
      <TextField
        value={currentAgent.notes}
        margin="normal"
        fullWidth
        id="notes"
        label="Notas"
        name="notes"
        multiline
        rows={3}
        type="text"
        onChange={(e) =>
          handleOnChange && handleOnChange("notes", e.target.value)
        }
      />

      {currentAgent?.totalRankings && (
        <Paper
          elevation={1}
          sx={{ padding: 3, borderRadius: 5, margin: "20px 0" }}
        >
          <Typography variant="h6">Calidad del agente</Typography>
          <Typography variant="body2">
            Este puntaje es el promedio de la calidad de sus{" "}
            <b>{currentAgent.totalRankings} cargas puntuadas</b>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "10px",
            }}
          >
            <Rating
              sx={{ marginTop: "10px", fontSize: "30px" }}
              readOnly={true}
              value={currentAgent.ranking}
              precision={0.5}
            />
          </Box>
        </Paper>
      )}

      <Contacts
        showErrors={formError.contacts}
        contacts={currentAgent.currentContacts || []}
        setContacts={(contacts) =>
          handleOnChange && handleOnChange("currentContacts", contacts)
        }
      />

      <Box sx={{ display: "flex" }}>
        <Button
          type="button"
          onClick={() => onCancel(false)}
          sx={{ flex: 1, mt: 3, mb: 2, marginRight: 2 }}
        >
          Cancelar
        </Button>
        <LoadingButton
          sx={{ flex: 1 }}
          loading={status === StatusType.loading}
          text={isUpdate ? "Modificar agente" : "Crear agente"}
          type="submit"
        />
      </Box>
    </Box>
  );
}
