import { Dispatch, SetStateAction, useState } from "react";
import formValidations from "../../utils/formValidations";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { updateCity, selectUpdateCitiesStatus } from "./citiesSlice";
import { useNavigate } from "react-router-dom";
import { CITIES } from "../../routes";
import CityForm from "./CityForm";
import { Alert, Container, Dialog, Snackbar, Typography } from "@mui/material";
import { NewCityBodyType } from "./citiesAPI";

export default function UpdateCity({
  open,
  onClose,
  setCurrentCity,
  currentCity,
}: {
  open: boolean;
  onClose: (update?: boolean) => void;
  setCurrentCity: Dispatch<SetStateAction<NewCityBodyType>>;
  currentCity: NewCityBodyType;
}) {
  const navigate = useNavigate();
  const status = useAppSelector(selectUpdateCitiesStatus);

  const defaultError = "Ha ocurrido un error. Por favor, intente nuevamente.";

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorSnackbarReason, setErrorSnackbarReason] = useState(defaultError);

  const [formError, setFormError] = useState({
    cityName: false,
    cityCountry: false,
    code: false,
  });

  const dispatch = useAppDispatch();

  const handleOnChange = (label: string, value: string | string[]) => {
    setCurrentCity((data) => ({ ...data, [label]: value }));
    setFormError((errors) => ({ ...errors, [label]: false }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validCityName =
      currentCity.cityName.length &&
      formValidations.validateName(currentCity.cityName);

    const validCityCountry =
      currentCity.cityCountry.length &&
      formValidations.validateName(currentCity.cityCountry);

    const validCode =
      currentCity.code.length && formValidations.validateName(currentCity.code);

    if (!validCityName) {
      setFormError((errors) => ({ ...errors, cityName: true }));
    }

    if (!validCityCountry) {
      setFormError((errors) => ({ ...errors, cityCountry: true }));
    }

    if (!validCode) {
      setFormError((errors) => ({ ...errors, code: true }));
    }

    if (validCityName && validCityCountry && validCode) {
      dispatch(updateCity(currentCity)).then((value) => {
        if (value.meta.requestStatus === "fulfilled") {
          navigate(CITIES);
          onClose(true);
          setOpenSnackbar(true);
        } else if (value.meta.requestStatus === "rejected") {
          if (value?.payload === "City code must be unique") {
            setErrorSnackbarReason(
              "Ya existe una ciudad con el mismo código internacional",
            );
          } else {
            setErrorSnackbarReason(defaultError);
          }
          setOpenErrorSnackbar(true);
        }
      });
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleCloseErrorSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorSnackbar(false);
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Ciudad modificado
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseErrorSnackbar}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorSnackbarReason}
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={() => onClose(false)}>
        <Container
          component="main"
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginTop: 4,
            marginBottom: 4,
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Modificar ciudad
          </Typography>
          <CityForm
            status={status}
            currentCity={currentCity}
            formError={formError}
            handleOnChange={handleOnChange}
            handleSubmit={handleSubmit}
            isUpdate
            onCancel={onClose}
          />
        </Container>
      </Dialog>
    </>
  );
}
