import { FC, useMemo } from "react";
import { Page, View, Document, Text } from "@react-pdf/renderer";
import { DateTime } from "luxon";

import styles from "./styles";
import { CargoRefContainer, RowElement, TemplateHeader } from "../shared";
import { PdfInterface } from "../../interfaces/PdfInterface";
import { FinanceTypeEnum } from "../../../../../app/constants";
import { CityType } from "../../../../cities/citiesAPI";
import { TemplateFooter } from "../shared/Footer/TemplateFooter";
import { getCargoRef } from "../../../../../utils/cargo";
import { CargoType, CurrencyCode } from "../../../cargosAPI";

export const AvisoLlegadaMaritima: FC<PdfInterface> = ({ cargo, full }) => {
  const rowValues = useMemo(() => {
    return [
      {
        leftColumn: {
          title: "Shipper / Embarcador",
          subtitle: cargo.shipper || "-",
        },
        rigthColumn: {
          title: "Consignee / Consignatario:",
          subtitle: cargo.consignee || "-",
        },
      },
      {
        leftColumn: {
          title: "Port Of Loading / Puerto de embarque",
          subtitle:
            `${(cargo.cities.departure as CityType).cityName}, ${
              (cargo.cities.departure as CityType).cityCountry
            }` || "-",
        },
        rigthColumn: {
          title: "ETD / Fecha estimada de salida",
          subtitle:
            (cargo.maritime?.etd as DateTime)?.toFormat("dd/MM/yyyy") || "-",
        },
      },

      {
        leftColumn: {
          title: "Port Of Discharge / Puerto de Descarga",
          subtitle:
            `${(cargo.cities.destination as CityType).cityName}, ${
              (cargo.cities.destination as CityType).cityCountry
            }` || "-",
        },
        rigthColumn: {
          title: "ETA / Fecha estimada de llegada",
          subtitle:
            (cargo.maritime?.eta as DateTime)?.toFormat("dd/MM/yyyy") || "-",
        },
      },
      {
        leftColumn: {
          title: "Ocean Vessel / Buque",
          subtitle: cargo.maritime?.vessel || "-",
        },
        rigthColumn: {
          title: "Carrier / Cargador",
          subtitle: cargo.responsible || "-",
        },
      },
      {
        leftColumn: {
          title: "MBL N°",
          subtitle: cargo.maritime?.mbl.number.toString() || "-",
        },
        rigthColumn: {
          title: "Tipo de MBL",
          subtitle: cargo.maritime?.mbl.type.toUpperCase() || "-",
        },
      },
    ];
  }, [
    cargo.cities.destination,
    cargo.consignee,
    cargo.maritime?.mbl,
    cargo.maritime?.vessel,
    cargo.responsible,
    cargo.shipper,
    cargo.cities.departure,
    cargo.maritime?.eta,
    cargo.maritime?.etd,
  ]);

  const expenses = useMemo(() => {
    return cargo.finance
      ?.filter((x) => x.type === FinanceTypeEnum.sale)
      .map((value) => {
        return {
          name: value.concept ?? "-",
          amount: value.amount ?? 0,
          currency: value.currency ?? CurrencyCode.USD,
        };
      });
  }, [cargo.finance]);

  const expensesQuantity = useMemo(() => {
    const currencyQuantity = Object.values(CurrencyCode).reduce(
      (accumulator, current) =>
        accumulator +
        (expenses?.some((x) => (x.currency ?? CurrencyCode.USD) === current)
          ? 1
          : 0),
      0,
    );

    const numberOfPosibleFinanceLines = 5;

    return numberOfPosibleFinanceLines - currencyQuantity;
  }, [expenses]);

  return (
    <Document
      title={`Aviso Llegada ${cargo.type}-${cargo.number}`}
      author="Tailored Logistics"
      creator="Tailored Logistics"
      producer="Tailored Logistics"
    >
      <Page size="A4" style={styles.page} wrap={false}>
        <View>
          <TemplateHeader title="Aviso llegada" />
          <View style={styles.content}>
            <CargoRefContainer
              cargoRef={cargo.cargoRef ?? getCargoRef(cargo as CargoType)}
            />
          </View>
          <View style={styles.section2}>
            <View style={styles.table}>
              {rowValues.map((values) => (
                <RowElement values={values} />
              ))}
              <View style={styles.tableRow}>
                <View style={{ flex: 1 }}>
                  <View style={styles.rowElement}>
                    <Text style={styles.rowElementTitle}>Bultos</Text>
                    <Text style={styles.rowElementSubtitle}>
                      {cargo.commodity?.quantities?.package ?? "-"}
                    </Text>
                  </View>
                </View>
                <View style={{ flex: 1 }}>
                  <View style={styles.rowElement}>
                    <Text style={styles.rowElementTitle}>Kg</Text>
                    <Text style={styles.rowElementSubtitle}>
                      {cargo.commodity?.quantities?.weight ?? "-"}
                    </Text>
                  </View>
                </View>
                <View style={{ flex: 1 }}>
                  <View style={styles.rowElement}>
                    <Text style={styles.rowElementTitle}>CBM</Text>
                    <Text style={styles.rowElementSubtitle}>
                      {cargo.commodity?.quantities?.height ?? "-"}
                    </Text>
                  </View>
                </View>
              </View>
              {full ? (
                <View style={styles.tableRow}>
                  <View style={{ flex: 1 }}>
                    <View style={styles.rowElement}>
                      <Text style={styles.rowElementTitle}>
                        Terminal Portuaria
                      </Text>
                      <Text style={styles.rowElementSubtitle}>
                        {cargo.maritime?.terminal ?? "-"}
                      </Text>
                    </View>
                  </View>
                  <View style={{ flex: 1 }}>
                    <View style={styles.rowElement}>
                      <Text style={styles.rowElementTitle}>
                        Terminal de devolución
                      </Text>
                      <Text style={styles.rowElementSubtitle}>
                        {cargo.maritime?.returningTerminal ?? "-"}
                      </Text>
                    </View>
                  </View>
                  <View style={{ flex: 1 }}>
                    <View style={styles.rowElement}>
                      <Text style={styles.rowElementTitle}>
                        Dias libres de demora
                      </Text>
                      <Text style={styles.rowElementSubtitle}>
                        {cargo.maritime?.freeDelayDays ?? "-"}
                      </Text>
                    </View>
                  </View>
                </View>
              ) : (
                <View style={styles.tableRow}>
                  <View style={{ flex: 1 }}>
                    <View style={styles.rowElement}>
                      <Text style={styles.rowElementTitle}>
                        Depósito de desconsolidación
                      </Text>
                      <Text style={styles.rowElementSubtitle}>
                        {cargo.maritime?.deconsolidationDeposit ?? "-"}
                      </Text>
                    </View>
                  </View>
                </View>
              )}
            </View>
          </View>

          <View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 10,
              }}
            >
              <Text style={{ fontSize: 10, color: "black" }}>
                DETALLE DE COSTOS
              </Text>
            </View>
            <View style={{ border: "1px solid black" }}>
              {expenses?.slice(0, expensesQuantity + 1).map((value) => (
                <View style={[styles.tableRow, { border: "0px", margin: 5 }]}>
                  <View style={{ width: "50%" }}>
                    <Text style={{ fontSize: 8, color: "black" }}>
                      {value?.name}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "50%",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        color: "black",
                        textAlign: "right",
                        width: 50,
                      }}
                    >
                      {value.currency}
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        color: "black",
                        textAlign: "right",
                      }}
                    >
                      {value?.amount?.toFixed(2)}
                    </Text>
                  </View>
                </View>
              ))}

              {expenses && expenses.length <= expensesQuantity && (
                <>
                  {Object.values(CurrencyCode).map((currencyCode) => {
                    const exprensesByCurrency = expenses.filter(
                      (x) => x.currency === currencyCode,
                    );

                    if (exprensesByCurrency.length <= 0) return null;

                    return (
                      <View
                        style={[
                          styles.tableRow,
                          { border: "0px", margin: 5, marginTop: 10 },
                        ]}
                      >
                        <View style={{ width: "50%" }}></View>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "50%",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 8,
                              color: "black",
                              textAlign: "right",
                              width: 50,
                            }}
                          >
                            TOTAL {currencyCode}
                          </Text>
                          <Text
                            style={{
                              fontSize: 8,
                              color: "black",
                              textAlign: "right",
                            }}
                          >
                            {exprensesByCurrency
                              .reduce(
                                (accumulator, currentValue) =>
                                  accumulator + (currentValue?.amount || 0),
                                0,
                              )
                              ?.toFixed(2)}
                          </Text>
                        </View>
                      </View>
                    );
                  })}
                </>
              )}
            </View>
          </View>
        </View>
        <TemplateFooter />
      </Page>

      {expenses && expenses.length > expensesQuantity && (
        <Page size="A4" style={styles.page} wrap={false}>
          <View>
            <TemplateHeader title="Aviso llegada" />
            <View style={styles.content}>
              <CargoRefContainer
                cargoRef={cargo.cargoRef ?? getCargoRef(cargo as CargoType)}
              />
            </View>
            <View style={{ border: "1px solid black" }}>
              {expenses?.slice(expensesQuantity + 1).map((value) => (
                <View style={[styles.tableRow, { border: "0px", margin: 5 }]}>
                  <View style={{ width: "50%" }}>
                    <Text style={{ fontSize: 8, color: "black" }}>
                      {value?.name}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "50%",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        color: "black",
                        textAlign: "right",
                        width: 50,
                      }}
                    >
                      {value.currency}
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        color: "black",
                        textAlign: "right",
                      }}
                    >
                      {value?.amount?.toFixed(2)}
                    </Text>
                  </View>
                </View>
              ))}
              <>
                {Object.values(CurrencyCode).map((currencyCode) => {
                  const exprensesByCurrency = expenses.filter(
                    (x) => x.currency === currencyCode,
                  );

                  if (exprensesByCurrency.length <= 0) return null;

                  return (
                    <View
                      style={[
                        styles.tableRow,
                        { border: "0px", margin: 5, marginTop: 10 },
                      ]}
                    >
                      <View style={{ width: "50%" }}></View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "50%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 8,
                            color: "black",
                            textAlign: "right",
                            width: 50,
                          }}
                        >
                          TOTAL {currencyCode}
                        </Text>
                        <Text
                          style={{
                            fontSize: 8,
                            color: "black",
                            textAlign: "right",
                          }}
                        >
                          {exprensesByCurrency
                            .reduce(
                              (accumulator, currentValue) =>
                                accumulator + (currentValue?.amount || 0),
                              0,
                            )
                            ?.toFixed(2)}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </>
            </View>
          </View>
          <TemplateFooter />
        </Page>
      )}
    </Document>
  );
};
