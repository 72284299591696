import { FC } from "react";
import { Autocomplete, Box, Paper, TextField, Typography } from "@mui/material";

import Connections from "./Connections";
import { CityType } from "../../../../cities/citiesAPI";
import { useAppSelector } from "../../../../../app/hooks";
import { selectCities } from "../../../../cities/citiesSlice";
import { CargoMaritimeType, NewCargoBodyType } from "../../../cargosAPI";

interface RoutesProps {
  currentCargo: NewCargoBodyType;
  handleOnChange: (label: string, value: string | any | null) => void;
  formError: {
    departureCity: boolean;
    destinationCity: boolean;
    originCity: boolean;
  };
  maritimeFormError: {
    etd: boolean;
    eta: boolean;
    terminal: boolean;
    booking: boolean;
    mbl: boolean;
    hbl: boolean;
    connections: boolean;
    containers: boolean;
  };
  canUpdate?: boolean;
  handleMaritimeCargoOnChange: (
    label: string,
    value: string | any | null,
  ) => void;
  maritimeCargo: CargoMaritimeType;
}

export const RoutesForm: FC<RoutesProps> = ({
  currentCargo,
  handleOnChange,
  formError,
  canUpdate,
  maritimeFormError,
  handleMaritimeCargoOnChange,
  maritimeCargo,
}) => {
  const cities = useAppSelector(selectCities);

  return (
    <Paper elevation={1} sx={{ padding: 3, borderRadius: 5, margin: "20px 0" }}>
      <Typography variant="h6">Ruta</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginTop: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: "10px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Autocomplete
              disablePortal
              id="departure-city"
              options={cities}
              disabled={!canUpdate}
              getOptionLabel={(city) => `${city.cityName} (${city.code})`}
              onChange={(_e, val) =>
                handleOnChange("cities", {
                  ...currentCargo.cities,
                  departure: val?._id,
                })
              }
              isOptionEqualToValue={(option, value) => option._id === value._id}
              defaultValue={currentCargo.cities.departure as CityType}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label={"Ciudad de puerto de carga*"}
                />
              )}
            />
            {formError.departureCity && !currentCargo.cities?.departure && (
              <Typography
                sx={{
                  color: "#d32f2f",
                  fontSize: "0.75rem",
                  fontWeight: 400,
                  marginTop: "7px",
                }}
              >
                Por favor ingrese una ciudad
              </Typography>
            )}
          </Box>
          <Box sx={{ width: "100%" }}>
            <Autocomplete
              disablePortal
              id="origin-city"
              options={cities}
              disabled={!canUpdate}
              getOptionLabel={(city) => `${city.cityName} (${city.code})`}
              onChange={(_e, val) =>
                handleOnChange("cities", {
                  ...currentCargo.cities,
                  origin: val?._id,
                })
              }
              sx={{ width: "100%" }}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              defaultValue={currentCargo.cities.origin as CityType}
              renderInput={(params) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <TextField {...params} label="Ciudad de origen" />
              )}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Autocomplete
              disablePortal
              id="destination-city"
              options={cities}
              getOptionLabel={(city) => `${city.cityName} (${city.code})`}
              disabled={!canUpdate}
              onChange={(_e, val) =>
                handleOnChange("cities", {
                  ...currentCargo.cities,
                  destination: val?._id,
                })
              }
              isOptionEqualToValue={(option, value) => option._id === value._id}
              defaultValue={currentCargo.cities.destination as CityType}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Ciudad de destino*"
                />
              )}
            />
            {formError.destinationCity && !currentCargo.cities?.destination && (
              <Typography
                sx={{
                  color: "#d32f2f",
                  fontSize: "0.75rem",
                  fontWeight: 400,
                  marginTop: "7px",
                }}
              >
                Por favor ingrese una ciudad
              </Typography>
            )}
          </Box>
        </Box>

        <Connections
          cities={cities}
          connections={maritimeCargo.currentConnections || []}
          setConnections={(connections) =>
            handleMaritimeCargoOnChange &&
            handleMaritimeCargoOnChange("currentConnections", connections)
          }
          connectionError={maritimeFormError.connections}
          canUpdate={canUpdate}
        />
      </Box>
    </Paper>
  );
};
