import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { DateTime } from "luxon";

import CargoDisplay from "./CargoDisplay";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getCargoByIdOrNumber, selectCargoByIdStatus } from "../cargosSlice";
import { getCities } from "../../cities/citiesSlice";
import {
  CargoAerealType,
  CargoGroundType,
  CargoMaritimeType,
  CargoType,
  NewCargoBodyType,
} from "../cargosAPI";
import {
  StatusType,
  isAerealCargo,
  isGroundCargo,
  isMaritimeCargo,
} from "../../../app/constants";
import { selectProfile } from "../../signIn/signInSlice";
import { Role } from "../../users/constants";
import { useNavigate } from "react-router-dom";
import { ClientType } from "../../clients/clientsAPI";
import { getCargoRef } from "../../../utils/cargo";

export const CargoModal = ({ cargoId }: { cargoId: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const statusCargo = useAppSelector(selectCargoByIdStatus);
  const [currentClient, setCurrentClient] = useState<ClientType | undefined>(
    undefined,
  );

  const [currentCargo, setCurrentCargo] = useState<NewCargoBodyType | null>(
    null,
  );

  const profile = useAppSelector(selectProfile);
  const isAdmin = useMemo(
    () =>
      (profile?.roles?.includes(Role.admin) ||
        profile?.roles?.includes(Role.customerService)) ??
      false,
    [profile],
  );

  const [isLoading, setIsLoading] = useState(true);

  const getCitiesPage = () =>
    dispatch(
      getCities({
        page: 1,
        pageSize: 99999,
      }),
    );

  useEffect(() => {
    getCitiesPage();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (cargoId && statusCargo !== StatusType.loading)
      dispatch(getCargoByIdOrNumber(cargoId)).then((value) => {
        if (value.meta.requestStatus === "fulfilled") {
          const cargo: CargoType = value.payload;

          if (cargo.client) setCurrentClient(cargo.client);

          let maritimeCargo: CargoMaritimeType | undefined = undefined;
          if (isMaritimeCargo(cargo.type) && cargo.maritime) {
            maritimeCargo = {
              ...cargo.maritime,
              eta:
                typeof cargo.maritime.eta === "string"
                  ? DateTime.fromISO(cargo.maritime.eta)
                  : undefined,
              etd:
                typeof cargo.maritime.etd === "string"
                  ? DateTime.fromISO(cargo.maritime.etd)
                  : undefined,
              connections: cargo.maritime.connections.map((connection) => ({
                ...connection,
                eta:
                  typeof connection.eta === "string"
                    ? DateTime.fromISO(connection.eta)
                    : undefined,
                etd:
                  typeof connection.etd === "string"
                    ? DateTime.fromISO(connection.etd)
                    : undefined,
              })),
            };
          }

          let aerealCargo: CargoAerealType | undefined = undefined;
          if (isAerealCargo(cargo.type) && cargo.aereal) {
            aerealCargo = {
              ...cargo.aereal,
              eta:
                typeof cargo.aereal.eta === "string"
                  ? DateTime.fromISO(cargo.aereal.eta)
                  : undefined,
              etd:
                typeof cargo.aereal.etd === "string"
                  ? DateTime.fromISO(cargo.aereal.etd)
                  : undefined,
            };
          }

          let groundCargo: CargoGroundType | undefined = undefined;
          if (isGroundCargo(cargo.type) && cargo.ground) {
            groundCargo = {
              ...cargo.ground,
              departureDate:
                typeof cargo.ground.departureDate === "string"
                  ? DateTime.fromISO(cargo.ground.departureDate)
                  : undefined,
              crossDate:
                typeof cargo.ground.crossDate === "string"
                  ? DateTime.fromISO(cargo.ground.crossDate)
                  : undefined,
              deliveryDate:
                typeof cargo.ground.deliveryDate === "string"
                  ? DateTime.fromISO(cargo.ground.deliveryDate)
                  : undefined,
            };
          }

          const cargoUpdate: NewCargoBodyType = {
            _id: cargo._id,
            number: cargo.number,
            client: cargo.client?.name,
            type: cargo.type,
            incoterm: cargo.incoterm,
            commodity: cargo.commodity,
            consignee: cargo.consignee,
            shipper: cargo.shipper,
            responsible: cargo.responsible,
            agent: cargo.agent?.name,
            finance: cargo.finance,
            currentFinance: cargo.finance.map((finance, i) => ({
              ...finance,
              id: i,
            })),
            cities: cargo.cities,
            maritime: maritimeCargo,
            aereal: aerealCargo,
            ground: groundCargo,
            customs: cargo.customs,
            ranking: cargo.ranking,
            follows: cargo.follows,
            agentReference: cargo.agentReference,
            eta: cargo.eta,
            createdAt: cargo.createdAt,
            updateAt: cargo.updatedAt,
          };

          setCurrentCargo(cargoUpdate);

          setIsLoading(false);
        } else {
          //TODO: handle error
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargoId, dispatch]);

  const canUpdate = useMemo(() => {
    return (
      isAdmin ||
      (profile && (currentClient?.sellers as string[])?.includes(profile._id))
    );
  }, [isAdmin, profile, currentClient]);
  return (
    <>
      {isLoading || !currentCargo ? (
        <Container
          component="main"
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 8,
            marginBottom: 20,
          }}
        >
          <CircularProgress
            size={44.5}
            style={{
              marginTop: "100px",
              display: "flex",
              flexGrow: 1,
              justifyContent: "center",
            }}
          />
        </Container>
      ) : (
        <Box
          component="main"
          sx={{
            width: "80%",
            height: "80%",
            maxHeight: "80%",
            backgroundColor: "white",
            position: "absolute",
            marginTop: { xs: "20%", sm: "10%", md: "10%", lg: "5%" },
            marginLeft: "10%",
            borderRadius: 3,
            overflowY: { xs: "scroll", md: "unset" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              padding: 5,
            }}
          >
            <Box sx={{ display: "flex", gap: 4 }}>
              <Typography
                variant="h4"
                component="h1"
                gutterBottom
                sx={{ height: "10%" }}
              >
                Carga{" "}
                {currentCargo
                  ? currentCargo.cargoRef ??
                    getCargoRef(currentCargo as CargoType)
                  : ""}
              </Typography>
              {canUpdate && (
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="add"
                  sx={{ paddingLeft: 2, paddingRight: 2, height: "80%" }}
                  onClick={() => navigate(`${cargoId}`)}
                >
                  <Edit />
                  <span>Editar carga</span>
                </Button>
              )}
            </Box>

            <Box sx={{ height: "90%", width: "100%" }}>
              <CargoDisplay
                currentCargo={currentCargo}
                cargoId={cargoId}
                canUpdate={!!canUpdate}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
