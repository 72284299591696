import { useMemo, useState } from "react";
import { Alert, Container, Snackbar, Typography } from "@mui/material";
import formValidations from "../../utils/formValidations";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createClient, selectCreateClientsStatus } from "./clientsSlice";
import { useNavigate } from "react-router-dom";
import { CLIENTS } from "../../routes";
import ClientForm from "./ClientsForm";
import { ClientStatus } from "./constants";
import { ClientBodyType, ExtendedContactType } from "./clientsAPI";
import { ClientBrokerType } from "../clientBrokers/clientBrokersAPI";
import { UserType } from "../users/usersAPI";
import { selectProfile } from "../signIn/signInSlice";
import { Role } from "../users/constants";

const initialClient: ClientBodyType = {
  name: "",
  taxId: "",
  address: "",
  selectedClientBroker: { name: "", email: "", phone: "", id: "newBroker" },
  currentContacts: undefined,
  notes: "",
  sellers: [],
  status: ClientStatus.potential,
};

export default function CreateClient() {
  const navigate = useNavigate();
  const status = useAppSelector(selectCreateClientsStatus);

  const profile = useAppSelector(selectProfile);
  const isAdmin = useMemo(
    () =>
      (profile?.roles?.includes(Role.admin) ||
        profile?.roles?.includes(Role.customerService)) ??
      false,
    [profile],
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [currentClient, setCurrentClient] = useState<ClientBodyType>({
    ...initialClient,
  });
  const [showErrors, setShowErrors] = useState(false);

  const [formError, setFormError] = useState({
    name: false,
    address: false,
    taxId: false,
    status: false,
  });

  const dispatch = useAppDispatch();

  const handleOnChange = (
    label: string,
    value:
      | string
      | string[]
      | ClientBrokerType
      | ExtendedContactType[]
      | UserType[],
  ) => {
    setCurrentClient((data) => ({ ...data, [label]: value }));
    setFormError((errors) => ({ ...errors, [label]: false }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowErrors(true);

    const validName =
      currentClient.name.length &&
      formValidations.validateName(currentClient.name);

    const validContacts =
      !currentClient.currentContacts ||
      !currentClient.currentContacts.length ||
      currentClient.currentContacts?.every(
        (contact) =>
          formValidations.validateName(contact.name) &&
          formValidations.validateEmail(contact.email) &&
          formValidations.validatePhone(contact.phone),
      );

    if (!validName) {
      setFormError((errors) => ({ ...errors, name: true }));
    }

    const validStatus = isAdmin || currentClient.status !== ClientStatus.active;
    if (!validStatus) {
      setFormError((errors) => ({ ...errors, status: true }));
    }

    if (validName && validContacts && validStatus) {
      const body: ClientBodyType = {
        name: currentClient.name,
        address: currentClient.address,
        taxId: currentClient.taxId,
        status: currentClient.status,
        notes: currentClient.notes,
      };

      if (currentClient.currentContacts?.length) {
        body.contacts = currentClient.currentContacts;
      }

      if (currentClient.selectedClientBroker?._id) {
        body.clientBroker = currentClient.selectedClientBroker?._id;
      }

      if (currentClient.selectedSellers?.length) {
        body.sellers = currentClient.selectedSellers.map(
          (seller) => seller._id,
        );
      }

      dispatch(createClient(body)).then((value) => {
        if (value.meta.requestStatus === "fulfilled") {
          navigate(CLIENTS);
          setOpenSnackbar(true);
        }
      });
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          <Typography variant="subtitle2">
            Ocurrió un error al crear el cliente
          </Typography>
        </Alert>
      </Snackbar>
      <Container
        component="main"
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginTop: 8,
          marginBottom: 20,
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{ alignSelf: "center" }}
        >
          Crear cliente
        </Typography>

        <ClientForm
          showErrors={showErrors}
          currentClient={currentClient}
          formError={formError}
          handleOnChange={handleOnChange}
          handleSubmit={handleSubmit}
          status={status}
        />
      </Container>
    </>
  );
}
