import { useEffect, useMemo, useState } from "react";
import formValidations from "../../utils/formValidations";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  updateClient,
  selectUpdateClientsStatus,
  selectGetClientByIdStatus,
  getClientById,
} from "./clientsSlice";
import { useNavigate, useParams } from "react-router-dom";
import ClientForm from "./ClientsForm";
import { Alert, Container, CircularProgress, Snackbar } from "@mui/material";
import { ClientBodyType, ClientType, ExtendedContactType } from "./clientsAPI";
import { StatusType } from "../../app/constants";
import { ClientBrokerType } from "../clientBrokers/clientBrokersAPI";
import { UserType } from "../users/usersAPI";
import { ClientStatus } from "./constants";
import { selectProfile } from "../signIn/signInSlice";
import { Role } from "../users/constants";

const initialClient: ClientBodyType = {
  name: "",
  taxId: "",
  address: "",
  contacts: [],
  notes: "",
  sellers: [],
  status: ClientStatus.potential,
  selectedClientBroker: { name: "", email: "", phone: "", id: "newBroker" },
};

export default function UpdateClientPage() {
  const navigate = useNavigate();
  const params = useParams();
  const clientId = params?.clientId;
  const status = useAppSelector(selectUpdateClientsStatus);
  const getClientStatus = useAppSelector(selectGetClientByIdStatus);

  const profile = useAppSelector(selectProfile);
  const isAdmin = useMemo(
    () =>
      (profile?.roles?.includes(Role.admin) ||
        profile?.roles?.includes(Role.customerService)) ??
      false,
    [profile],
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [oldStatus, setOldStatus] = useState(ClientStatus.potential);
  const [currentClient, setCurrentClient] = useState<ClientBodyType>({
    ...initialClient,
  });
  const [showErrors, setShowErrors] = useState(false);
  const [formError, setFormError] = useState({
    name: false,
    address: false,
    taxId: false,
    status: false,
  });

  const dispatch = useAppDispatch();

  const onCancel = () => navigate(-1);

  const handleOnChange = (
    label: string,
    value:
      | string
      | string[]
      | ClientBrokerType
      | ExtendedContactType[]
      | UserType[],
  ) => {
    setCurrentClient((data) => ({ ...data, [label]: value }));
    setFormError((errors) => ({ ...errors, [label]: false }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setShowErrors(true);

    const validName =
      currentClient.name.length &&
      formValidations.validateName(currentClient.name);

    const validContacts =
      !currentClient.currentContacts ||
      !currentClient.currentContacts.length ||
      currentClient.currentContacts?.length
        ? currentClient.currentContacts?.every(
            (contact) =>
              formValidations.validateName(contact.name) &&
              formValidations.validateEmail(contact.email) &&
              formValidations.validatePhone(contact.phone),
          )
        : true;

    if (!validName) {
      setFormError((errors) => ({ ...errors, name: true }));
    }

    const validStatus =
      isAdmin ||
      oldStatus === ClientStatus.active ||
      currentClient.status !== ClientStatus.active;
    if (!validStatus) {
      setFormError((errors) => ({ ...errors, status: true }));
    }

    if (validName && validContacts && validStatus) {
      const statusUpdate =
        oldStatus !== currentClient.status ? currentClient.status : undefined;
      const body: ClientBodyType = {
        _id: currentClient._id,
        name: currentClient.name,
        address: currentClient.address,
        taxId: currentClient.taxId,
        status: statusUpdate,
        notes: currentClient.notes,
      };

      if (currentClient.currentContacts?.length) {
        body.contacts = currentClient.currentContacts;
      }
      if (currentClient.selectedClientBroker?._id) {
        body.clientBroker = currentClient.selectedClientBroker?._id;
      }
      if (isAdmin && currentClient.selectedSellers) {
        body.sellers = currentClient.selectedSellers.map(
          (seller) => seller._id,
        );
      }

      dispatch(updateClient(body)).then((value) => {
        if (value.meta.requestStatus === "fulfilled") {
          setOpenSnackbar(true);
          navigate(-1);
        }
      });
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (clientId && getClientStatus !== StatusType.loading)
      dispatch(getClientById(clientId)).then((value) => {
        if (value.meta.requestStatus === "fulfilled") {
          const client: ClientType = value.payload;

          setCurrentClient({
            ...value.payload,
            currentContacts: client?.contacts.map((contact, index) => ({
              ...contact,
              id: index,
            })),
            status: client.statuses[client.statuses.length - 1].status,
            selectedClientBroker: client.clientBroker,
            selectedSellers: client.sellers,
            createdBy: client.createdBy,
          });

          setOldStatus(client.statuses[client.statuses.length - 1].status);

          setIsLoading(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, dispatch]);

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={status === StatusType.failed ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {status === StatusType.failed
            ? "Ocurrió un error al modificar el usuario. Intente nuevamente."
            : "Usuario modificado"}
        </Alert>
      </Snackbar>

      <Container
        component="main"
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginBottom: 4,
          marginTop: 6,
        }}
      >
        {isLoading && (
          <CircularProgress sx={{ placeSelf: "center", marginTop: "20%" }} />
        )}
        {!isLoading && (
          <ClientForm
            showErrors={showErrors}
            status={status}
            currentClient={currentClient}
            formError={formError}
            handleOnChange={handleOnChange}
            handleSubmit={handleSubmit}
            isUpdate
            onCancel={onCancel}
          />
        )}
      </Container>
    </>
  );
}
