import { FC } from "react";
import { Page, View, Document } from "@react-pdf/renderer";

import styles from "./styles";
import { IRowElement } from "../../interfaces/RowElementInterface";
import { CargoRefContainer, RowElement, TemplateHeader } from "../shared";
import { TemplateFooter } from "../shared/Footer/TemplateFooter";

interface CommonTemplateProps {
  title: string;
  cargoRef: string;
  rowValues: IRowElement[];
  pdfTitle: string;
}

export const CommonTemplate: FC<CommonTemplateProps> = ({
  title,
  rowValues,
  cargoRef,
  pdfTitle,
}) => {
  return (
    <Document
      title={pdfTitle}
      author="Tailored Logistics"
      creator="Tailored Logistics"
      producer="Tailored Logistics"
    >
      <Page size="A4" style={styles.page}>
        <TemplateHeader title={title} />
        <View style={styles.content}>
          <CargoRefContainer cargoRef={cargoRef} />
          <View style={styles.section2}>
            <View style={styles.table}>
              {rowValues.map((values) => (
                <RowElement values={values} />
              ))}
            </View>
          </View>
        </View>
        <TemplateFooter />
      </Page>
    </Document>
  );
};
