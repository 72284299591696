import { NewCargoBodyType } from "../../../cargosAPI";
import { CargoTypeEnum } from "../../../../../app/constants";
import { CaratulaMaritimo } from "./Maritime";
import { ClientType } from "../../../../clients/clientsAPI";
import { CaratulaAereo } from "./Aereal";
import { CaratulaTerrestre } from "./Ground";
import { CaratulaAduana } from "./Aduana";
import { AgentType } from "../../../../agents/agentsAPI";

export const Caratulas = ({
  cargo,
  cargoClient,
  cargoAgent,
}: {
  cargo: NewCargoBodyType;
  cargoClient?: ClientType;
  cargoAgent?: AgentType;
}) => {
  const { type } = cargo;
  return (
    <>
      {type === CargoTypeEnum.EM || type === CargoTypeEnum.IM ? (
        <CaratulaMaritimo
          cargo={cargo}
          cargoClient={cargoClient}
          cargoAgent={cargoAgent}
        />
      ) : type === CargoTypeEnum.EA || type === CargoTypeEnum.IA ? (
        <CaratulaAereo cargo={cargo} cargoClient={cargoClient} />
      ) : type === CargoTypeEnum.ET || type === CargoTypeEnum.IT ? (
        <CaratulaTerrestre cargo={cargo} cargoClient={cargoClient} />
      ) : type === CargoTypeEnum.DA ? (
        <CaratulaAduana cargo={cargo} cargoClient={cargoClient} />
      ) : null}
    </>
  );
};
