import API from "../../app/apiConfig";
import jwt_decode from "jwt-decode";
import storage from "../../utils/storage";
import { Role } from "../users/constants";

export type SignInBodyType = {
  email: string;
  password: string;
};

export type ProfileType = {
  name: string;
  email: string;
  roles: Role[];
  password?: string;
};

export type ProfileUpdateType = {
  name?: string;
  email?: string;
  oldPassword?: string;
  password?: string;
};

function signIn(body: SignInBodyType) {
  return API.post("/auth/login", body);
}

function getProfile() {
  return API.get("/users/profile");
}

function updateProfile(body: ProfileUpdateType) {
  return API.put("/users/profile", body);
}

function setAuthToken(token: string) {
  if (token) {
    storage.setItem("authToken", token);
    const decodedToken: { roles: string[] } = jwt_decode(token);
    storage.setItem("userRoles", decodedToken.roles.toString());
    API.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    storage.removeItem("authToken");
    storage.removeItem("userRoles");
    delete API.defaults.headers.common.Authorization;
  }
}

function isAdmin() {
  storage.getUserRole()?.includes(Role.admin);
}

const signInAPI = { signIn, setAuthToken, isAdmin, getProfile, updateProfile };
export default signInAPI;
