import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useMemo } from "react";
import { ClientWithoutNewCargos } from "../dashboardAPI";
import { DateTime } from "luxon";

const headCells = [
  {
    id: "client",
    disablePadding: false,
    label: "Cliente",
  },

  {
    id: "lastCargoDate",
    disablePadding: false,
    label: "Fecha última carga",
  },
];

export const ClientsWithoutNewCargos = ({
  clientsWithoutNewCargos,
}: {
  clientsWithoutNewCargos?: ClientWithoutNewCargos[];
}) => {
  const rows = useMemo(
    () =>
      clientsWithoutNewCargos?.map((clientData) => ({
        _id: clientData._id,
        client: clientData.name,
        date: clientData.date,
      })),
    [clientsWithoutNewCargos],
  );

  return (
    <Box>
      <TableContainer
        sx={{
          width: "100%",
          overflowX: "auto",
          //   position: "relative",
          display: "block",
          maxWidth: "100%",
          "& td, & th": { whiteSpace: "nowrap" },
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          sx={{
            "& .MuiTableCell-root:first-child": {
              pl: 2,
            },
            "& .MuiTableCell-root:last-child": {
              pr: 3,
            },
          }}
        >
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  // align="center"
                  padding={headCell.disablePadding ? "none" : "normal"}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  aria-checked={false}
                  tabIndex={-1}
                  key={`table-dashboard-row-${index}`}
                  selected={false}
                >
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    align="left"
                  >
                    {row.client}
                  </TableCell>
                  <TableCell>
                    {DateTime.fromISO(row.date).toFormat("dd/MM/yyyy")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
