import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { CargoFinanceType, CurrencyCode } from "../../../cargosAPI";

const TableHeader = () => {
  const headCells = [
    {
      id: "concept",
      align: "left",
      disablePadding: false,
      label: "Concepto",
    },
    {
      id: "currency",
      align: "left",
      disablePadding: false,
      label: "Moneda",
    },
    {
      id: "amount",
      align: "left",
      disablePadding: false,
      label: "Monto",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const FinanceTable = ({
  finances,
}: {
  finances: CargoFinanceType[];
}) => {
  return (
    <TableContainer
      sx={{
        width: "100%",
        overflowX: "auto",
        position: "relative",
        display: "block",
        maxWidth: "100%",
        "& td, & th": { whiteSpace: "nowrap" },
      }}
    >
      <Table
        aria-labelledby="tableTitle"
        sx={{
          "& .MuiTableCell-root:first-child": {
            pl: 2,
          },
          "& .MuiTableCell-root:last-child": {
            pr: 3,
          },
        }}
      >
        <TableHeader />
        <TableBody>
          {finances.map((row, index) => {
            return (
              <TableRow
                hover
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
                tabIndex={-1}
                key={row.concept + index}
              >
                <TableCell
                  align="left"
                  sx={{
                    whiteSpace: "normal !important",
                    wordWrap: "break-word",
                  }}
                >
                  {row.concept}
                </TableCell>
                <TableCell component="th" scope="row" align="left" width={100}>
                  {row.currency ?? CurrencyCode.USD}
                </TableCell>
                <TableCell align="left">{row.amount}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
