import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusType } from "../../app/constants";
import { RootState } from "../../app/store";
import usersAPI, {
  GetUsersFilters,
  NewUserBodyType,
  UserType,
} from "./usersAPI";

export interface UsersState {
  getUsersStatus: StatusType;
  users: UserType[];
  totalUsers: number;

  createUserStatus: StatusType;
  updateUserStatus: StatusType;
  getUserByIdStatus: StatusType;
  deleteUserStatus: StatusType;
}

const initialState: UsersState = {
  getUsersStatus: StatusType.idle,
  users: [],
  totalUsers: 0,

  createUserStatus: StatusType.idle,
  updateUserStatus: StatusType.idle,
  getUserByIdStatus: StatusType.idle,
  deleteUserStatus: StatusType.idle,
};

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (filters: GetUsersFilters) => {
    const response = await usersAPI.getUsers(filters);
    return response.data;
  },
);

export const createUser = createAsyncThunk(
  "users/createUser",
  async (body: NewUserBodyType, { rejectWithValue }) => {
    try {
      const response = await usersAPI.createUser(body);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.message);
    }
  },
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await usersAPI.deleteUser(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.message);
    }
  },
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (body: NewUserBodyType) => {
    const response = await usersAPI.updateUser(body);
    return response.data;
  },
);

export const getUserById = createAsyncThunk(
  "users/getUserById",
  async (id: string) => {
    const response = await usersAPI.getUserById(id);
    return response.data;
  },
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.getUsersStatus = StatusType.loading;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.getUsersStatus = StatusType.succeeded;
        state.totalUsers = action.payload.totalDocs;
        state.users = action.payload.docs.map((doc: UserType) => ({
          ...doc,
          id: doc._id,
        }));
      })
      .addCase(getUsers.rejected, (state) => {
        state.getUsersStatus = StatusType.failed;
      })

      .addCase(createUser.pending, (state) => {
        state.createUserStatus = StatusType.loading;
      })
      .addCase(createUser.fulfilled, (state) => {
        state.createUserStatus = StatusType.succeeded;
      })
      .addCase(createUser.rejected, (state) => {
        state.createUserStatus = StatusType.failed;
      })

      .addCase(updateUser.pending, (state) => {
        state.updateUserStatus = StatusType.loading;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.updateUserStatus = StatusType.succeeded;
      })
      .addCase(updateUser.rejected, (state) => {
        state.updateUserStatus = StatusType.failed;
      })

      .addCase(getUserById.pending, (state) => {
        state.getUserByIdStatus = StatusType.loading;
      })
      .addCase(getUserById.fulfilled, (state) => {
        state.getUserByIdStatus = StatusType.succeeded;
      })
      .addCase(getUserById.rejected, (state) => {
        state.getUserByIdStatus = StatusType.failed;
      })

      .addCase(deleteUser.pending, (state) => {
        state.deleteUserStatus = StatusType.loading;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.deleteUserStatus = StatusType.succeeded;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.deleteUserStatus = StatusType.failed;
      });
  },
});

// export const {} = usersSlice.actions;

export const selectUsersStatus = (state: RootState) =>
  state.users.getUsersStatus;
export const selectTotalUsers = (state: RootState) => state.users.totalUsers;
export const selectUsers = (state: RootState) => state.users.users;

export const selectCreateUsersStatus = (state: RootState) =>
  state.users.createUserStatus;
export const selectUpdateUsersStatus = (state: RootState) =>
  state.users.updateUserStatus;
export const selectGetUserByIdStatus = (state: RootState) =>
  state.users.getUserByIdStatus;
export const selectDeleteUserStatus = (state: RootState) =>
  state.users.deleteUserStatus;

export default usersSlice.reducer;
