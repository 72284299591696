const validateName = (name: string) => name.length >= 3;
const validateAddress = (address: string) => address.length >= 8;
const validatePhone = (phone: string) => phone.length >= 8;
const validateMongoId = (id: string) =>
  id.match(/^[0-9a-fA-F]{24}$/) ? true : false;
const validateEmail = (email: string) =>
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum|fund|xyz|top|online|tech|dev|club|app|pro|site|shop|win|store|space|download|live|cloud|work|one|life|link|ltd|design|network|email|science|blog|zone|news|host|website|trade)\b/i.test(
    email,
  );
const validatePassword = (password: string) =>
  /[A-Za-z\d~`!@#$%^&&*()_+={[}}|:;"'<,>./?-]{8,}$/i.test(password);

const formValidations = {
  validateName,
  validateAddress,
  validatePhone,
  validateMongoId,
  validateEmail,
  validatePassword,
};

export default formValidations;
