import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  FormControl,
  IconButton,
  List,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { TransitionGroup } from "react-transition-group";
import { Delete } from "@mui/icons-material";
import { MEDIA_QUERY_MOBILE } from "../../../../../utils/constants";
import { ExtendedCargoConnectionType } from "../../../cargosAPI";
import { CityType } from "../../../../cities/citiesAPI";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

const emptyConnection = {
  city: undefined,
  eta: undefined,
  etd: undefined,
  id: 0,
};

const Connection = ({
  connection,
  cities,
  handleOnChange,
  onDelete,
  readOnly,
  isMobile,
  showError,
}: {
  connection: ExtendedCargoConnectionType;
  cities: CityType[];
  readOnly?: boolean;
  handleOnChange: (
    label: string,
    value: string | Date | DateTime | undefined | CityType,
    id: number,
  ) => void;
  onDelete: () => void;
  isMobile: boolean;
  showError: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        marginBottom: "10px",
        gap: "10px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: { xs: "unset", sm: "80px" },
        }}
      >
        <FormControl sx={{ width: "100%" }} margin="normal">
          <Autocomplete
            disablePortal
            id={`conection-city-${connection.id}`}
            options={cities}
            getOptionLabel={(city) => `${city.cityName} (${city.code})`}
            onChange={(_e, val) => {
              if (val) handleOnChange("city", val, connection.id);
            }}
            fullWidth
            isOptionEqualToValue={(option, value) => option._id === value._id}
            defaultValue={connection.city as CityType}
            renderInput={(params) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TextField {...params} label="Ciudad de conexión*" />
            )}
          />
        </FormControl>
        {showError && !connection?.city && (
          <Typography
            sx={{
              color: "#d32f2f",
              fontSize: "0.75rem",
              fontWeight: 400,
            }}
          >
            Por favor ingrese una ciudad
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
          marginTop: "10px",
          width: "100%",
        }}
      >
        <DatePicker
          label="ETA"
          value={connection.eta}
          onChange={(value) =>
            handleOnChange &&
            handleOnChange("eta", value ?? undefined, connection.id)
          }
          sx={{ width: { xs: "100%", sm: "50%" } }}
        />
        <DatePicker
          label="ETD"
          value={connection.etd}
          onChange={(value) =>
            handleOnChange &&
            handleOnChange("etd", value ?? undefined, connection.id)
          }
          sx={{ width: { xs: "100%", sm: "50%" } }}
        />
      </Box>

      {!readOnly && (
        <IconButton aria-label="delete" onClick={onDelete}>
          <Delete />
        </IconButton>
      )}
    </Box>
  );
};

const Connections = ({
  connections,
  setConnections,
  cities,
  connectionError,
  canUpdate,
}: {
  connections: ExtendedCargoConnectionType[];
  setConnections: (connections: ExtendedCargoConnectionType[]) => void;
  cities: CityType[];
  connectionError: boolean;
  canUpdate?: boolean;
}) => {
  const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE);
  const [currentId, setId] = useState(connections.length + 1);

  const handleOnChange = (
    label: string,
    value: string | Date | DateTime | undefined | CityType,
    id: number,
  ) => {
    if (connections)
      setConnections(
        connections?.map((connection) =>
          connection.id === id
            ? { ...connection, [label]: value as DateTime }
            : connection,
        ),
      );
  };

  const addContainer = () => {
    setConnections([...connections, { ...emptyConnection, id: currentId }]);
    setId(currentId + 1);
  };

  const onDelete = (id: number) =>
    setConnections(connections.filter((connection) => connection.id !== id));

  return (
    <Paper elevation={1} sx={{ padding: 3, borderRadius: 5, margin: "20px 0" }}>
      <Typography variant="h6">Conexiones</Typography>
      <List>
        <TransitionGroup>
          {connections.map((connection) => (
            <Collapse key={connection.id}>
              <Connection
                cities={cities}
                connection={connection}
                handleOnChange={handleOnChange}
                onDelete={() => onDelete(connection.id!)}
                isMobile={isMobile}
                showError={connectionError}
              />
            </Collapse>
          ))}
          {canUpdate && (
            <Button onClick={addContainer} fullWidth variant="outlined">
              Agregar conexión
            </Button>
          )}
        </TransitionGroup>
      </List>
    </Paper>
  );
};

export default Connections;
