import { CargoStatus, MblType } from "../../../app/constants";
import { NewCargoBodyType } from "../cargosAPI";

export const initialCargo: NewCargoBodyType = {
  client: "",
  type: null,
  incoterm: null,
  commodity: {
    description: "",
    value: "",
    quantities: {
      package: undefined,
      weight: undefined,
      grossWeight: undefined,
      height: undefined,
    },
  },
  consignee: "",
  shipper: "",
  responsible: "",
  agent: "",
  finance: [],
  currentFinance: [],
  cities: {
    origin: undefined,
    departure: undefined,
    departureDetail: undefined,
    destination: undefined,
    destinationDetail: undefined,
    crossingCustoms: undefined,
  },
  maritime: {
    status: CargoStatus.pending,
    booking: "",
    mbl: {
      type: MblType.original,
      number: "",
    },
    hbl: "",
    eta: undefined,
    etd: undefined,
    connections: [],
    containers: [],
    terminal: undefined,
    returningTerminal: undefined,
    deconsolidationDeposit: "",
    freeDelayDays: undefined,
    insurance: false,
  },
  aereal: {
    status: CargoStatus.pending,
    booking: "",
    hawb: "",
    mawb: "",
    flightNumber: "",
    eta: undefined,
    etd: undefined,
  },
  ground: {
    status: CargoStatus.pending,
    crt: "",
    dua: "",
    departureDate: undefined,
    crossDate: undefined,
    deliveryDate: undefined,
  },
  customs: {
    knowledgeNumber: "",
    status: CargoStatus.pending,
    eta: undefined,
  },
  ranking: 0,
};

export enum FinanceConcept {
  FleteMarinoImportacion = "FLETE MARITIMO IMPORTACION",
  GastosTerminalesExentos = "GASTOS TERMINALES EXENTOS",
  AlmacenajeGravado = "ALMACENAJE GRAVADO",
  SeguroGravado = "SEGURO GRAVADO",
  GastosEnOrigen = "GASTOS EN ORIGEN",
  EmisionBl = "EMISIÓN BL",
  RecuperoPagosAduaneros = "RECUPERO PAGOS ADUANEROS",
  VentaDeContenedorNacionalizado = "VENTA DE CONTENEDOR NACIONALIZADO",
  DepositoPortuario = "DEPOSITO PORTUARIO",
  GastosTerminalesGravados = "GASTOS TERMINALES GRAVADOS",
  FleteTerrestreInternacional = "FLETE TERRESTRE INTERNACIONAL",
  FleteTerrestreNacional = "FLETE TERRESTRE NACIONAL",
  FleteAereoImportacionExento = "FLETE AEREO IMPORTACION EXENTO",
  FleteAereoImportacionGravado = "FLETE AEREO IMPORTACION GRAVADO",
  FleteAereoExportacion = "FLETE AEREO EXPORTACION",
  Handling = "HANDLING",
  DesconsolidacionElectronica = "DESCONSOLIDACIÓN ELECTRÓNICA",
  HandlingGravado = "HANDLING GRAVADO",
  PickUp = "PICK UP",
  AdValorem = "AD VALOREM",
  ComisionGravada = "COMISION GRAVADA",
  AlmacenajeCtnrCerrado = "ALMACENAJE CTNR CERRADO",
  TransladoContenedor = "TRASLADO CONTENEDOR",
  FleteMaritimoExportacion = "FLETE MARITIMO EXPORTACION",
  GastosLocalesExportacion = "GASTOS LOCALES EXPORTACION",
  Vgm = "VGM",
  SeguroExento = "SEGURO EXENTO",
  HonorariosGravados = "HONORARIOS GRAVADOS",
  GastosDeDespacho = "GASTOS DE DESPACHO",
  AsesoramientoLogisticoExento = "ASESORAMIENTO LOGISTICO EXENTO",
  AsesoramientoLogistoGravado = "ASESORAMIENTO LOGISTICO GRAVADO",
  FleteTerrestreTransito = "FLETE TERRESTRE TRANSITO",
  Phip = "PBIP",
  Thc = "THC",
  AlmacenajeExento = "ALMACENAJE EXENTO",
  SalidaDepositoPortuario = "SALIDA DEPOSITO PORTUARIO",
  FleteMaritimoImportacionExento = "FLETE MARITIMO IMPORTACION EXENTO",
  FleteMaritimoImportacionGravado = "FLETE MARITIMO IMPORTACION GRAVADO",
  DemoraCntr = "DEMORA CTNR",
  GastosAdministrativos = "GASTOS ADMINISTRATIVOS",
  LogisticsFee = "LOGISTICS FEE",
  TasaDeSeguridadImpoMaritima = "TASA DE SEGURIDAD IMPO MARITIMA",
  DevolucionContenedorVacio = "DEVOLUCION CONTENEDOR VACIO",
  Crt = "CRT",
  Profit = "PROFIT",
  DespachoEnOrigen = "DESPACHO EN ORIGEN",
  UnificacionDeFacturas = "UNIFICACION DE FACTURAS",
  DespachoDeTransito = "DESPACHO DE TRANSITO",
  PrecintoElectronico = "PRECINTO ELECTRONICO",
  GastosTerminalPortuaria = "GASTOS TERMINAL PORTUARIA",
  "VaciadoCntr20'" = "VACIADO CTNR 20'",
  Entrega = "ENTREGA",
  Dna = "DNA",
  FleteTerrestreExportacion = "FLETE TERRESTRE EXPORTACION",
  AperturaCntrGravado = "APERTURA CTNR GRAVADO",
  VerificacionCanalRojo = "VERIFICACION CANAL ROJO",
  EntregaDeMercaderia = "ENTREGA DE MERCADERIA",
  FleteMaritimoTercerosPaises = "FLETE MARITIMO TERCEROS PAISES",
  ConsultoriayAsesoramiento = "CONSULTORIA Y ASESORAMIENTO",
}
