import API from "../../app/apiConfig";
import { transformObjectToQueryString } from "../../utils/transformObjectToQueryString";

const base = "/client-brokers";

export type GetClientBrokersFilters = {
  search?: string;
  page?: number;
  pageSize?: number;
};

export type ClientBrokerType = {
  id?: string;
  _id?: string;
  name: string;
  email?: string;
  phone?: string;
};

function getClients(filters: GetClientBrokersFilters) {
  const filterString = transformObjectToQueryString(filters);

  return API.get(`${base}?${filterString}`);
}

function createClient(body: ClientBrokerType) {
  return API.post(base, body);
}

function deleteClientBroker(id: string) {
  return API.delete(`${base}/${id}`);
}

const clientsAPI = { getClients, createClient, deleteClientBroker };
export default clientsAPI;
