import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DateTime } from "luxon";

import { CargoConnectionType } from "../../../../cargosAPI";
import { CityType } from "../../../../../cities/citiesAPI";

const TableHeader = () => {
  const headCells = [
    {
      id: "city",
      align: "left",
      disablePadding: false,
      label: "Ciudad",
    },
    {
      id: "eta",
      align: "left",
      disablePadding: false,
      label: "ETA",
    },
    {
      id: "etd",
      align: "left",
      disablePadding: false,
      label: "ETD",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const ConnectionTable = ({
  connections,
}: {
  connections: CargoConnectionType[];
}) => {
  return (
    <TableContainer
      sx={{
        width: "100%",
        overflowX: "auto",
        position: "relative",
        display: "block",
        maxWidth: "100%",
        "& td, & th": { whiteSpace: "nowrap" },
      }}
    >
      <Table
        aria-labelledby="tableTitle"
        sx={{
          "& .MuiTableCell-root:first-child": {
            pl: 2,
          },
          "& .MuiTableCell-root:last-child": {
            pr: 3,
          },
        }}
      >
        <TableHeader />

        <TableBody>
          {connections.map((row, index) => {
            const city = row.city as CityType;
            return (
              <TableRow
                hover
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
                tabIndex={-1}
                key={city.cityName + index}
              >
                <TableCell component="th" scope="row" align="left">
                  {`${city.cityName}, ${city.cityCountry}`}
                </TableCell>
                <TableCell align="left">
                  {(row.eta as DateTime).toFormat("dd/MM/yyyy")}
                </TableCell>
                <TableCell align="left">
                  {(row.etd as DateTime).toFormat("dd/MM/yyyy")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
