import styles from "./styles";
import { Text, View } from "@react-pdf/renderer";

export const CaratulaFooter = () => {
  return (
    <View
      style={[
        styles.container,
        { display: "flex", flexDirection: "column", gap: 5, flex: 1 },
      ]}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
        }}
      >
        {/* Egresos */}
        <View style={{ flex: 1 }}>
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 5,
            }}
          >
            <Text style={styles.title}>Egresos</Text>
          </View>

          <View style={{ display: "flex", flexDirection: "column", gap: 5 }}>
            <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
              <View style={{ flex: 1 }}>
                <Text style={styles.subtitle}>Concepto a pagar:</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.subtitle}>Pagar a:</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.subtitle}>Monto a Pagar:</Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
            </View>
          </View>
        </View>

        {/* Ingresos */}

        <View style={{ flex: 1 }}>
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 5,
            }}
          >
            <Text style={styles.title}>INGRESOS</Text>
          </View>

          <View style={{ display: "flex", flexDirection: "column", gap: 5 }}>
            <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
              <View style={{ flex: 1 }}>
                <Text style={styles.subtitle}>Monto a cobrar:</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.subtitle}>Cobrar a:</Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.subtitle}>Concepto a cobrar:</Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.fillingText}> </Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      {/* Total combra y venta */}
      <View
        style={{ display: "flex", flexDirection: "row", gap: 5, width: "100%" }}
      >
        <View style={{ flex: 1 / 6 }}></View>
        <View
          style={{
            flex: 4 / 6,
            display: "flex",
            flexDirection: "row",
            gap: 5,
            alignItems: "center",
          }}
        >
          <Text style={[styles.title, { flex: 1, fontSize: 10 }]}>
            Total compra
          </Text>
          <Text style={[styles.fillingText, { flex: 1 }]}> </Text>
          <Text style={[styles.fillingText, { flex: 1 }]}> </Text>
          <Text style={[styles.title, { flex: 1, fontSize: 10 }]}>
            Total Venta
          </Text>
        </View>
        <View style={{ flex: 1 / 6 }}></View>
      </View>

      {/* Profit */}
      <View
        style={{ display: "flex", flexDirection: "row", gap: 5, width: "100%" }}
      >
        <View style={{ flex: 1 / 6 }}></View>
        <View
          style={{
            flex: 1 / 6,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Text style={[styles.fillingText]}> </Text>
          <Text style={[styles.subtitle]}>Divisa</Text>
        </View>
        <View
          style={{
            flex: 2 / 6,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Text style={[styles.fillingText]}> </Text>
          <Text style={[styles.subtitle]}>Total PROFIT</Text>
        </View>
        <View style={{ flex: 2 / 6 }}></View>
      </View>
      {/* Notas */}
      <View style={[styles.container, { flex: 1 }]}>
        <Text style={styles.subtitle}>Notas: </Text>
      </View>
    </View>
  );
};
