import { Button, CircularProgress, SxProps } from "@mui/material";

const LoadingButton = ({
  loading,
  text,
  disabled,
  onClick,
  type,
  sx,
}: {
  loading: boolean;
  text: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  onClick?: () => void;
  sx?: SxProps;
}) => (
  <Button
    type={type}
    fullWidth
    variant="contained"
    sx={{ ...sx, mt: 3, mb: 2 }}
    onClick={onClick}
    disabled={disabled}
  >
    {loading ? (
      <CircularProgress size={24.5} style={{ color: "white" }} />
    ) : (
      text
    )}
  </Button>
);

export default LoadingButton;
