import { FC, useState } from "react";
import { Box, TextField } from "@mui/material";

import { CargoCustomsType, NewCargoBodyType } from "../../cargosAPI";
import { ClientType } from "../../../clients/clientsAPI";
import ClientSelector from "../../../clients/ClientSelector";
import CrossingCustomsCargoForm from "./components/CrossingCustomsForm";
import RoutesForm from "./components/RoutesForm";
import { CommodityForm } from "./components/CommodityForm";
import Finance from "../shared/Finance/Finance";
import { CargoFollow } from "../shared/CargoFollowForm/CargoFollow";
import { DownloadCargoPdfButtons } from "../../DownloadView/DownloadCargoPdfButtons";
import { CargoRating } from "../shared/CargoRating";
import AgentSelector from "../../../agents/AgentSelector";
import { AgentType } from "../../../agents/agentsAPI";
import { CargoStatusForm } from "../shared/CargoStatusForm";
import { DatePicker } from "@mui/x-date-pickers";

interface CustomCargoFormProps {
  currentCargo: NewCargoBodyType;
  currentCustomsCargo: CargoCustomsType;
  handleOnChange: (label: string, value: string | any | null) => void;

  canUpdate?: boolean;
  formError: {
    type: boolean;
    incoterm: boolean;
    client: boolean;
    agent: boolean;
    departureCity: boolean;
    destinationCity: boolean;
    originCity: boolean;
    shipper: boolean;
    finance: boolean;
  };
  isUpdate: boolean;
  handleCustomsCargoOnChange: (label: string, value: any) => void;
  selectedClient: ClientType | null;
  setSelectedClient: (client: ClientType) => void;
}

const CustomCargoForm: FC<CustomCargoFormProps> = ({
  currentCustomsCargo,
  currentCargo,
  handleOnChange,
  canUpdate,
  formError,
  isUpdate,
  handleCustomsCargoOnChange,
  selectedClient,
  setSelectedClient,
}) => {
  const [openSelectClient, setOpenSelectClient] = useState(false);

  const toggleSelectClientModal = () => {
    setOpenSelectClient(!openSelectClient);
  };

  return (
    <Box>
      <ClientSelector
        open={openSelectClient}
        toggleModal={toggleSelectClientModal}
        selectedClient={selectedClient}
        onSelectExistentClient={setSelectedClient}
        error={formError.client}
        canUpdate={canUpdate}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <CargoStatusForm
          canUpdate={canUpdate}
          currentStatus={currentCustomsCargo.status}
          handleStatusChange={handleCustomsCargoOnChange}
        />
        <TextField
          value={currentCargo.shipper}
          margin="normal"
          fullWidth
          name="shipper"
          label="Shipper"
          required
          type="text"
          id="shipper"
          disabled={!canUpdate}
          onChange={(e) => handleOnChange("shipper", e.target.value)}
          error={formError.shipper}
          helperText={formError.shipper ? "Por favor ingrese un shipper" : ""}
        />

        <TextField
          value={currentCargo.consignee}
          margin="normal"
          fullWidth
          name="consignee"
          label="Consignatario"
          type="text"
          disabled={!canUpdate}
          id="consignee"
          onChange={(e) => handleOnChange("consignee", e.target.value)}
        />
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            marginTop: "16px", // marginTop that TextField has
          }}
        >
          <DatePicker
            label="ETA"
            disabled={!canUpdate}
            value={currentCustomsCargo.eta}
            onChange={(value) =>
              handleCustomsCargoOnChange &&
              handleCustomsCargoOnChange("eta", value)
            }
            sx={{ width: "100%" }}
          />
        </Box>
      </Box>
      <CrossingCustomsCargoForm
        currentCrossingCustomCargo={currentCustomsCargo}
        handleOnChange={handleCustomsCargoOnChange}
        canUpdate={canUpdate}
      />

      <RoutesForm
        currentCargo={currentCargo}
        handleOnChange={handleOnChange}
        canUpdate={canUpdate}
      />
      <CommodityForm
        currentCargo={currentCargo}
        handleOnChange={handleOnChange}
        canUpdate={canUpdate}
      />

      <Finance
        finance={currentCargo.currentFinance || []}
        setFinance={(finance) =>
          handleOnChange && handleOnChange("currentFinance", finance)
        }
        financeError={formError.finance}
        canUpdate={canUpdate}
      />

      {isUpdate && (
        <DownloadCargoPdfButtons
          currentCargo={currentCargo}
          cargoClient={selectedClient ?? undefined}
        />
      )}

      <CargoFollow
        follows={currentCargo.follows}
        handleOnChange={handleOnChange}
      />

      <CargoRating
        currentCargo={currentCargo}
        handleOnChange={handleOnChange}
        canUpdate={canUpdate}
      />
    </Box>
  );
};

export default CustomCargoForm;
