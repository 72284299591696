import {
  Autocomplete,
  Box,
  Button,
  Divider,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { DatePicker, LocalizationProvider, esES } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";

import { UserType } from "../users/usersAPI";
import { MEDIA_QUERY_MOBILE } from "../../utils/constants";
import { ClientType } from "../clients/clientsAPI";
import { AgentType } from "../agents/agentsAPI";

const SearchBar = ({
  searchValue,
  setSearchValue,
}: {
  searchValue: string;
  setSearchValue: (search: string) => void;
}) => (
  <div>
    <TextField
      id="search-bar-input"
      className="text"
      variant="outlined"
      placeholder="Search..."
      value={searchValue}
      onChange={(e) => setSearchValue(e?.target?.value)}
      size="small"
      sx={{
        width: "210px",
        height: "56px",
        display: { xs: "none", sm: "flex" },
        justifyContent: "center",
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: searchValue?.length ? (
          <InputAdornment
            sx={{ cursor: "pointer" }}
            position="end"
            onClick={() => setSearchValue("")}
          >
            <ClearIcon />
          </InputAdornment>
        ) : null,
      }}
    />
  </div>
);

const StatusFilter = ({
  statusFilter,
  setStatusFilter,
  statuses,
}: {
  statusFilter?: string;
  setStatusFilter?: (status: string) => void;
  statuses?: any;
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "10px",
      width: "33%",
    }}
  >
    <InputLabel
      id="status-label"
      style={{
        color: "black",
        width: "100px",
        display: useMediaQuery(MEDIA_QUERY_MOBILE) ? "none" : "flex",
      }}
    >
      Estado
    </InputLabel>
    <Select
      labelId="status-label"
      id="status"
      value={statusFilter}
      sx={{ width: "100%", height: "55px" }}
      size="small"
      onChange={(e) => setStatusFilter && setStatusFilter(e?.target?.value)}
    >
      <MenuItem value={"all"}>Todos</MenuItem>
      {statuses}
    </Select>
  </div>
);

const TypeFilter = ({
  typeFilter,
  setTypeFilter,
  types,
}: {
  typeFilter?: string;
  setTypeFilter?: (status: string) => void;
  types?: any;
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
        minWidth: "33%",
      }}
    >
      <InputLabel
        id="type-label"
        style={{
          color: "black",
          width: "20%",
          display: useMediaQuery(MEDIA_QUERY_MOBILE) ? "none" : "flex",
        }}
      >
        Tipos
      </InputLabel>
      <Select
        labelId="type-label"
        id="type"
        value={typeFilter}
        sx={{ flex: 1, height: "55px" }}
        size="small"
        onChange={(e) => setTypeFilter && setTypeFilter(e?.target?.value)}
        placeholder="Todos"
      >
        <MenuItem value={"all"}>Todos</MenuItem>
        {types}
      </Select>
    </div>
  );
};

const SellersFilter = ({
  sellers,
  setSellerFilter,
  handleSellerInputChange,
  defaultSeller,
  sellerFilter,
}: {
  sellers: UserType[];
  setSellerFilter?: (seller_id: string[]) => void;
  handleSellerInputChange?: (val: string) => void;
  defaultSeller?: UserType;
  sellerFilter?: string[];
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "33%",
    }}
  >
    <InputLabel
      id="seller-label"
      style={{
        color: "black",
        width: "110px",
        display: useMediaQuery(MEDIA_QUERY_MOBILE) ? "none" : "flex",
      }}
    >
      Vendedor
    </InputLabel>
    <Autocomplete
      disablePortal
      id="seller-filter"
      options={sellers}
      getOptionLabel={(user: UserType) => user.name}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      defaultValue={defaultSeller}
      value={
        !sellerFilter || sellerFilter?.length === 0
          ? null
          : sellers.length > 0
          ? sellers.find((seller) => sellerFilter.includes(seller._id))
          : null
      }
      onInputChange={(_e, val) =>
        handleSellerInputChange && handleSellerInputChange(val)
      }
      onChange={(_e, val) => {
        if (setSellerFilter) {
          if (val?._id) setSellerFilter([val._id]);
          else setSellerFilter([]);
        }
      }}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TextField {...params} label="Vendedor" />
      )}
    />
  </div>
);

const AgentsFilter = ({
  agents,
  setAgentFilter,
  handleAgentInputChange,
  agentFilter,
}: {
  agents: AgentType[];
  setAgentFilter?: (agent_id: string[]) => void;
  handleAgentInputChange?: (val: string) => void;
  agentFilter?: string[];
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "33%",
    }}
  >
    <InputLabel
      id="agent-label"
      style={{
        color: "black",
        width: "110px",
        display: useMediaQuery(MEDIA_QUERY_MOBILE) ? "none" : "flex",
      }}
    >
      Agente
    </InputLabel>
    <Autocomplete
      disablePortal
      id="agent-filter"
      options={agents}
      getOptionLabel={(agent: AgentType) => agent.name}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      value={
        !agentFilter || agentFilter?.length === 0
          ? null
          : agents.length > 0
          ? agents.find((agent) => agentFilter.includes(agent._id))
          : null
      }
      onInputChange={(_e, val) =>
        handleAgentInputChange && handleAgentInputChange(val)
      }
      onChange={(_e, val) => {
        if (setAgentFilter) {
          if (val?._id) setAgentFilter([val._id]);
          else setAgentFilter([]);
        }
      }}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TextField {...params} label="Agente" />
      )}
    />
  </div>
);

const ClientsFilter = ({
  clients,
  setClientFilter,
  handleClientInputChange,
  clientFilter,
}: {
  clients: ClientType[];
  setClientFilter?: (client_id: string[]) => void;
  handleClientInputChange?: (val: string) => void;
  clientFilter?: string[];
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "33%",
      }}
    >
      <InputLabel
        id="client-label"
        style={{
          color: "black",
          width: "110px",
          display: useMediaQuery(MEDIA_QUERY_MOBILE) ? "none" : "flex",
        }}
      >
        Cliente
      </InputLabel>
      <Autocomplete
        disablePortal
        id="client-filter"
        options={clients}
        value={
          !clientFilter || clientFilter?.length === 0
            ? null
            : clients.length > 0
            ? clients.find((client) => clientFilter.includes(client._id))
            : null
        }
        getOptionLabel={(client: ClientType) => client.name}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        onInputChange={(_e, val) =>
          handleClientInputChange && handleClientInputChange(val)
        }
        onChange={(_e, val) => {
          if (setClientFilter) {
            if (val?._id) setClientFilter([val._id]);
            else setClientFilter([]);
          }
        }}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <TextField {...params} label="Cliente" />
        )}
      />
    </div>
  );
};

const EtaMinFilter = ({
  setFilterMinEta,
  minEtaFilter,
}: {
  setFilterMinEta?: (val: any) => void;
  minEtaFilter?: any;
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "33%",
    }}
  >
    <InputLabel
      id="eta-min-label"
      style={{
        color: "black",
        width: "110px",
        display: useMediaQuery(MEDIA_QUERY_MOBILE) ? "none" : "flex",
      }}
    >
      Min ETA
    </InputLabel>
    <DatePicker
      sx={{ width: "100%" }}
      label="Min ETA"
      value={minEtaFilter ?? null}
      onChange={(val) => setFilterMinEta && setFilterMinEta(val)}
    />
  </div>
);

const EtaMaxFilter = ({
  setFilterMaxEta,
  maxEtaFilter,
}: {
  setFilterMaxEta?: (val: any) => void;
  maxEtaFilter?: any;
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "33%",
    }}
  >
    <InputLabel
      id="eta-min-label"
      style={{
        color: "black",
        width: "110px",
        display: useMediaQuery(MEDIA_QUERY_MOBILE) ? "none" : "flex",
      }}
    >
      Max ETA
    </InputLabel>
    <DatePicker
      sx={{ width: "100%" }}
      label="Max ETA"
      value={maxEtaFilter ?? null}
      onChange={(val) => setFilterMaxEta && setFilterMaxEta(val)}
    />
  </div>
);

const CustomFilterSection = ({
  searchValue,
  setSearchValue,
  hasStatusFilter,
  statusFilter,
  setStatusFilter,
  hasSellerFilter,
  sellers,
  setSellerFilter,
  handleSellerInputChange,
  defaultSeller,
  statuses,
  hasAgentFilter,
  agents,
  setAgentsFilter,
  handleAgentsInputChange,
  hasClientFilter,
  clients,
  setClientsFilter,
  handleClientsInputChange,
  hasEtaFilter,
  setFilterMinEta,
  setFilterMaxEta,
  resetFilters,
  clientFilter,
  agentFilter,
  sellerFilter,
  minEtaFilter,
  maxEtaFilter,
  customStatusComponent,
  hasTypeFilter,
  setTypesFilter,
  typeFilter,
  types,
}: {
  searchValue: string;
  setSearchValue: (search: string) => void;
  hasStatusFilter?: boolean;
  statusFilter?: string;
  setStatusFilter?: (status: string) => void;
  hasSellerFilter?: boolean;
  sellers?: UserType[];
  setSellerFilter?: (seller_id: string[]) => void;
  handleSellerInputChange?: (val: string) => void;
  defaultSeller?: UserType;
  statuses?: any;
  hasAgentFilter?: boolean;
  agents?: AgentType[];
  setAgentsFilter?: (agent_id: string[]) => void;
  handleAgentsInputChange?: (val: string) => void;
  hasClientFilter?: boolean;
  clients?: ClientType[];
  setClientsFilter?: (client_id: string[]) => void;
  handleClientsInputChange?: (val: string) => void;
  hasEtaFilter?: boolean;
  setFilterMinEta?: (val: any) => void;
  setFilterMaxEta?: (val: any) => void;
  resetFilters?: () => void;
  clientFilter?: string[];
  agentFilter?: string[];
  sellerFilter?: string[];
  minEtaFilter?: any;
  maxEtaFilter?: any;

  hasTypeFilter?: boolean;
  typeFilter?: string;
  setTypesFilter?: (status: string) => void;
  types?: any;

  customStatusComponent?: JSX.Element;
}) => (
  <>
    <Box
      sx={{
        padding: "10px",
        borderRadius: "5px 5px 0px 0px",
        border: "1px lightgray solid",
        marginBottom: "-1px",
        borderBottom: "unset",
        display: { xs: "none", sm: "flex" },
        placeItems: { xs: "start", sm: "center" },
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          placeItems: { xs: "start", sm: "center" },
          gap: "20px",
          width: "calc(100% - 240px)", //210px is from search input + 30px of separation
          flexDirection: { xs: "column" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            placeItems: { xs: "start", sm: "center" },
            gap: "20px",
            width: "100%",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          {hasStatusFilter ? (
            customStatusComponent ? (
              customStatusComponent
            ) : (
              <StatusFilter
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                statuses={statuses}
              />
            )
          ) : null}
          {hasSellerFilter && (
            <SellersFilter
              sellers={sellers ?? []}
              setSellerFilter={setSellerFilter}
              handleSellerInputChange={handleSellerInputChange}
              defaultSeller={defaultSeller}
              sellerFilter={sellerFilter}
            />
          )}
          {hasClientFilter && (
            <ClientsFilter
              clients={clients ?? []}
              setClientFilter={setClientsFilter}
              handleClientInputChange={handleClientsInputChange}
              clientFilter={clientFilter}
            />
          )}
        </Box>
        <LocalizationProvider
          dateAdapter={AdapterLuxon}
          localeText={
            esES.components.MuiLocalizationProvider.defaultProps.localeText
          }
          adapterLocale="es"
        >
          <Box
            sx={{
              display: "flex",
              placeItems: { xs: "start", sm: "center" },
              gap: "20px",
              flexDirection: { xs: "column", sm: "row" },
              width: "100%",
              marginTop: hasAgentFilter || hasClientFilter ? "10px" : "unset",
            }}
          >
            {hasTypeFilter && (
              <TypeFilter
                setTypeFilter={setTypesFilter}
                typeFilter={typeFilter}
                types={types}
              />
            )}
            {hasAgentFilter && (
              <AgentsFilter
                agents={agents ?? []}
                setAgentFilter={setAgentsFilter}
                handleAgentInputChange={handleAgentsInputChange}
                agentFilter={agentFilter}
              />
            )}
            {hasEtaFilter && (
              <EtaMinFilter
                setFilterMinEta={setFilterMinEta}
                minEtaFilter={minEtaFilter}
              />
            )}
            {hasEtaFilter && (
              <EtaMaxFilter
                setFilterMaxEta={setFilterMaxEta}
                maxEtaFilter={maxEtaFilter}
              />
            )}
          </Box>
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "row-reverse",
        }}
      >
        <Box>
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
          {resetFilters && (
            <Button
              variant="contained"
              color="primary"
              aria-label="add"
              sx={{ paddingLeft: 5, paddingRight: 5 }}
              onClick={resetFilters}
            >
              <CleaningServicesIcon />
              <span>Limpiar filtros</span>
            </Button>
          )}
        </Box>
      </Box>
    </Box>
    <Divider />
  </>
);

export default CustomFilterSection;
