import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import {
  AGENTS,
  CARGOS,
  CITIES,
  CLIENTS,
  HOME_PAGE,
  PROFILE,
  SIGN_IN,
  USERS,
} from "../../routes";
import storage from "../../utils/storage";
import { Role } from "../users/constants";
import signInAPI from "../signIn/signInAPI";

import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";

import { MEDIA_QUERY_MOBILE } from "../../utils/constants";

export default function Root() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE);

  const drawerWidth = 220;
  const actualRoute = useMemo(
    () => window.location?.pathname,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location?.pathname],
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const navigateTo = (route: string) => {
    navigate(route);
    if (isMenuOpen) toggleMenu();
  };

  const isLoggedIn = !!storage.getAuthToken()?.length;

  const isAdmin = storage.getUserRole()?.includes(Role.admin);

  const logout = () => {
    signInAPI.setAuthToken("");
    navigate(SIGN_IN);
  };

  useEffect(() => {
    if (!isLoggedIn) navigate(SIGN_IN);
  }, [isLoggedIn, navigate]);

  const drawerContent = (
    <>
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List disablePadding>
          <ListItem
            key={"home"}
            disablePadding
            selected={actualRoute === HOME_PAGE}
          >
            <ListItemButton onClick={() => navigateTo(HOME_PAGE)}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Inicio" />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={"clients"}
            disablePadding
            selected={actualRoute === CLIENTS}
          >
            <ListItemButton onClick={() => navigateTo(CLIENTS)}>
              <ListItemIcon>
                <BusinessCenterIcon />
              </ListItemIcon>
              <ListItemText primary="Clientes" />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={"agents"}
            disablePadding
            selected={actualRoute === AGENTS}
          >
            <ListItemButton onClick={() => navigateTo(AGENTS)}>
              <ListItemIcon>
                <ContactPhoneIcon />
              </ListItemIcon>
              <ListItemText primary="Agentes" />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={"cargos"}
            disablePadding
            selected={actualRoute === CARGOS}
          >
            <ListItemButton onClick={() => navigateTo(CARGOS)}>
              <ListItemIcon>
                <DirectionsBoatIcon />
              </ListItemIcon>
              <ListItemText primary="Cargas" />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List disablePadding>
          {isAdmin && (
            <ListItem
              key={"users"}
              disablePadding
              selected={actualRoute === USERS}
            >
              <ListItemButton onClick={() => navigateTo(USERS)}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Usuarios" />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem
            key={"cities"}
            disablePadding
            selected={actualRoute === CITIES}
          >
            <ListItemButton onClick={() => navigateTo(CITIES)}>
              <ListItemIcon>
                <LocationCityIcon />
              </ListItemIcon>
              <ListItemText primary="Ciudades" />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={"profile"}
            disablePadding
            selected={actualRoute === PROFILE}
          >
            <ListItemButton onClick={() => navigateTo(PROFILE)}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary="Mi Perfil" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, boxShadow: "none" }}
      >
        <Toolbar>
          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              marginRight: 2,
            }}
            onClick={toggleMenu}
          >
            <MenuIcon />
          </Box>
          <div
            style={{ display: "flex", flexGrow: 1, cursor: "pointer" }}
            onClick={() => navigateTo(HOME_PAGE)}
          >
            <img
              src="/img/tailored-logo.png"
              height="45px"
              alt="Tailored logistics"
            />
          </div>
          <Button color="inherit" onClick={logout}>
            {isLoggedIn ? "Cerrar Sesión" : "Iniciar Sesión"}
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={isMenuOpen}
        onClose={toggleMenu}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          display: { xs: "none", sm: "block" },
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        open
      >
        {drawerContent}
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: isMobile ? 0 : 3,
          backgroundColor: "rgb(250, 250, 251)",
        }}
        maxWidth="100vw"
      >
        <Outlet />
      </Box>
    </Box>
  );
}
