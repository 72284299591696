import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { CargoMaritimeContainerType } from "../../../../cargosAPI";

const TableHeader = () => {
  const headCells = [
    {
      id: "seal",
      align: "left",
      disablePadding: false,
      label: "Precinto",
    },
    {
      id: "type",
      align: "left",
      disablePadding: false,
      label: "type",
    },
    {
      id: "number",
      align: "left",
      disablePadding: false,
      label: "Numero",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export const ContainerTable = ({
  containers,
}: {
  containers: CargoMaritimeContainerType[];
}) => {
  return (
    <TableContainer
      sx={{
        width: "100%",
        overflowX: "auto",
        position: "relative",
        display: "block",
        maxWidth: "100%",
        "& td, & th": { whiteSpace: "nowrap" },
      }}
    >
      <Table
        aria-labelledby="tableTitle"
        sx={{
          "& .MuiTableCell-root:first-child": {
            pl: 2,
          },
          "& .MuiTableCell-root:last-child": {
            pr: 3,
          },
        }}
      >
        <TableHeader />
        <TableBody>
          {containers.map((row, index) => {
            return (
              <TableRow
                hover
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
                tabIndex={-1}
                key={row.seal + index}
              >
                <TableCell component="th" scope="row" align="left">
                  {row.seal}
                </TableCell>
                <TableCell align="left">{row.type}</TableCell>
                <TableCell align="left">{row.number}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
