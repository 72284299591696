import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Menu,
  useMediaQuery,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { MEDIA_QUERY_MOBILE } from "../../../utils/constants";
import { CargoStatus, getCargoStatusName } from "../../../app/constants";

const StatusFilter = ({
  statusFilter,
  setStatusFilter,
}: {
  statusFilter?: string[];
  setStatusFilter?: (status: string[]) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheck = (value: string, checked: boolean) => {
    if (setStatusFilter && statusFilter) {
      if (checked) {
        setStatusFilter([...statusFilter, value]);
      } else {
        setStatusFilter(statusFilter.filter((status) => status !== value));
      }
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
        width: "33%",
      }}
    >
      <InputLabel
        id="status-label"
        style={{
          color: "black",
          width: "100px",
          display: useMediaQuery(MEDIA_QUERY_MOBILE) ? "none" : "flex",
        }}
      >
        Estado
      </InputLabel>

      <Button
        id="demo-customized-button"
        aria-controls={openMenu ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
        disableElevation
        sx={{
          width: "100%",
          height: "55px",
          display: "flex",
          justifyContent: "space-between",
          paddingX: 2,
          color: "rgba(0, 0, 0, 0.6)",
          textTransform: "capitalize",
          border: "1px solid rgba(0, 0, 0, 0.3)",
          fontSize: "1rem",
          fontWeight: 400,
        }}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        {statusFilter?.length === 0
          ? "Todos"
          : `${statusFilter?.length} seleccionados`}
      </Button>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <FormGroup sx={{ paddingX: 4 }}>
          {Object.entries(CargoStatus).map(([key, value]) => (
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={statusFilter?.includes(value)}
                  onChange={(e, checked) => handleCheck(value, checked)}
                />
              }
              label={getCargoStatusName(value)}
              key={key}
            />
          ))}
        </FormGroup>
      </Menu>
    </div>
  );
};

export default StatusFilter;
