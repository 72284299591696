import { CSSProperties, useEffect, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowId,
  GridSortModel,
} from "@mui/x-data-grid";
import type {} from "@mui/x-data-grid/themeAugmentation";
import { Button, Container, Rating } from "@mui/material";
import { Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { StatusType } from "../../app/constants";
import CustomFilterSection from "../shared/FiltersSection";
import { AgentType, GetAgentsFilters, NewAgentBodyType } from "./agentsAPI";
import {
  getAgentById,
  getAgents,
  selectAgents,
  selectAgentsStatus,
  selectTotalAgents,
} from "./agentsSlice";
import CreateAgent from "./CreateAgent";
import UpdateAgent from "./UpdateAgent";
import { useSearchParams } from "react-router-dom";

const CreateButtonStyle: CSSProperties = {
  marginBottom: 20,
  paddingLeft: 15,
  paddingRight: 15,
};

const initialAgent: NewAgentBodyType = {
  name: "",
  country: "",
  contacts: [],
  currentContacts: [],
  notes: "",
};

export default function DataTable() {
  const dispatch = useAppDispatch();

  const loading = useAppSelector(selectAgentsStatus) === StatusType.loading;
  const agents = useAppSelector(selectAgents);
  const total = useAppSelector(selectTotalAgents);

  const [currentAgent, setCurrentAgent] = useState({ ...initialAgent });
  const [pageModel, setPageModel] = useState({ page: 0, pageSize: 10 });
  const [search, setSearch] = useState("");
  const [openCreate, setOpenCreate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "rating",
      sort: null,
    },
  ]);

  const [searchParams, setSearchParams] = useSearchParams();
  const defaultSelectedAgent = searchParams.get("selected");

  const getAgentsPage = (newFilters?: GetAgentsFilters) =>
    dispatch(
      getAgents({
        page: pageModel.page + 1,
        pageSize: pageModel.pageSize,
        search,
        sortField: sortModel[0]?.field,
        sortValue: sortModel[0]?.sort,
        ...newFilters,
      }),
    );

  const onClickAddButton = () => {
    setCurrentAgent({ ...initialAgent });
    setOpenCreate(true);
  };

  const onCloseCreate = () => {
    setOpenCreate(false);
    setCurrentAgent({ ...initialAgent });
    getAgentsPage();
  };

  const updateAgent = (id: GridRowId) => {
    if (id) {
      dispatch(getAgentById(id.toString())).then((value) => {
        if (value.meta.requestStatus === "fulfilled") {
          const agent: AgentType = value.payload;
          setCurrentAgent({
            ...agent,
            currentContacts:
              agent.contacts?.map((c, i) => ({ ...c, id: i })) ?? [],
          });
          setOpenUpdate(true);
        }
      });
    }
  };

  const onCloseUpdate = (updateAgents = false) => {
    setOpenUpdate(false);
    setCurrentAgent({ ...initialAgent });
    if (updateAgents) getAgentsPage();
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nombre",
      width: 230,
      filterable: false,
      sortable: false,
    },
    {
      field: "country",
      headerName: "País",
      width: 200,
      filterable: false,
      sortable: false,
    },
    {
      field: "contacts",
      headerName: "Cantidad de contactos",
      valueFormatter: ({ value }: { value: any[] }) => value.length,
      width: 200,
      filterable: false,
      sortable: false,
    },
    {
      field: "rating",
      headerName: "Rating",
      width: 150,
      renderCell: (params: GridRenderCellParams<any, number>) => {
        const row: AgentType = params.row;
        if (!row.totalRankings) {
          return "N/A";
        }
        return <Rating readOnly value={row.ranking} precision={0.5} />;
      },
      editable: false,
      filterable: false,
    },
    {
      field: "ratingTotals",
      headerName: "Nº Puntuaciones",
      width: 150,
      renderCell: (params: GridRenderCellParams<any, number>) => {
        const row: AgentType = params.row;
        if (!row.totalRankings) {
          return 0;
        }
        return row.totalRankings;
      },
      editable: false,
      filterable: false,
      sortable: false,
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Modificar"
          onClick={() => updateAgent(params.id)}
        />,
      ],
      filterable: false,
      sortable: false,
    },
  ];

  const onPaginationModelChange = (model: GridPaginationModel) => {
    setPageModel({ page: model.page, pageSize: model.pageSize });
    getAgentsPage({ page: model.page + 1, pageSize: model.pageSize });
  };

  const onSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
    getAgentsPage({
      sortField: model[0]?.field ?? null,
      sortValue: model[0]?.sort ?? null,
    });
  };

  useEffect(() => {
    if (loading) return;
    getAgentsPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAgentsPage({
      search: search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (defaultSelectedAgent) updateAgent(defaultSelectedAgent);
    window.history.replaceState({}, document.title, window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelectedAgent]);

  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        height: "calc(100vh - 115px)",
        marginTop: 8,
      }}
    >
      <Button
        variant="contained"
        color="primary"
        aria-label="add"
        style={CreateButtonStyle}
        onClick={onClickAddButton}
      >
        <AddIcon />
        <span>Crear</span>
      </Button>

      <div style={{ height: "74%", width: "100%" }}>
        <CustomFilterSection searchValue={search} setSearchValue={setSearch} />
        <DataGrid
          sx={{
            borderRadius: "0px 0px 5px 5px",
            border: "1px lightgray solid",
            borderTop: "unset",
            "& .MuiDataGrid-columnHeaderTitleContainer": { padding: "0 10px" },
            "& .MuiDataGrid-cell": { padding: "0 20px" },
            "& .MuiDataGrid-row": { cursor: "pointer" },
          }}
          filterMode="server"
          paginationMode="server"
          pagination={true}
          paginationModel={pageModel}
          onPaginationModelChange={onPaginationModelChange}
          loading={loading}
          rows={agents}
          columns={columns}
          disableColumnMenu
          rowCount={total}
          pageSizeOptions={[5, 10, 25]}
          onRowClick={(params) => updateAgent(params.id)}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          onSortModelChange={onSortModelChange}
          sortModel={sortModel}
        />
      </div>

      <CreateAgent
        currentAgent={currentAgent}
        open={openCreate}
        onClose={onCloseCreate}
        setCurrentAgent={setCurrentAgent}
      />

      <UpdateAgent
        currentAgent={currentAgent}
        open={openUpdate}
        onClose={onCloseUpdate}
        setCurrentAgent={setCurrentAgent}
      />
    </Container>
  );
}
