import { GridSortDirection } from "@mui/x-data-grid";
import API from "../../app/apiConfig";
import { transformObjectToQueryString } from "../../utils/transformObjectToQueryString";

const base = "/agents";

export type GetAgentsFilters = {
  search?: string;
  page?: number;
  pageSize?: number;
  sortField?: string;
  sortValue?: GridSortDirection;
};

export type AgentType = {
  _id: string;
  name: string;
  notes: string;
  country: string;
  contacts?: ContactType[];
  ranking?: number;
  totalRankings?: number;
};

export type NewAgentBodyType = {
  _id?: string;
  name: string;
  notes: string;
  country: string;
  contacts?: ContactType[];
  currentContacts?: ExtendedContactType[];
  ranking?: number;
  totalRankings?: number;
};

export type ContactType = {
  phone: string;
  email: string;
};

export type ExtendedContactType = ContactType & { id: number };

function getAgents(filters: GetAgentsFilters) {
  const filterString = transformObjectToQueryString(filters);

  return API.get(`${base}?${filterString}`);
}

function getAgentById(id: string) {
  return API.get(`${base}/${id}`);
}

function createAgent(body: NewAgentBodyType) {
  return API.post(base, body);
}

function updateAgent(body: NewAgentBodyType) {
  return API.put(`${base}/${body._id}`, body);
}

const agentsAPI = { getAgents, getAgentById, createAgent, updateAgent };
export default agentsAPI;
