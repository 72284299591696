import API from "../../app/apiConfig";
import { transformObjectToQueryString } from "../../utils/transformObjectToQueryString";

const base = "/cities";

export type GetCitiesFilters = {
  search?: string;
  page?: number;
  pageSize?: number;
};

export type CityType = {
  _id: string;
  code: string;
  cityName: string;
  cityCountry: string;
};

export type NewCityBodyType = {
  _id?: string;
  code: string;
  cityName: string;
  cityCountry: string;
};

function getCities(filters: GetCitiesFilters) {
  const filterString = transformObjectToQueryString(filters);

  return API.get(`${base}?${filterString}`);
}

function createCity(body: NewCityBodyType) {
  return API.post(base, body);
}

function updateCity(body: NewCityBodyType) {
  return API.put(`${base}/${body._id}`, body);
}

function deleteCity(id: string) {
  return API.delete(`${base}/${id}`);
}

const citiesAPI = { getCities, createCity, updateCity, deleteCity };
export default citiesAPI;
