import { grey } from "@mui/material/colors";
import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  footer: {
    margin: 0,
    padding: 15,
    height: 80,
    width: "100%",
    flexDirection: "row",
  },

  marginBox: {
    width: "80px",
    alignItems: "center",
    justifyContent: "center",
  },
  middleBox: {
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    gap: 5,
  },
  logo: {
    height: 40,
    width: 40,
  },
  text: {
    fontSize: 8,
  },
  disclaimerText: {
    fontSize: 6,
    color: grey[800],
    marginBottom: 2,
    textAlign: "center",
  },
});

export default styles;
