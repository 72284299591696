import API from "../../app/apiConfig";
import { transformObjectToQueryString } from "../../utils/transformObjectToQueryString";

const base = "/users";

export type GetUsersFilters = {
  search?: string;
  page?: number;
  pageSize?: number;
};

export type UserType = {
  _id: string;
  email: string;
  name: string;
  roles: string[];
};

export type NewUserBodyType = {
  _id?: string;
  email: string;
  name: string;
  password?: string;
  roles: string[];
};

function getUsers(filters: GetUsersFilters) {
  const filterString = transformObjectToQueryString(filters);

  return API.get(`${base}?${filterString}`);
}

function createUser(body: NewUserBodyType) {
  return API.post(base, body);
}

function updateUser(body: NewUserBodyType) {
  return API.put(`${base}/${body._id}`, body);
}

function getUserById(id: string) {
  return API.get(`${base}/${id}`);
}

function deleteUser(id: string) {
  return API.delete(`${base}/${id}`);
}

const usersAPI = { getUsers, createUser, updateUser, getUserById, deleteUser };
export default usersAPI;
