import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HOME_PAGE } from "./routes";

export default function ErrorPage() {
  const navigate = useNavigate();
  useEffect(() => navigate(HOME_PAGE), [navigate]);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
    </div>
  );
}
