export const HOME_PAGE = "/";
export const SIGN_IN = "/login";
export const USERS = "/users";
export const CITIES = "/cities";
export const PROFILE = "/profile";
export const CLIENTS = "/clients";
export const CREATE_CLIENT = "/clients/create";
export const CLIENT_VIEW = "/clients/:clientId";
export const AGENTS = "/agents";
export const CARGOS = "/cargas";
export const CREATE_CARGO = "/cargas/create";
export const CARGO_VIEW = "/cargas/:cargoId";
