import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusType } from "../../app/constants";
import { RootState } from "../../app/store";
import clientBrokersAPI, {
  ClientBrokerType,
  GetClientBrokersFilters,
} from "./clientBrokersAPI";

export interface ClientsState {
  getClientBrokersStatus: StatusType;
  clientBrokers: ClientBrokerType[];
  totalClientBrokers: number;

  createClientBrokerStatus: StatusType;
  deleteClientBrokerStatus: StatusType;
}

const initialState: ClientsState = {
  getClientBrokersStatus: StatusType.idle,
  clientBrokers: [],
  totalClientBrokers: 0,

  createClientBrokerStatus: StatusType.idle,
  deleteClientBrokerStatus: StatusType.idle,
};

export const getClientBrokers = createAsyncThunk(
  "clients/getClientBrokers",
  async (filters: GetClientBrokersFilters) => {
    const response = await clientBrokersAPI.getClients(filters);
    return response.data;
  },
);

export const createClientBroker = createAsyncThunk(
  "clients/createClientBroker",
  async (body: ClientBrokerType, { rejectWithValue }) => {
    try {
      const response = await clientBrokersAPI.createClient(body);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.message);
    }
  },
);

export const deleteClientBroker = createAsyncThunk(
  "clients/deleteClientBroker",
  async (id: string) => {
    const response = await clientBrokersAPI.deleteClientBroker(id);
    return response.data;
  },
);

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClientBrokers.pending, (state) => {
        state.getClientBrokersStatus = StatusType.loading;
      })
      .addCase(getClientBrokers.fulfilled, (state, action) => {
        state.getClientBrokersStatus = StatusType.succeeded;
        state.totalClientBrokers = action.payload.totalDocs;
        state.clientBrokers = action.payload.docs.map(
          (doc: ClientBrokerType) => ({
            ...doc,
            id: doc._id,
          }),
        );
      })
      .addCase(getClientBrokers.rejected, (state) => {
        state.getClientBrokersStatus = StatusType.failed;
      })

      .addCase(createClientBroker.pending, (state) => {
        state.createClientBrokerStatus = StatusType.loading;
      })
      .addCase(createClientBroker.fulfilled, (state) => {
        state.createClientBrokerStatus = StatusType.succeeded;
      })
      .addCase(createClientBroker.rejected, (state) => {
        state.createClientBrokerStatus = StatusType.failed;
      })

      .addCase(deleteClientBroker.pending, (state) => {
        state.deleteClientBrokerStatus = StatusType.loading;
      })
      .addCase(deleteClientBroker.fulfilled, (state) => {
        state.deleteClientBrokerStatus = StatusType.succeeded;
      })
      .addCase(deleteClientBroker.rejected, (state) => {
        state.deleteClientBrokerStatus = StatusType.failed;
      });
  },
});

export const selectClientBrokersStatus = (state: RootState) =>
  state.clientBrokers.getClientBrokersStatus;
export const selectTotalClientBrokers = (state: RootState) =>
  state.clientBrokers.totalClientBrokers;
export const selectClientBrokers = (state: RootState) =>
  state.clientBrokers.clientBrokers;

export const selectCreateClientBrokersStatus = (state: RootState) =>
  state.clientBrokers.createClientBrokerStatus;
export const selectDeleteClientBrokersStatus = (state: RootState) =>
  state.clientBrokers.deleteClientBrokerStatus;

export default clientsSlice.reducer;
