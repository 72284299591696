import { Box } from "@mui/material";

import { NewCargoBodyType } from "../../../../cargosAPI";
import { TextTitle } from "../../shared/TextTitle";
import { TextSubtitle } from "../../shared/TextSubtitle";

export const Commodity = ({
  currentCargo,
}: {
  currentCargo: NewCargoBodyType;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        gap: 3,
        justifyContent: "space-between",
      }}
    >
      <Box>
        <TextTitle label="Descripción mercadería" />
        <TextSubtitle label={currentCargo.commodity?.description} />
      </Box>
      <Box>
        <TextTitle label="Bultos" />
        <TextSubtitle
          label={currentCargo.commodity?.quantities?.package?.toString()}
        />
      </Box>
      <Box>
        <TextTitle label="Peso bruto" />
        <TextSubtitle
          label={currentCargo.commodity?.quantities?.grossWeight?.toString()}
        />
      </Box>

      <Box>
        <TextTitle label="Peso cargable" />
        <TextSubtitle
          label={currentCargo.commodity?.quantities?.weight?.toString()}
        />
      </Box>
      <Box>
        <TextTitle label="Volumen" />
        <TextSubtitle
          label={currentCargo.commodity?.quantities?.height?.toString()}
        />
      </Box>
    </Box>
  );
};
