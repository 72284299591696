import { Autocomplete, Box, Paper, TextField, Typography } from "@mui/material";

import { NewCargoBodyType } from "../../../cargosAPI";
import { CityType } from "../../../../cities/citiesAPI";
import { useAppSelector } from "../../../../../app/hooks";
import { selectCities } from "../../../../cities/citiesSlice";

export default function RoutesForm({
  currentCargo,
  handleOnChange,
  canUpdate,
}: {
  currentCargo: NewCargoBodyType;
  handleOnChange: (label: string, value: string | any | null) => void;

  canUpdate?: boolean;
}) {
  const cities = useAppSelector(selectCities);

  return (
    <Paper elevation={1} sx={{ padding: 3, borderRadius: 5, margin: "20px 0" }}>
      <Typography variant="h6">Ruta</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          marginTop: 2,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Autocomplete
            disablePortal
            id="departure-city"
            options={cities}
            disabled={!canUpdate}
            getOptionLabel={(city) => `${city.cityName} (${city.code})`}
            onChange={(_e, val) =>
              handleOnChange("cities", {
                ...currentCargo.cities,
                departure: val?._id,
              })
            }
            isOptionEqualToValue={(option, value) => option._id === value._id}
            defaultValue={currentCargo.cities?.departure as CityType}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="Punto de salida"
              />
            )}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <TextField
            value={currentCargo.cities?.crossingCustoms ?? ""}
            margin="normal"
            fullWidth
            name="crossingCustoms"
            label="Aduana de cruce"
            type="text"
            disabled={!canUpdate}
            id="crossingCustoms"
            sx={{ marginTop: 0 }}
            onChange={(e) =>
              handleOnChange("cities", {
                ...currentCargo.cities,
                crossingCustoms: e.target.value,
              })
            }
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Autocomplete
            disablePortal
            id="destination-city"
            options={cities}
            getOptionLabel={(city) => `${city.cityName} (${city.code})`}
            disabled={!canUpdate}
            onChange={(_e, val) =>
              handleOnChange("cities", {
                ...currentCargo.cities,
                destination: val?._id,
              })
            }
            isOptionEqualToValue={(option, value) => option._id === value._id}
            defaultValue={currentCargo.cities?.destination as CityType}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="Lugar de descarga"
              />
            )}
          />
        </Box>
      </Box>
    </Paper>
  );
}
