import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getProfile,
  selectProfile,
  selectStatusProfile,
  updateProfile,
} from "../signIn/signInSlice";
import ProfileForm from "./ProfileForm";
import formValidations from "../../utils/formValidations";

export default function DataTable() {
  const dispatch = useAppDispatch();
  const authProfile = useAppSelector(selectProfile);
  const profileStatus = useAppSelector(selectStatusProfile);

  const initialProfileInfo = {
    name: authProfile?.name,
    email: authProfile?.email,
    oldPassword: undefined,
    password: undefined,
  };

  const [profile, setProfile] = useState(initialProfileInfo);

  const [formError, setFormError] = useState({
    name: false,
    email: false,
  });

  useEffect(() => {
    setProfile({
      name: authProfile?.name,
      email: authProfile?.email,
      oldPassword: undefined,
      password: undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authProfile]);

  const resetProfile = () => {
    setProfile(initialProfileInfo);
  };

  const handleOnChange = (label: string, value: string | string[]) => {
    setProfile((data) => ({ ...data, [label]: value }));
    setFormError((errors) => ({ ...errors, [label]: false }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validName =
      profile?.name?.length && formValidations.validateName(profile?.name);

    const validEmail =
      profile?.email?.length && formValidations.validateEmail(profile?.email);

    if (!validName) {
      setFormError((errors) => ({ ...errors, name: true }));
    }

    if (!validEmail) {
      setFormError((errors) => ({ ...errors, email: true }));
    }

    if (validName && validEmail) {
      dispatch(updateProfile(profile)).then((value) => {
        if (value.meta.requestStatus === "fulfilled") {
          dispatch(getProfile);
        }
      });
    }
  };

  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-center",
        marginTop: 8,
        height: "calc(100vh - 115px)",
      }}
    >
      {authProfile?.email?.length && (
        <ProfileForm
          profile={authProfile}
          updatedProfile={profile}
          formError={formError}
          handleOnChange={handleOnChange}
          handleSubmit={handleSubmit}
          resetProfile={resetProfile}
          status={profileStatus}
        />
      )}
    </Container>
  );
}
