import { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Card, CardContent, IconButton, InputAdornment } from "@mui/material";
import formValidations from "../../utils/formValidations";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectSignInStatus, signInWithMail } from "./signInSlice";
import { StatusType } from "../../app/constants";
import { useNavigate } from "react-router-dom";
import storage from "../../utils/storage";
import LoadingButton from "../shared/LoadingButton";

export default function SignIn() {
  const [signInData, setSignInData] = useState({ email: "", password: "" });
  const [formError, setFormError] = useState({
    email: false,
    password: false,
    authError: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectSignInStatus) === StatusType.loading;
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleOnChange = (label: string, value: string) => {
    setSignInData((data) => ({ ...data, [label]: value }));
    setFormError((errors) => ({ ...errors, authError: false, [label]: false }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (loading) return;

    const validEmail =
      signInData.email.length &&
      formValidations.validateEmail(signInData.email);

    if (!validEmail) {
      setFormError((errors) => ({ ...errors, email: true }));
    }

    if (!signInData.password.length) {
      setFormError((errors) => ({ ...errors, password: true }));
    }

    if (validEmail && signInData.password.length) {
      dispatch(signInWithMail(signInData)).then((value) => {
        if (value.meta.requestStatus === "fulfilled") {
          navigate("/");
        } else {
          setFormError({
            email: false,
            password: false,
            authError: true,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (storage.getAuthToken()) navigate("/");
  }, [navigate]);

  return (
    <Container
      component="main"
      maxWidth="xs"
      style={{
        backgroundImage: 'url("/img/login-background.jpg")',
        height: "100vh",
        maxWidth: "100%",
        paddingBottom: "20%",
      }}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 8,
          maxWidth: "450px",
        }}
      >
        <Card>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Iniciar Sesión
              </Typography>
            </Box>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                value={signInData.email}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Correo electrónico"
                name="email"
                autoComplete="email"
                autoFocus
                type="email"
                onChange={(e) => handleOnChange("email", e.target.value)}
                error={formError.email}
                helperText={
                  formError.email ? "Por favor, ingresa un correo valido" : ""
                }
              />
              <TextField
                value={signInData.password}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                onChange={(e) => handleOnChange("password", e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={formError.password}
                helperText={
                  formError.password ? "Por favor, ingresa una contraseña" : ""
                }
              />

              {formError.authError && (
                <Typography
                  variant="body2"
                  component="h1"
                  sx={{ color: "red" }}
                >
                  Credenciales incorrectas
                </Typography>
              )}

              <LoadingButton
                loading={loading}
                text="Iniciar sesión"
                type="submit"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
