import { SxProps, Typography } from "@mui/material";
import { Theme } from "@mui/system";

export const TextTitle = ({
  style,
  label,
}: {
  style?: SxProps<Theme>;
  label: string;
}) => {
  return (
    <Typography
      sx={{
        fontSize: { sm: 14, xl: 18 },
        marginBottom: 1,
        fontWeight: "700",
        ...style,
      }}
    >
      {label}
    </Typography>
  );
};
