import { useEffect } from "react";
import { Box } from "@mui/material";
import { LocalizationProvider, esES } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

import {
  isAerealCargo,
  isCustomsCargo,
  isGroundCargo,
} from "../../../app/constants";
import { NewCargoBodyType } from "../cargosAPI";
import { AerealCargoDisplay } from "./Displays/AerealCargoDisplay/AerealCargoDisplay";
import { GroundCargoDisplay } from "./Displays/GroundCargoDisplay/GroundCargoDisplay";
import { CustomCargoDisplay } from "./Displays/CustomCargoDisplay/CustomCargoDisplay";
import { MaritimeCargoDisplay } from "./Displays/MaritimeCargoDisplay/MaritimeCargoDisplay";

const CargoDisplay = ({
  currentCargo,
  setCurrentCargo,
  cargoId,
  canUpdate,
}: {
  currentCargo: NewCargoBodyType;
  cargoId: string;
  canUpdate: boolean;
  setCurrentCargo?:
    | ((value: React.SetStateAction<NewCargoBodyType | null>) => void)
    | ((value: React.SetStateAction<NewCargoBodyType>) => void);
}) => {
  useEffect(() => {
    if (setCurrentCargo)
      setCurrentCargo({
        ...currentCargo,
        currentFinance:
          currentCargo.finance?.map((financeRow, i) => ({
            ...financeRow,
            id: i,
          })) ?? [],
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ maxHeight: "100%" }}>
      <LocalizationProvider
        dateAdapter={AdapterLuxon}
        localeText={
          esES.components.MuiLocalizationProvider.defaultProps.localeText
        }
        adapterLocale="es"
      >
        {isAerealCargo(currentCargo.type) ? (
          <AerealCargoDisplay
            currentCargo={currentCargo}
            cargoId={cargoId}
            canUpdate={canUpdate}
          />
        ) : isGroundCargo(currentCargo.type) ? (
          <GroundCargoDisplay
            currentCargo={currentCargo}
            cargoId={cargoId}
            canUpdate={canUpdate}
          />
        ) : isCustomsCargo(currentCargo.type) ? (
          <CustomCargoDisplay
            currentCargo={currentCargo}
            cargoId={cargoId}
            canUpdate={canUpdate}
          />
        ) : (
          <MaritimeCargoDisplay
            currentCargo={currentCargo}
            cargoId={cargoId}
            canUpdate={canUpdate}
          />
        )}
      </LocalizationProvider>
    </Box>
  );
};

export default CargoDisplay;
