import { Image, Text, View } from "@react-pdf/renderer";

import styles from "./styles";

export const TemplateFooter = () => {
  return (
    <View style={{ marginTop: 4 }}>
      <View
        style={{
          paddingHorizontal: "5%",
        }}
      >
        <Text style={styles.disclaimerText}>
          TAILORED LOGISTICS SERVICES agradece la contratación de nuestros
          servicios
        </Text>
        <Text style={styles.disclaimerText}>
          El tipo de cambio utilizado para el pago de conceptos en moneda
          extranjera, será el vigente al día efectivo del pago. El importe
          detallado puede ser depositado por transferencia en la siguiente
          cuenta: Banco Santander, Sucursal N° 0073, Cuenta Corriente Dólares
          N°005101088962
        </Text>

        <Text style={styles.disclaimerText}>
          La fecha de llegada puede variar sin previo aviso y son siempre a
          confirmar, en caso de variaciones de la misma le contactaremos
          nuevamente. Sugerimos consultarnos sobre la fecha de arribo que
          aparece en el aviso. No validamos reclamos de cargas sin cobertura de
          seguro apropiada para el tipo de transporte contratado
        </Text>
      </View>
      <View fixed style={styles.footer}>
        <View style={styles.marginBox}></View>
        <View style={styles.middleBox}>
          <Text style={styles.text}>Tailored Logistics Services</Text>
          <Text style={styles.text}>
            Ruta 8 Km 17,500 - Zonamerica - Uruguay
          </Text>
          <Text style={styles.text}>(+598) 25185359 - (+598) 95123120</Text>
          <Text style={styles.text}>
            Tel: 25185359 - www.tailoredlogisticsservices.com
          </Text>
        </View>
        <View style={styles.marginBox}>
          <Image src="/img/tailored-min-logo.png" style={styles.logo} />
        </View>
      </View>
    </View>
  );
};
