import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    border: "1px solid black",
    padding: 5,
  },

  fillingText: {
    fontSize: 6,
    color: "black",
    border: "1px solid #CAC8C8",
    padding: 2,
    fontWeight: 700,
    marginTop: 2,
  },

  subtitle: {
    fontSize: 8,
    fontWeight: 300,
  },

  title: {
    fontSize: 10,
    fontWeight: 800,
    textTransform: "uppercase",
  },
});

export default styles;
