import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import { LocalizationProvider, esES } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

import {
  CargoTypeEnum,
  IncoTerm,
  isAerealCargo,
  isCustomsCargo,
  isGroundCargo,
  isMaritimeCargo,
  StatusType,
} from "../../../app/constants";
import { ClientType } from "../../clients/clientsAPI";
import { getCargoTypeName } from "../../../app/constants";
import LoadingButton from "../../shared/LoadingButton";
import { CurrencyCode, NewCargoBodyType } from "../cargosAPI";
import { AgentType } from "../../agents/agentsAPI";
import MaritimeCargoForm from "./MaritimeCargoForm/MaritimeCargoForm";
import { useAppSelector } from "../../../app/hooks";
import GroundCargoForm from "./GroundCargoForm/GroundCargoForm";
import { selectProfile } from "../../signIn/signInSlice";
import { Role } from "../../users/constants";
import AereoCargoForm from "./AereoCargoForm/AereoCargoForm";
import CustomCargoForm from "./CustomCargoForm/CustomCargoForm";

export default function CargoForm({
  currentCargo,
  handleSubmit,
  handleOnChange,
  setCurrentCargo,
  formError,
  maritimeFormError,
  aereoFormError,
  groundFormError,
  customsFormError,
  status,
  isUpdate,
  cargoClient,
  cargoAgent,
  onCancel,
}: {
  status: StatusType;
  currentCargo: NewCargoBodyType;
  handleSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    updatedCargo: NewCargoBodyType | null,
  ) => void;
  handleOnChange: (label: string, value: string | null | any) => void;
  setCurrentCargo?:
    | ((value: React.SetStateAction<NewCargoBodyType | null>) => void)
    | ((value: React.SetStateAction<NewCargoBodyType>) => void);
  formError: {
    type: boolean;
    incoterm: boolean;
    client: boolean;
    agent: boolean;
    departureCity: boolean;
    destinationCity: boolean;
    originCity: boolean;
    shipper: boolean;
    finance: boolean;
  };
  maritimeFormError: {
    etd: boolean;
    eta: boolean;
    terminal: boolean;
    booking: boolean;
    mbl: boolean;
    hbl: boolean;
    connections: boolean;
    containers: boolean;
  };
  aereoFormError: {
    etd: boolean;
    eta: boolean;
    booking: boolean;
    hawb: boolean;
    mawb: boolean;
    flightNumber: boolean;
  };
  groundFormError: {
    crt: boolean;
    dua: boolean;
  };
  customsFormError: {
    knowledgeNumber: boolean;
  };
  isUpdate?: boolean;
  cargoClient?: ClientType;
  cargoAgent?: AgentType;
  onCancel: (update?: boolean) => void;
}) {
  const [selectedClient, setSelectedClient] = useState<ClientType | null>(null);

  const profile = useAppSelector(selectProfile);
  const isAdmin = useMemo(
    () =>
      (profile?.roles?.includes(Role.admin) ||
        profile?.roles?.includes(Role.customerService)) ??
      false,
    [profile],
  );

  const canUpdate = useMemo(
    () =>
      !isUpdate ||
      isAdmin ||
      (profile && (selectedClient?.sellers as string[])?.includes(profile._id)),
    [isUpdate, isAdmin, profile, selectedClient],
  );

  const [selectedAgent, setSelectedAgent] = useState<AgentType | null>(null);

  const [currentMaritimeCargo, setCurrentMaritimeCargo] = useState(
    currentCargo.maritime,
  );

  const [currentAereoCargo, setCurrentAereoCargo] = useState(
    currentCargo.aereal,
  );

  const [currentGroundCargo, setCurrentGroundCargo] = useState(
    currentCargo.ground,
  );

  const [currentCustomsCargo, setCurrentCustomsCargo] = useState(
    currentCargo.customs,
  );

  const handleMaritimeCargoOnChange = (
    label: string,
    value: string | null | undefined | any,
  ) => {
    if (currentMaritimeCargo) {
      setCurrentMaritimeCargo((data) => {
        if (!!data) return { ...data, [label]: value };
      });
    }
  };

  const handleAereoCargoOnChange = (
    label: string,
    value: string | null | undefined | any,
  ) => {
    if (currentAereoCargo) {
      setCurrentAereoCargo((data) => {
        if (!!data) return { ...data, [label]: value };
      });
    }
  };

  const handleGroundCargoOnChange = (
    label: string,
    value: string | null | undefined | any,
  ) => {
    if (currentGroundCargo) {
      setCurrentGroundCargo((data) => {
        if (!!data) return { ...data, [label]: value };
      });
    }
  };

  const handleCustomsCargoOnChange = (
    label: string,
    value: string | null | undefined | any,
  ) => {
    if (currentCustomsCargo) {
      setCurrentCustomsCargo((data) => {
        if (!!data) return { ...data, [label]: value };
      });
    }
  };

  useEffect(() => {
    if (setCurrentCargo)
      setCurrentCargo({
        ...currentCargo,
        currentFinance:
          currentCargo.finance?.map((financeRow, i) => ({
            ...financeRow,
            id: i,
          })) ?? [],
      });
    setCurrentMaritimeCargo((data) => {
      if (!!data)
        return {
          ...data,
          currentContainers:
            currentCargo.maritime?.containers?.map((container, i) => ({
              ...container,
              id: i,
            })) ?? [],
          currentConnections:
            currentCargo.maritime?.connections?.map((connection, i) => ({
              ...connection,
              id: i,
            })) ?? [],
        };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateSubmission = (e: React.FormEvent<HTMLFormElement>) => {
    let cargo = currentCargo;

    if (
      cargo.cities?.departure &&
      !(typeof cargo.cities?.departure === "string")
    ) {
      cargo.cities.departure = cargo.cities.departure._id;
    }

    if (cargo.cities?.origin && !(typeof cargo.cities?.origin === "string")) {
      cargo.cities.origin = cargo.cities.origin._id;
    }

    if (
      cargo.cities?.destination &&
      !(typeof cargo.cities?.destination === "string")
    ) {
      cargo.cities.destination = cargo.cities.destination._id;
    }

    const maritimeCargo = currentMaritimeCargo;
    const aereoCargo = currentAereoCargo;
    const groundCargo = currentGroundCargo;
    const customsCargo = currentCustomsCargo;

    if (maritimeCargo && isMaritimeCargo(currentCargo.type)) {
      maritimeCargo.containers = maritimeCargo?.currentContainers ?? [];
      maritimeCargo.connections = maritimeCargo?.currentConnections ?? [];

      cargo = {
        ...cargo,
        client: selectedClient?._id,
        agent: selectedAgent?._id,
        maritime: maritimeCargo,
        aereal: undefined,
        ground: undefined,
        customs: undefined,
        finance:
          cargo?.currentFinance?.map((extendedFinance) => ({
            type: extendedFinance.type,
            amount: extendedFinance.amount,
            hasIVA: extendedFinance.hasIVA,
            concept: extendedFinance.concept,
            currency: extendedFinance.currency ?? CurrencyCode.USD,
          })) ?? [],
      };

      if (setCurrentCargo) setCurrentCargo(cargo);
    }

    if (aereoCargo && isAerealCargo(currentCargo.type)) {
      cargo = {
        ...cargo,
        client: selectedClient?._id,
        agent: selectedAgent?._id,
        aereal: aereoCargo,
        maritime: undefined,
        ground: undefined,
        customs: undefined,
        finance:
          cargo?.currentFinance?.map((extendedFinance) => ({
            type: extendedFinance.type,
            amount: extendedFinance.amount,
            hasIVA: extendedFinance.hasIVA,
            concept: extendedFinance.concept,
            currency: extendedFinance.currency ?? CurrencyCode.USD,
          })) ?? [],
      };
    }

    if (groundCargo && isGroundCargo(currentCargo.type)) {
      cargo = {
        ...cargo,
        client: selectedClient?._id,
        agent: selectedAgent?._id,
        ground: groundCargo,
        maritime: undefined,
        aereal: undefined,
        customs: undefined,
        finance:
          cargo?.currentFinance?.map((extendedFinance) => ({
            type: extendedFinance.type,
            amount: extendedFinance.amount,
            hasIVA: extendedFinance.hasIVA,
            concept: extendedFinance.concept,
            currency: extendedFinance.currency ?? CurrencyCode.USD,
          })) ?? [],
      };
    }

    if (customsCargo && isCustomsCargo(currentCargo.type)) {
      cargo = {
        ...cargo,
        client: selectedClient?._id,
        agent: selectedAgent?._id,
        customs: customsCargo,
        maritime: undefined,
        aereal: undefined,
        ground: undefined,
        finance:
          cargo?.currentFinance?.map((extendedFinance) => ({
            type: extendedFinance.type,
            amount: extendedFinance.amount,
            hasIVA: extendedFinance.hasIVA,
            concept: extendedFinance.concept,
            currency: extendedFinance.currency ?? CurrencyCode.USD,
          })) ?? [],
      };
    }

    handleSubmit(e, cargo);
  };

  useEffect(() => {
    if (isUpdate && cargoClient) {
      setSelectedClient(cargoClient);
    }

    if (isUpdate && cargoAgent) {
      setSelectedAgent(cargoAgent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate]);

  return (
    <Box
      component="form"
      onSubmit={handleCreateSubmission}
      noValidate
      sx={{ mt: 1, minWidth: { xs: "90%", sm: "100%" } }}
    >
      <LocalizationProvider
        dateAdapter={AdapterLuxon}
        localeText={
          esES.components.MuiLocalizationProvider.defaultProps.localeText
        }
        adapterLocale="es"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: "10px",
          }}
        >
          <FormControl fullWidth margin="normal">
            <InputLabel id="type-label">Tipo de carga*</InputLabel>
            <Select
              labelId="type"
              id="type"
              value={currentCargo.type ?? ""}
              required
              label="Tipo de carga*"
              disabled={isUpdate}
              onChange={(e) =>
                handleOnChange &&
                handleOnChange("type", e.target.value ? e.target.value : null)
              }
            >
              <MenuItem value={CargoTypeEnum.IM}>
                {getCargoTypeName(CargoTypeEnum.IM)}
              </MenuItem>
              <MenuItem value={CargoTypeEnum.EM}>
                {getCargoTypeName(CargoTypeEnum.EM)}
              </MenuItem>
              <MenuItem value={CargoTypeEnum.IA}>
                {getCargoTypeName(CargoTypeEnum.IA)}
              </MenuItem>
              <MenuItem value={CargoTypeEnum.EA}>
                {getCargoTypeName(CargoTypeEnum.EA)}
              </MenuItem>
              <MenuItem value={CargoTypeEnum.IT}>
                {getCargoTypeName(CargoTypeEnum.IT)}
              </MenuItem>
              <MenuItem value={CargoTypeEnum.ET}>
                {getCargoTypeName(CargoTypeEnum.ET)}
              </MenuItem>
              <MenuItem value={CargoTypeEnum.DA}>
                {getCargoTypeName(CargoTypeEnum.DA)}
              </MenuItem>
            </Select>
            {formError.type && (
              <Typography
                sx={{
                  color: "#d32f2f",
                  fontSize: "0.75rem",
                  fontWeight: 400,
                  marginTop: "7px",
                }}
              >
                Por favor ingrese un tipo válido
              </Typography>
            )}
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="incoterm-label">Incoterm*</InputLabel>
            <Select
              labelId="incoterm"
              id="incoterm"
              value={currentCargo.incoterm ?? ""}
              label="Incoterm*"
              required
              disabled={!canUpdate}
              onChange={(e) =>
                handleOnChange &&
                handleOnChange(
                  "incoterm",
                  e.target.value ? e.target.value : null,
                )
              }
            >
              <MenuItem value={IncoTerm.CFR}>{IncoTerm.CFR}</MenuItem>
              <MenuItem value={IncoTerm.CIF}>{IncoTerm.CIF}</MenuItem>
              <MenuItem value={IncoTerm.CIP}>{IncoTerm.CIP}</MenuItem>
              <MenuItem value={IncoTerm.CPT}>{IncoTerm.CPT}</MenuItem>
              <MenuItem value={IncoTerm.DAP}>{IncoTerm.DAP}</MenuItem>
              <MenuItem value={IncoTerm.DDP}>{IncoTerm.DDP}</MenuItem>
              <MenuItem value={IncoTerm.DPU}>{IncoTerm.DPU}</MenuItem>
              <MenuItem value={IncoTerm.EXW}>{IncoTerm.EXW}</MenuItem>
              <MenuItem value={IncoTerm.FAS}>{IncoTerm.FAS}</MenuItem>
              <MenuItem value={IncoTerm.FCA}>{IncoTerm.FCA}</MenuItem>
              <MenuItem value={IncoTerm.FOB}>{IncoTerm.FOB}</MenuItem>
            </Select>
            {formError.incoterm && (
              <Typography
                sx={{
                  color: "#d32f2f",
                  fontSize: "0.75rem",
                  fontWeight: 400,
                  marginTop: "7px",
                }}
              >
                Por favor ingrese una opción válida
              </Typography>
            )}
          </FormControl>

          {!isCustomsCargo(currentCargo.type) && (
            <TextField
              value={currentCargo.agentReference}
              margin="normal"
              fullWidth
              name="agentReference"
              label="Referencia agente"
              type="text"
              id="agentReference"
              onChange={(e) => handleOnChange("agentReference", e.target.value)}
            />
          )}
        </Box>

        {isAerealCargo(currentCargo.type) && currentAereoCargo ? (
          <AereoCargoForm
            currentCargo={currentCargo}
            formError={formError}
            handleOnChange={handleOnChange}
            isUpdate={!!isUpdate}
            canUpdate={canUpdate}
            handleAereoCargoOnChange={handleAereoCargoOnChange}
            selectedAgent={selectedAgent}
            selectedClient={selectedClient}
            setSelectedAgent={setSelectedAgent}
            setSelectedClient={setSelectedClient}
            currentAereoCargo={currentAereoCargo}
          />
        ) : isMaritimeCargo(currentCargo.type) && currentMaritimeCargo ? (
          <MaritimeCargoForm
            currentCargo={currentCargo}
            formError={formError}
            handleOnChange={handleOnChange}
            isUpdate={!!isUpdate}
            canUpdate={canUpdate}
            handleMaritimeCargoOnChange={handleMaritimeCargoOnChange}
            maritimeFormError={maritimeFormError}
            currentMaritimeCargo={currentMaritimeCargo}
            selectedAgent={selectedAgent}
            selectedClient={selectedClient}
            setSelectedAgent={setSelectedAgent}
            setSelectedClient={setSelectedClient}
          />
        ) : isGroundCargo(currentCargo.type) && currentGroundCargo ? (
          <GroundCargoForm
            currentCargo={currentCargo}
            formError={formError}
            handleOnChange={handleOnChange}
            isUpdate={!!isUpdate}
            canUpdate={canUpdate}
            currentGroundCargo={currentGroundCargo}
            handleGroundCargoOnChange={handleGroundCargoOnChange}
            selectedAgent={selectedAgent}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            setSelectedAgent={setSelectedAgent}
          />
        ) : (
          currentCustomsCargo && (
            <CustomCargoForm
              currentCargo={currentCargo}
              formError={formError}
              handleOnChange={handleOnChange}
              isUpdate={!!isUpdate}
              canUpdate={canUpdate}
              handleCustomsCargoOnChange={handleCustomsCargoOnChange}
              currentCustomsCargo={currentCustomsCargo}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
            />
          )
        )}

        <Box sx={{ display: "flex" }}>
          <Button
            type="button"
            onClick={() => onCancel(false)}
            sx={{ flex: 1, mt: 3, mb: 2, marginRight: 2 }}
          >
            {canUpdate ? "Cancelar" : "Atras"}
          </Button>
          {canUpdate && (
            <LoadingButton
              sx={{ flex: 1 }}
              loading={status === StatusType.loading}
              text={isUpdate ? "Modificar carga" : "Crear carga"}
              type="submit"
            />
          )}
        </Box>
      </LocalizationProvider>
    </Box>
  );
}
