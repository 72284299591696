import { FC, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { MblType } from "../../../../app/constants";
import { CargoMaritimeType, NewCargoBodyType } from "../../cargosAPI";
import ClientSelector from "../../../clients/ClientSelector";
import AgentSelector from "../../../agents/AgentSelector";
import { ClientType } from "../../../clients/clientsAPI";
import { AgentType } from "../../../agents/agentsAPI";
import { RoutesForm } from "./components/RoutesForm";
import Containers from "./components/Containers";
import { CommodityForm } from "./components/CommodityForm";
import { PortForm } from "./components/PortForm";
import Finance from "../shared/Finance/Finance";
import { DownloadCargoPdfButtons } from "../../DownloadView/DownloadCargoPdfButtons";
import { CargoFollow } from "../shared/CargoFollowForm/CargoFollow";
import { CargoRating } from "../shared/CargoRating";
import { CargoStatusForm } from "../shared/CargoStatusForm";

interface MaritimeCargoFormProps {
  currentCargo: NewCargoBodyType;
  handleOnChange: (label: string, value: string | any | null) => void;
  maritimeFormError: {
    etd: boolean;
    eta: boolean;
    terminal: boolean;
    booking: boolean;
    mbl: boolean;
    hbl: boolean;
    connections: boolean;
    containers: boolean;
  };
  canUpdate?: boolean;
  formError: {
    type: boolean;
    incoterm: boolean;
    client: boolean;
    agent: boolean;
    departureCity: boolean;
    destinationCity: boolean;
    originCity: boolean;
    shipper: boolean;
    finance: boolean;
  };
  isUpdate: boolean;
  handleMaritimeCargoOnChange: (
    label: string,
    value: string | any | null,
  ) => void;
  currentMaritimeCargo: CargoMaritimeType;
  selectedAgent: AgentType | null;
  setSelectedAgent: (agent: AgentType) => void;
  selectedClient: ClientType | null;
  setSelectedClient: (client: ClientType) => void;
}

const MaritimeCargoForm2: FC<MaritimeCargoFormProps> = ({
  currentCargo,
  handleOnChange,
  maritimeFormError,
  canUpdate,
  formError,
  isUpdate,
  handleMaritimeCargoOnChange,
  currentMaritimeCargo,
  selectedAgent,
  setSelectedAgent,
  selectedClient,
  setSelectedClient,
}) => {
  const [openSelectClient, setOpenSelectClient] = useState(false);

  const [openSelectAgent, setOpenSelectAgent] = useState(false);

  const toggleSelectClientModal = () => {
    setOpenSelectClient(!openSelectClient);
  };

  const toggleSelectAgentModal = () => {
    setOpenSelectAgent(!openSelectAgent);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <TextField
          value={currentCargo.shipper}
          margin="normal"
          fullWidth
          name="shipper"
          label="Shipper"
          required
          type="text"
          id="shipper"
          disabled={!canUpdate}
          onChange={(e) => handleOnChange("shipper", e.target.value)}
          error={formError.shipper}
          helperText={formError.shipper ? "Por favor ingrese un shipper" : ""}
        />

        <TextField
          value={currentCargo.consignee}
          margin="normal"
          fullWidth
          name="consignee"
          label="Consignatario"
          type="text"
          disabled={!canUpdate}
          id="consignee"
          onChange={(e) => handleOnChange("consignee", e.target.value)}
        />
        <TextField
          value={currentCargo.responsible}
          margin="normal"
          fullWidth
          disabled={!canUpdate}
          name="responsible"
          label="Carrier"
          type="text"
          id="responsible"
          onChange={(e) => handleOnChange("responsible", e.target.value)}
        />
        <TextField
          value={currentMaritimeCargo.vessel}
          margin="normal"
          id="vessel"
          label="Buque"
          name="name"
          type="vessel"
          onChange={(e) =>
            handleMaritimeCargoOnChange("vessel", e.target.value)
          }
          sx={{
            width: "100%",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <Box flex={1}>
          <ClientSelector
            open={openSelectClient}
            toggleModal={toggleSelectClientModal}
            selectedClient={selectedClient}
            onSelectExistentClient={setSelectedClient}
            error={formError.client}
            canUpdate={canUpdate}
          />
        </Box>
        <Box flex={1}>
          <AgentSelector
            open={openSelectAgent}
            toggleModal={toggleSelectAgentModal}
            selectedAgent={selectedAgent}
            onSelectExistentAgent={setSelectedAgent}
            error={formError.agent}
            canUpdate={canUpdate}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <CargoStatusForm
          canUpdate={canUpdate}
          currentStatus={currentMaritimeCargo.status}
          handleStatusChange={handleMaritimeCargoOnChange}
        />

        <TextField
          value={currentMaritimeCargo.booking}
          margin="normal"
          fullWidth
          name="booking"
          label="Número de booking"
          type="text"
          id="booking"
          disabled={!canUpdate}
          onChange={(e) =>
            handleMaritimeCargoOnChange("booking", e.target.value)
          }
        />

        <TextField
          value={currentMaritimeCargo.hbl}
          margin="normal"
          fullWidth
          name="hbl"
          label="Número de HBL"
          type="text"
          id="hbl"
          disabled={!canUpdate}
          onChange={(e) => handleMaritimeCargoOnChange("hbl", e.target.value)}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <FormControl fullWidth margin="normal">
          <InputLabel id="type-label">Tipo de MBL</InputLabel>
          <Select
            labelId="mbl-type"
            id="mbl-type"
            value={currentMaritimeCargo.mbl.type}
            label="Tipo de MBL"
            disabled={!canUpdate}
            onChange={(e) =>
              handleMaritimeCargoOnChange &&
              handleMaritimeCargoOnChange(
                "mbl",
                e.target.value
                  ? {
                      type: e.target.value,
                      number: currentMaritimeCargo.mbl.number,
                    }
                  : currentMaritimeCargo.mbl,
              )
            }
          >
            <MenuItem value={MblType.original}>Original</MenuItem>
            <MenuItem value={MblType.express}>Express release</MenuItem>
          </Select>
        </FormControl>
        <TextField
          value={currentMaritimeCargo.mbl.number}
          margin="normal"
          fullWidth
          name="hbl"
          label="Número de MBL"
          type="text"
          id="mbl"
          disabled={!canUpdate}
          onChange={(e) =>
            handleMaritimeCargoOnChange &&
            handleMaritimeCargoOnChange(
              "mbl",
              e.target.value
                ? {
                    type: currentMaritimeCargo.mbl.type,
                    number: e.target.value,
                  }
                : currentMaritimeCargo.mbl,
            )
          }
        />

        <Box sx={{ width: "100%", marginTop: "16px" }}>
          <DatePicker
            label="ETD"
            value={currentMaritimeCargo.etd}
            disabled={!canUpdate}
            onChange={(value) =>
              handleMaritimeCargoOnChange &&
              handleMaritimeCargoOnChange("etd", value)
            }
            sx={{ width: "100%" }}
          />
          {maritimeFormError.etd && !currentMaritimeCargo.etd && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontSize: "0.75rem",
                fontWeight: 400,
                marginTop: "7px",
              }}
            >
              Por favor seleccione una fecha
            </Typography>
          )}
        </Box>
        <Box sx={{ width: "100%", marginTop: "16px" }}>
          <DatePicker
            label="ETA"
            value={currentMaritimeCargo.eta}
            disabled={!canUpdate}
            onChange={(value) =>
              handleMaritimeCargoOnChange &&
              handleMaritimeCargoOnChange("eta", value)
            }
            sx={{ width: "100%" }}
          />
          {maritimeFormError.eta && !currentMaritimeCargo.eta && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontSize: "0.75rem",
                fontWeight: 400,
                marginTop: "7px",
              }}
            >
              Por favor seleccione una fecha
            </Typography>
          )}
        </Box>
      </Box>

      <RoutesForm
        currentCargo={currentCargo}
        formError={formError}
        handleOnChange={handleOnChange}
        maritimeFormError={maritimeFormError}
        canUpdate={canUpdate}
        handleMaritimeCargoOnChange={handleMaritimeCargoOnChange}
        maritimeCargo={currentMaritimeCargo}
      />

      <Containers
        containers={currentMaritimeCargo.currentContainers || []}
        setContainers={(containers) =>
          handleMaritimeCargoOnChange &&
          handleMaritimeCargoOnChange("currentContainers", containers)
        }
        containerError={maritimeFormError.containers}
        canUpdate={canUpdate}
      />

      <CommodityForm
        currentCargo={currentCargo}
        handleOnChange={handleOnChange}
        canUpdate={canUpdate}
      />

      <PortForm
        currentMaritimeCargo={currentMaritimeCargo!}
        handleOnChange={handleMaritimeCargoOnChange}
        maritimeFormError={maritimeFormError}
        canUpdate={canUpdate}
      />

      <Finance
        finance={currentCargo.currentFinance || []}
        setFinance={(finance) =>
          handleOnChange && handleOnChange("currentFinance", finance)
        }
        financeError={formError.finance}
        canUpdate={canUpdate}
      />

      {isUpdate && (
        <DownloadCargoPdfButtons
          currentCargo={currentCargo}
          cargoClient={selectedClient ?? undefined}
          cargoAgent={selectedAgent ?? undefined}
        />
      )}

      <CargoFollow
        follows={currentCargo.follows}
        handleOnChange={handleOnChange}
      />

      <CargoRating
        handleOnChange={handleMaritimeCargoOnChange}
        canUpdate={canUpdate}
        currentCargo={currentCargo}
      />
    </Box>
  );
};

export default MaritimeCargoForm2;
