import {
  Box,
  Button,
  Collapse,
  IconButton,
  List,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import formValidations from "../../utils/formValidations";
import { ExtendedContactType } from "./agentsAPI";
import { TransitionGroup } from "react-transition-group";
import { Delete } from "@mui/icons-material";
import { MEDIA_QUERY_MOBILE } from "../../utils/constants";

const emptyContact = { email: "", phone: "", id: 0 };

const Contact = ({
  contact,
  showErrors,
  handleOnChange,
  onDelete,
  readOnly,
  isMobile,
}: {
  contact: ExtendedContactType;
  showErrors?: boolean;
  readOnly?: boolean;
  handleOnChange: (label: string, value: string, id: number) => void;
  onDelete: () => void;
  isMobile: boolean;
}) => {
  const errorEmail =
    showErrors && !formValidations.validateEmail(contact.email);
  const errorPhone =
    showErrors && !formValidations.validatePhone(contact.phone);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <TextField
        value={contact.email}
        margin="normal"
        required
        fullWidth
        id="email"
        label="Correo electrónico"
        name="name"
        autoFocus
        type="email"
        onChange={(e) => handleOnChange("email", e.target.value, contact.id)}
        error={errorEmail}
        helperText={errorEmail ? "Por favor ingrese un correo válido" : ""}
        sx={{ marginRight: 1 }}
        InputProps={{
          readOnly: readOnly,
        }}
      />
      <TextField
        value={contact.phone}
        margin="normal"
        required
        fullWidth
        id="phone"
        label="Teléfono"
        name="phone"
        type="text"
        onChange={(e) => handleOnChange("phone", e.target.value, contact.id)}
        error={errorPhone}
        helperText={errorPhone ? "Por favor ingrese un teléfono válido" : ""}
        sx={{ marginRight: 1 }}
        InputProps={{
          readOnly: readOnly,
        }}
      />
      {!readOnly && (
        <IconButton aria-label="delete" onClick={onDelete}>
          <Delete />
        </IconButton>
      )}
    </Box>
  );
};

const Contacts = ({
  showErrors,
  contacts,
  setContacts,
}: {
  showErrors?: boolean;
  contacts: ExtendedContactType[];
  setContacts: (contacts: ExtendedContactType[]) => void;
}) => {
  const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE);
  const [currentId, setId] = useState(contacts.length + 1 || 2);

  const handleOnChange = (label: string, value: string, id: number) => {
    if (contacts)
      setContacts(
        contacts?.map((contact) =>
          contact.id === id ? { ...contact, [label]: value } : contact,
        ),
      );
  };

  const addContact = () => {
    setContacts([...contacts, { ...emptyContact, id: currentId }]);
    setId(currentId + 1);
  };

  const onDelete = (id: number) =>
    setContacts(contacts.filter((contact) => contact.id !== id));

  return (
    <Paper elevation={1} sx={{ padding: 3, borderRadius: 5, margin: "20px 0" }}>
      <Typography variant="h6">Contactos</Typography>
      <List>
        <TransitionGroup>
          {contacts.map((contact) => (
            <Collapse key={contact.id || contact.email}>
              <Contact
                contact={contact}
                showErrors={showErrors}
                handleOnChange={handleOnChange}
                onDelete={() => onDelete(contact.id!)}
                isMobile={isMobile}
              />
            </Collapse>
          ))}
          <Button onClick={addContact} fullWidth variant="outlined">
            Agregar contacto
          </Button>
        </TransitionGroup>
      </List>
    </Paper>
  );
};

export default Contacts;
