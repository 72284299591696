import { Dispatch, SetStateAction, useState } from "react";
import formValidations from "../../utils/formValidations";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import { AGENTS } from "../../routes";
import { Alert, Container, Dialog, Snackbar, Typography } from "@mui/material";
import { ExtendedContactType, NewAgentBodyType } from "./agentsAPI";
import { selectUpdateAgentsStatus, updateAgent } from "./agentsSlice";
import AgentForm from "./AgentForm";

export default function UpdateAgent({
  open,
  onClose,
  setCurrentAgent,
  currentAgent,
}: {
  open: boolean;
  onClose: (update?: boolean) => void;
  setCurrentAgent: Dispatch<SetStateAction<NewAgentBodyType>>;
  currentAgent: NewAgentBodyType;
}) {
  const navigate = useNavigate();
  const status = useAppSelector(selectUpdateAgentsStatus);

  const defaultError = "Ha ocurrido un error. Por favor, intente nuevamente.";

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorSnackbarReason, setErrorSnackbarReason] = useState(defaultError);

  const [formError, setFormError] = useState({
    name: false,
    contacts: false,
    country: false,
  });

  const dispatch = useAppDispatch();

  const handleOnChange = (
    label: string,
    value: string | string[] | ExtendedContactType[],
  ) => {
    setCurrentAgent((data) => ({ ...data, [label]: value }));
    setFormError((errors) => ({ ...errors, [label]: false }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const agent: NewAgentBodyType = {
      ...currentAgent,
      contacts: currentAgent.currentContacts,
      ranking: undefined,
      totalRankings: undefined,
    };

    const validAgentName =
      agent.name.length && formValidations.validateName(agent.name);

    const validContacts =
      agent.contacts?.every(
        (contact) =>
          formValidations.validatePhone(contact.phone) &&
          formValidations.validateEmail(contact.email),
      ) ?? true;

    const validAgentCountry =
      agent.country.length && formValidations.validateName(agent.country);

    if (!validAgentName) {
      setFormError((errors) => ({ ...errors, name: true }));
    }

    if (!validContacts) {
      setFormError((errors) => ({ ...errors, contacts: true }));
    }

    if (!validAgentCountry) {
      setFormError((errors) => ({ ...errors, country: true }));
    }

    if (validAgentName && validContacts && validAgentCountry) {
      dispatch(updateAgent(agent)).then((value) => {
        if (value.meta.requestStatus === "fulfilled") {
          navigate(AGENTS);
          onClose(true);
          setOpenSnackbar(true);
        } else if (value.meta.requestStatus === "rejected") {
          setErrorSnackbarReason(defaultError);
          setOpenErrorSnackbar(true);
        }
      });
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleCloseErrorSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorSnackbar(false);
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Agente modificado
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseErrorSnackbar}
      >
        <Alert
          onClose={handleCloseErrorSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorSnackbarReason}
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={() => onClose(false)}>
        <Container
          component="main"
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginTop: 4,
            marginBottom: 4,
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Modificar agente
          </Typography>
          <AgentForm
            status={status}
            currentAgent={currentAgent}
            formError={formError}
            handleOnChange={handleOnChange}
            handleSubmit={handleSubmit}
            isUpdate
            onCancel={onClose}
          />
        </Container>
      </Dialog>
    </>
  );
}
