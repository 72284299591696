import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  header: {
    margin: 0,
    padding: 15,
    height: 100,
    width: "100%",
    backgroundColor: "white",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    height: 40,
    marginBottom: 20,
  },
  logoText: {
    fontSize: 12,
    fontWeight: 900,
    letterSpacing: 0.09,
    color: "#b02b13",
    textTransform: "uppercase",
  },
});

export default styles;
