import { CurrencyCode, ExtendedCargoFinanceType } from "../../../../cargosAPI";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { FinanceTypeEnum } from "../../../../../../app/constants";
import { Delete } from "@mui/icons-material";
import { ConceptForm } from "./ConceptForm";
import { FinanceConcept } from "../../../../constants/cargoContants";

const conceptsWithIVA: string[] = [
  FinanceConcept.AlmacenajeGravado,
  FinanceConcept.SeguroGravado,
  FinanceConcept.EmisionBl,
  FinanceConcept.VentaDeContenedorNacionalizado,
  FinanceConcept.GastosTerminalesGravados,
  FinanceConcept.FleteAereoImportacionGravado,
  FinanceConcept.FleteTerrestreNacional,
  FinanceConcept.DesconsolidacionElectronica,
  FinanceConcept.HandlingGravado,
  FinanceConcept.ComisionGravada,
  FinanceConcept.HonorariosGravados,
  FinanceConcept.GastosDeDespacho,
  FinanceConcept.AsesoramientoLogistoGravado,
  FinanceConcept.GastosAdministrativos,
  FinanceConcept.LogisticsFee,
  FinanceConcept.DespachoDeTransito,
  FinanceConcept.AperturaCntrGravado,
  FinanceConcept.EntregaDeMercaderia,
  FinanceConcept.AdValorem,
];

export const FinanceRow = ({
  row,
  handleOnChange,
  onDelete,
  readOnly,
  isMobile,
  error,
  type,
}: {
  row: ExtendedCargoFinanceType;
  readOnly?: boolean;
  handleOnChange: (
    label: string,
    value: string | boolean,
    id: number,
    type: FinanceTypeEnum,
  ) => void;
  onDelete: () => void;
  isMobile: boolean;
  error: boolean;
  type: FinanceTypeEnum;
}) => {
  const handleChange = (label: string, value: string | boolean, id: number) => {
    handleOnChange(label, value, id, type);
  };
  return (
    <Box
      sx={{
        marginBottom: isMobile ? "unset" : "20px",
      }}
    >
      <Box sx={{ display: "flex", gap: 2 }}>
        <Box sx={{ width: "100%" }}>
          <ConceptForm handleOnChange={handleChange} row={row} type={type} />
          {error && !row.concept && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontSize: "0.75rem",
                fontWeight: 400,
              }}
            >
              Por favor ingrese un concepto
            </Typography>
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <FormControlLabel
            label="IVA incluido"
            sx={{
              marginTop: isMobile ? "unset" : "25px",
              alignSelf: isMobile ? "center" : "unset",
            }}
            control={
              <Checkbox
                readOnly
                defaultChecked={conceptsWithIVA.includes(row.concept)}
                checked={row.hasIVA}
                onChange={(e) =>
                  handleChange("hasIVA", e.target.checked, row.id)
                }
              />
            }
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          flexDirection: isMobile ? "column" : "row",
          gap: "5px",
        }}
      >
        <FormControl fullWidth margin="normal">
          <InputLabel id="type-label">Moneda</InputLabel>
          <Select
            labelId="commodity_currency"
            id="commodity_currency"
            value={row.currency ?? CurrencyCode.USD}
            label="Moneda"
            onChange={(e) => handleChange("currency", e.target.value, row.id)}
          >
            {Object.values(CurrencyCode).map((currency) => (
              <MenuItem value={currency}>{currency}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <TextField
            value={row.amount}
            margin="normal"
            required
            id="amount"
            label="Monto"
            name="amount"
            type="number"
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
            onChange={(e) => handleChange("amount", e.target.value, row.id)}
            InputProps={{
              readOnly: readOnly,
            }}
          />
          {error && !row.amount && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontSize: "0.75rem",
                fontWeight: 400,
              }}
            >
              Por favor ingrese un monto
            </Typography>
          )}
        </Box>

        {!readOnly && (
          <IconButton
            aria-label="delete"
            onClick={onDelete}
            sx={{
              marginTop: isMobile ? "unset" : "25px",
              alignSelf: isMobile ? "center" : "unset",
            }}
          >
            <Delete />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
