import { ThemeProvider } from "@emotion/react";
import "./App.css";
import SignIn from "./features/signIn/SignIn";
import { createTheme, ThemeOptions } from "@mui/material/styles";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./errorPage";
import Root from "./features/root/Root";
import Users from "./features/users/Users";
import Cities from "./features/cities/Cities";
import Profile from "./features/profile/Profile";
import ClientsList from "./features/clients/ClientsList";
import CreateClient from "./features/clients/CreateClient";
import UpdateClientPage from "./features/clients/UpdateClientPage";
import Dashboard from "./features/dashboard/Dashboard";
import Agents from "./features/agents/Agents";
import Cargos from "./features/cargos/Cargos";
import CreateCargo from "./features/cargos/CreateCargo";
import {
  AGENTS,
  CARGOS,
  CARGO_VIEW,
  CITIES,
  CLIENTS,
  CLIENT_VIEW,
  CREATE_CARGO,
  CREATE_CLIENT,
  HOME_PAGE,
  PROFILE,
  SIGN_IN,
  USERS,
} from "./routes";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import UpdateCargo from "./features/cargos/UpdateCargo";

const router = createBrowserRouter([
  {
    path: HOME_PAGE,
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: HOME_PAGE,
        element: <Dashboard />,
      },
      {
        path: USERS,
        element: <Users />,
      },
      {
        path: CITIES,
        element: <Cities />,
      },
      {
        path: PROFILE,
        element: <Profile />,
      },
      {
        path: CREATE_CLIENT,
        element: <CreateClient />,
      },
      {
        path: CLIENT_VIEW,
        element: <UpdateClientPage />,
      },
      {
        path: CLIENTS,
        element: <ClientsList />,
      },
      {
        path: AGENTS,
        element: <Agents />,
      },
      {
        path: CARGOS,
        element: <Cargos />,
      },
      {
        path: CREATE_CARGO,
        element: <CreateCargo />,
      },
      {
        path: CARGO_VIEW,
        element: <UpdateCargo />,
      },
    ],
  },
  {
    path: SIGN_IN,
    element: <SignIn />,
  },
]);

const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#0961a9",
    },
    secondary: {
      main: "#af1f1e",
    },
  },
};

const theme = createTheme(themeOptions);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
