class Storage {
  setItem(key, value) {
    try {
      if (!value) return null;
      const jsonValue = JSON.stringify(value);
      localStorage.setItem(key, jsonValue);
      return this;
    } catch (error) {
      return null;
    }
  }

  getItem(key) {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      return null;
    }
  }

  getAuthToken() {
    try {
      return localStorage.getItem("authToken");
    } catch (error) {
      return null;
    }
  }

  getUserRole() {
    try {
      return localStorage.getItem("userRoles");
    } catch (error) {
      return null;
    }
  }

  removeItem(key) {
    try {
      localStorage.removeItem(key);
    } catch (error) {}
  }

  clear() {
    try {
      localStorage.clear();
    } catch (error) {}
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Storage();
