import { Autocomplete, Box, Paper, TextField, Typography } from "@mui/material";

import { NewCargoBodyType } from "../../../cargosAPI";
import { CityType } from "../../../../cities/citiesAPI";
import { useAppSelector } from "../../../../../app/hooks";
import { selectCities } from "../../../../cities/citiesSlice";

export default function RoutesForm({
  currentCargo,
  handleOnChange,
  formError,
  canUpdate,
}: {
  currentCargo: NewCargoBodyType;
  handleOnChange: (label: string, value: string | any | null) => void;
  formError: {
    departureCity: boolean;
    destinationCity: boolean;
    originCity: boolean;
  };
  canUpdate?: boolean;
}) {
  const cities = useAppSelector(selectCities);

  return (
    <Paper elevation={1} sx={{ padding: 3, borderRadius: 5, margin: "20px 0" }}>
      <Typography variant="h6">Ruta</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          marginTop: 2,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Autocomplete
            disablePortal
            id="departure-city"
            options={cities}
            disabled={!canUpdate}
            getOptionLabel={(city) => `${city.cityName} (${city.code})`}
            onChange={(_e, val) =>
              handleOnChange("cities", {
                ...currentCargo.cities,
                departure: val?._id,
              })
            }
            isOptionEqualToValue={(option, value) => option._id === value._id}
            defaultValue={currentCargo.cities.departure as CityType}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label={"Aeropuerto de carga*"}
              />
            )}
          />
          {formError.departureCity && !currentCargo.cities?.departure && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontSize: "0.75rem",
                fontWeight: 400,
                marginTop: "7px",
              }}
            >
              Por favor ingrese una ciudad
            </Typography>
          )}
        </Box>
        <Box sx={{ width: "100%" }}>
          <Autocomplete
            disablePortal
            id="destination-city"
            options={cities}
            getOptionLabel={(city) => `${city.cityName} (${city.code})`}
            disabled={!canUpdate}
            onChange={(_e, val) =>
              handleOnChange("cities", {
                ...currentCargo.cities,
                destination: val?._id,
              })
            }
            isOptionEqualToValue={(option, value) => option._id === value._id}
            defaultValue={currentCargo.cities.destination as CityType}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="Aeropuerto de destino*"
              />
            )}
          />
          {formError.destinationCity && !currentCargo.cities?.destination && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontSize: "0.75rem",
                fontWeight: 400,
                marginTop: "7px",
              }}
            >
              Por favor ingrese una ciudad
            </Typography>
          )}
        </Box>
      </Box>
    </Paper>
  );
}
