import { Box } from "@mui/material";

import { NewCargoBodyType } from "../../../../cargosAPI";
import { CityType } from "../../../../../cities/citiesAPI";
import { TextTitle } from "../../shared/TextTitle";
import { TextSubtitle } from "../../shared/TextSubtitle";

export const Route = ({ currentCargo }: { currentCargo: NewCargoBodyType }) => {
  const departureCity = currentCargo.cities?.departure as CityType;
  const destinationCity = currentCargo.cities?.destination as CityType;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        gap: 3,
      }}
    >
      <Box>
        <TextTitle label="Punto de salida" />
        <TextSubtitle
          label={`${departureCity?.cityName} (${departureCity?.code})`}
        />
      </Box>
      <Box>
        <TextTitle label="Aduana de cruce" />
        <TextSubtitle label={currentCargo.cities?.crossingCustoms} />
      </Box>
      <Box>
        <TextTitle label="Lugar de descarga" />
        <TextSubtitle
          label={`${destinationCity?.cityName} (${destinationCity?.code})`}
        />
      </Box>
    </Box>
  );
};
