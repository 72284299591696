import { CargoType, NewCargoBodyType } from "../cargosAPI";
import { AvisoDesconsolidacion } from "./templates/AvisoDesconsolidacion/AvisoDesconsolidacion";
import { ConfirmacionBooking } from "./templates/ConfirmacionBooking/ConfirmacionBooking";
import { CommonTemplate } from "./templates/CommonTemplate/CommonTemplate";
import { AvisoLlegadaMaritima } from "./templates/AvisoLlegadaImport/AvisoLlegadaMaritima";
import { getCommonRowValues } from "./templates/CommonTemplate/utils/getCommonRowValues";
import {
  getCommonTitle,
  getPdfTitle,
} from "./templates/CommonTemplate/utils/getCommonTitle";
import { ConfirmacionSalidaMaritima } from "./templates/AvisoSalidaMaritima/AvisoSalida";
import { AvisoLlegadaAereo } from "./templates/AvisoLlegadaImport/AvisoLlegadaAereo";
import { AvisoCruce } from "./templates/AvisoCruce/AvisoCruce";
import { getCargoRef } from "../../../utils/cargo";
import { Caratulas } from "./templates/Caratulas";
import { ClientType } from "../../clients/clientsAPI";
import { AgentType } from "../../agents/agentsAPI";

export enum DownloadPdfType {
  LlegadaFcl,
  LlegadaLcl,
  LlegadaAerea,
  Desconsolidacion,
  ReservaMaritimaFcl,
  ReservaMaritimaLcl,
  ReservaAerea,
  SalidaMaritimaFcl,
  SalidaMaritimaLcl,
  SalidaAerea,
  CruceTerrestre,
  Caratula,
}

interface InvokePdfProps {
  cargo: NewCargoBodyType;
  downloadType: DownloadPdfType;
  cargoClient?: ClientType;
  cargoAgent?: AgentType;
}

const InvoicePdf = ({
  cargo,
  downloadType,
  cargoClient,
  cargoAgent,
}: InvokePdfProps) => {
  return (
    <>
      {downloadType === DownloadPdfType.Desconsolidacion ? (
        <AvisoDesconsolidacion cargo={cargo} />
      ) : downloadType === DownloadPdfType.LlegadaFcl ||
        downloadType === DownloadPdfType.LlegadaLcl ? (
        <AvisoLlegadaMaritima
          cargo={cargo}
          full={downloadType === DownloadPdfType.LlegadaFcl}
        />
      ) : downloadType === DownloadPdfType.LlegadaAerea ? (
        <AvisoLlegadaAereo cargo={cargo} />
      ) : downloadType === DownloadPdfType.ReservaMaritimaFcl ||
        downloadType === DownloadPdfType.ReservaMaritimaLcl ? (
        <ConfirmacionBooking
          cargo={cargo}
          full={downloadType === DownloadPdfType.ReservaMaritimaFcl}
        />
      ) : downloadType === DownloadPdfType.SalidaMaritimaFcl ||
        downloadType === DownloadPdfType.SalidaMaritimaLcl ? (
        <ConfirmacionSalidaMaritima
          cargo={cargo}
          full={downloadType === DownloadPdfType.SalidaMaritimaFcl}
        />
      ) : downloadType === DownloadPdfType.CruceTerrestre ? (
        <AvisoCruce cargo={cargo} />
      ) : downloadType === DownloadPdfType.Caratula ? (
        <Caratulas
          cargo={cargo}
          cargoClient={cargoClient}
          cargoAgent={cargoAgent}
        />
      ) : (
        <CommonTemplate
          title={getCommonTitle(downloadType)}
          cargoRef={cargo.cargoRef ?? getCargoRef(cargo as CargoType)}
          rowValues={getCommonRowValues(cargo, downloadType)}
          pdfTitle={`${getPdfTitle(downloadType)} ${cargo.type}-${
            cargo.number
          }`}
        />
      )}
    </>
  );
};

export default InvoicePdf;
