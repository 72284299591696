import { Box, Paper, Rating, Typography } from "@mui/material";

import { NewCargoBodyType } from "../../cargosAPI";

export const CargoRating = ({
  currentCargo,
  handleOnChange,
  canUpdate,
}: {
  currentCargo: NewCargoBodyType;
  handleOnChange: (label: string, value: string | any | null) => void;

  canUpdate?: boolean;
}) => {
  return (
    <Paper elevation={1} sx={{ padding: 3, borderRadius: 5, margin: "20px 0" }}>
      <Typography variant="h6">Calidad de la carga</Typography>
      <Typography variant="body2">
        Este puntaje es utilizado para el rating de los agentes del exterior
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <Rating
          sx={{ marginTop: "10px", fontSize: "35px" }}
          readOnly={!canUpdate}
          value={currentCargo.ranking}
          precision={0.5}
          onChange={(_e, val) =>
            handleOnChange && handleOnChange("ranking", val)
          }
        />
      </Box>
    </Paper>
  );
};
