import { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import AddIcon from "@mui/icons-material/Add";

import { CargoFollowType } from "../../../../cargosAPI";

const initialData: CargoFollowType = {
  user: { _id: "", email: "", name: "", roles: [] },
  date: DateTime.now(),
  comment: "",
};

export const CargoFollowForm = ({
  cargoFollow,
  isMobile,
  submit,
}: {
  cargoFollow?: CargoFollowType;
  isMobile: boolean;
  submit: (formValues: CargoFollowType) => boolean;
}) => {
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState<CargoFollowType>(
    cargoFollow ?? initialData,
  );

  const handleSubmit = () => {
    if (!formData?.comment || !formData?.date) return setError(true);

    const success = submit(formData);
    if (!success) return setError(true);

    setFormData(initialData);
    setError(false);
  };

  return (
    <Box
      sx={{
        marginTop: isMobile ? "unset" : "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          flexDirection: isMobile ? "column" : "row",
          gap: "5px",
        }}
      >
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <TextField
            value={formData.comment}
            margin="normal"
            required
            id="comment"
            label="Comentario"
            name="comment"
            onChange={(e) =>
              setFormData((current) => ({
                ...current,
                comment: e.target.value,
              }))
            }
          />
          {error && !formData.comment && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontSize: "0.75rem",
                fontWeight: 400,
              }}
            >
              Por favor ingrese un comentario
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DatePicker
            label="Fecha *"
            value={formData.date}
            sx={{ marginTop: "16px", marginBottom: "8px" }}
            onChange={(value) =>
              setFormData((current) => ({ ...current, date: value || "" }))
            }
          />
          {error && !formData.date && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontSize: "0.75rem",
                fontWeight: 400,
                marginTop: "7px",
              }}
            >
              Por favor seleccione una fecha
            </Typography>
          )}
        </Box>
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
        <Button
          variant="contained"
          color="primary"
          aria-label="add"
          sx={{ paddingLeft: 2, paddingRight: 2 }}
          onClick={handleSubmit}
        >
          <AddIcon />
          <span>Agregar comentario</span>
        </Button>
      </Box>
    </Box>
  );
};
