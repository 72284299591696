import { Box, Button, CircularProgress } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";

import InvoicePdf, { DownloadPdfType } from "./PdfView";
import { CargoTypeEnum } from "../../../app/constants";
import { CargoType, NewCargoBodyType } from "../cargosAPI";
import { getCargoRef } from "../../../utils/cargo";
import { ClientType } from "../../clients/clientsAPI";
import { AgentType } from "../../agents/agentsAPI";

export const DownloadCargoPdfButtons = ({
  currentCargo,
  cargoClient,
  cargoAgent,
}: {
  currentCargo: NewCargoBodyType;
  cargoClient?: ClientType;
  cargoAgent?: AgentType;
}) => {
  return (
    <Box>
      <PDFDownloadLink
        document={
          <InvoicePdf
            cargo={currentCargo}
            cargoClient={cargoClient}
            cargoAgent={cargoAgent}
            downloadType={DownloadPdfType.Caratula}
          />
        }
        fileName={`Caratula_${
          currentCargo.cargoRef ?? getCargoRef(currentCargo as CargoType)
        }.pdf`}
      >
        {({ loading }) =>
          loading ? <CircularProgress /> : <Button>Caratula</Button>
        }
      </PDFDownloadLink>

      {currentCargo?.type === CargoTypeEnum.EM ||
      currentCargo?.type === CargoTypeEnum.IM ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <PDFDownloadLink
              document={
                <InvoicePdf
                  cargo={currentCargo}
                  downloadType={DownloadPdfType.ReservaMaritimaFcl}
                />
              }
              fileName={`reserva_maritima_fcl_${
                currentCargo.cargoRef ?? getCargoRef(currentCargo as CargoType)
              }.pdf`}
            >
              {({ loading }) =>
                loading ? (
                  <CircularProgress />
                ) : (
                  <Button>Aviso de Reserva FCL</Button>
                )
              }
            </PDFDownloadLink>
            <PDFDownloadLink
              document={
                <InvoicePdf
                  cargo={currentCargo}
                  downloadType={DownloadPdfType.SalidaMaritimaFcl}
                />
              }
              fileName={`aviso_salida_maritima_fcl_${
                currentCargo.cargoRef ?? getCargoRef(currentCargo as CargoType)
              }.pdf`}
            >
              {({ loading }) =>
                loading ? (
                  <CircularProgress />
                ) : (
                  <Button>Aviso Salida FCL</Button>
                )
              }
            </PDFDownloadLink>
            <PDFDownloadLink
              document={
                <InvoicePdf
                  cargo={currentCargo}
                  downloadType={DownloadPdfType.LlegadaFcl}
                />
              }
              fileName={`llegada_fcl_${
                currentCargo.cargoRef ?? getCargoRef(currentCargo as CargoType)
              }.pdf`}
            >
              {({ loading }) =>
                loading ? (
                  <CircularProgress />
                ) : (
                  <Button>Aviso Llegada FCL</Button>
                )
              }
            </PDFDownloadLink>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <PDFDownloadLink
              document={
                <InvoicePdf
                  cargo={currentCargo}
                  downloadType={DownloadPdfType.ReservaMaritimaLcl}
                />
              }
              fileName={`reserva_maritima_lcl_${
                currentCargo.cargoRef ?? getCargoRef(currentCargo as CargoType)
              }.pdf`}
            >
              {({ loading }) =>
                loading ? (
                  <CircularProgress />
                ) : (
                  <Button>Aviso de Reserva LCL</Button>
                )
              }
            </PDFDownloadLink>

            <PDFDownloadLink
              document={
                <InvoicePdf
                  cargo={currentCargo}
                  downloadType={DownloadPdfType.SalidaMaritimaLcl}
                />
              }
              fileName={`aviso_salida_maritima_lcl_${
                currentCargo.cargoRef ?? getCargoRef(currentCargo as CargoType)
              }.pdf`}
            >
              {({ loading }) =>
                loading ? (
                  <CircularProgress />
                ) : (
                  <Button>Aviso Salida LCL</Button>
                )
              }
            </PDFDownloadLink>

            <PDFDownloadLink
              document={
                <InvoicePdf
                  cargo={currentCargo}
                  downloadType={DownloadPdfType.LlegadaLcl}
                />
              }
              fileName={`llegada_lcl_${
                currentCargo.cargoRef ?? getCargoRef(currentCargo as CargoType)
              }.pdf`}
            >
              {({ loading }) =>
                loading ? (
                  <CircularProgress />
                ) : (
                  <Button>Aviso Llegada LCL</Button>
                )
              }
            </PDFDownloadLink>
          </Box>

          <PDFDownloadLink
            document={
              <InvoicePdf
                cargo={currentCargo}
                downloadType={DownloadPdfType.Desconsolidacion}
              />
            }
            fileName={`desconsolidacion_${
              currentCargo.cargoRef ?? getCargoRef(currentCargo as CargoType)
            }.pdf`}
          >
            {({ loading }) =>
              loading ? (
                <CircularProgress />
              ) : (
                <Button>Aviso Desconsolidación</Button>
              )
            }
          </PDFDownloadLink>
        </Box>
      ) : currentCargo?.type === CargoTypeEnum.EA ||
        currentCargo?.type === CargoTypeEnum.IA ? (
        <>
          <PDFDownloadLink
            document={
              <InvoicePdf
                cargo={currentCargo}
                downloadType={DownloadPdfType.ReservaAerea}
              />
            }
            fileName={`reserva_aerea_${
              currentCargo.cargoRef ?? getCargoRef(currentCargo as CargoType)
            }.pdf`}
          >
            {({ loading }) =>
              loading ? <CircularProgress /> : <Button>Aviso Reserva</Button>
            }
          </PDFDownloadLink>

          <PDFDownloadLink
            document={
              <InvoicePdf
                cargo={currentCargo}
                downloadType={DownloadPdfType.SalidaAerea}
              />
            }
            fileName={`aviso_salida_aerea_${
              currentCargo.cargoRef ?? getCargoRef(currentCargo as CargoType)
            }.pdf`}
          >
            {({ loading }) =>
              loading ? <CircularProgress /> : <Button>Aviso Salida</Button>
            }
          </PDFDownloadLink>

          <PDFDownloadLink
            document={
              <InvoicePdf
                cargo={currentCargo}
                downloadType={DownloadPdfType.LlegadaAerea}
              />
            }
            fileName={`aviso_llegada_aerea_${
              currentCargo.cargoRef ?? getCargoRef(currentCargo as CargoType)
            }.pdf`}
          >
            {({ loading }) =>
              loading ? <CircularProgress /> : <Button>Aviso Llegada</Button>
            }
          </PDFDownloadLink>
        </>
      ) : (
        (currentCargo?.type === CargoTypeEnum.ET ||
          currentCargo?.type === CargoTypeEnum.IT) && (
          <>
            <PDFDownloadLink
              document={
                <InvoicePdf
                  cargo={currentCargo}
                  downloadType={DownloadPdfType.CruceTerrestre}
                />
              }
              fileName={`aviso_cruce_terrestre_${
                currentCargo.cargoRef ?? getCargoRef(currentCargo as CargoType)
              }.pdf`}
            >
              {({ loading }) =>
                loading ? <CircularProgress /> : <Button>Aviso Cruce</Button>
              }
            </PDFDownloadLink>
          </>
        )
      )}
    </Box>
  );
};
