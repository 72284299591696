import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import signInAPI from "../features/signIn/signInAPI";
import signInReducer, { getProfile } from "../features/signIn/signInSlice";
import usersReducer from "../features/users/usersSlice";
import citiesReducer from "../features/cities/citiesSlice";
import clientsReducer from "../features/clients/clientsSlice";
import agentsReducer from "../features/agents/agentsSlice";
import clientBrokersReducer from "../features/clientBrokers/clientBrokersSlice";
import cargosReducer from "../features/cargos/cargosSlice";
import dashboardReducer from "../features/dashboard/dashboardSlice";
import ls from "../utils/storage";

export const store = configureStore({
  reducer: {
    signIn: signInReducer,
    users: usersReducer,
    cities: citiesReducer,
    clients: clientsReducer,
    clientBrokers: clientBrokersReducer,
    agents: agentsReducer,
    cargos: cargosReducer,
    dashboard: dashboardReducer,
  },
});

if (ls.getAuthToken()) {
  signInAPI.setAuthToken(ls.getAuthToken()?.replaceAll('"', "") || "");
  store.dispatch(getProfile());
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
