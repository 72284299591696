import API from "../../app/apiConfig";
import { transformObjectToQueryString } from "../../utils/transformObjectToQueryString";
import { ClientBrokerType } from "../clientBrokers/clientBrokersAPI";
import { UserType } from "../users/usersAPI";
import { ClientStatus } from "./constants";

const base = "/clients";

export type GetClientsFilters = {
  search?: string;
  page?: number;
  pageSize?: number;
  sellers?: string[];
  status?: string;
};

export type ClientType = {
  name: string;
  address: string;
  taxId: string;
  notes: string;
  contacts: ContactType[];
  clientBroker: ClientBrokerType;
  sellers: string[] | UserType[];
  _id: string;
  statuses: {
    status: ClientStatus;
    createdAt: string;
    createdBy: string;
  }[];
  createdBy: UserType;
};

export type ClientBodyType = {
  _id?: string;
  name: string;
  address: string;
  taxId: string;
  notes?: string;
  contacts?: ContactType[];
  currentContacts?: ExtendedContactType[];
  clientBroker?: string;
  selectedClientBroker?: ClientBrokerType;
  sellers?: string[];
  selectedSellers?: UserType[];
  status?: ClientStatus;
  createdBy?: UserType;
  statuses?: {
    status: ClientStatus;
    createdAt: string;
    createdBy: string;
  }[];
  createdAt?: string;
};

export type ContactType = {
  name: string;
  phone: string;
  email: string;
};

interface CollectAllDataResponse {
  docs: [
    {
      name: string;
      address: string;
      taxId: string;
      notes: string;
      contacts: ContactType[];
      clientBroker: ClientBrokerType;
      sellers: [
        {
          createdAt: string;
          email: string;
          name: string;
          roles: string[];
          updatedAt: string;
          _id: string;
        },
      ];
      _id: string;
      statuses: {
        status: ClientStatus;
        createdAt: string;
        createdBy: string;
      }[];
      createdBy: UserType;
    },
  ];
}

export type ExtendedContactType = ContactType & { id: number };

function getClients(filters: GetClientsFilters) {
  let { status, ...filterValues } = filters;
  if (filters.status && filters.status !== "all") {
    filterValues = filters;
  }

  const filterString = transformObjectToQueryString(filterValues);

  return API.get(`${base}?${filterString}`);
}

function createClient(body: ClientBodyType) {
  return API.post(base, body);
}

function updateClient(body: ClientBodyType) {
  return API.put(`${base}/${body._id}`, body);
}

function getClientById(id: string) {
  return API.get(`${base}/${id}`);
}

function getAllClientData(filters: GetClientsFilters) {
  let { status, ...filterValues } = filters;
  if (filters.status && filters.status !== "all") {
    filterValues = filters;
  }

  const filterString = transformObjectToQueryString(filterValues);

  return API.get<CollectAllDataResponse>(`${base}/export?${filterString}`);
}

const clientsAPI = {
  getClientById,
  getClients,
  createClient,
  updateClient,
  getAllClientData,
};
export default clientsAPI;
