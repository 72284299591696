import { Box, Paper, TextField, Typography } from "@mui/material";

import { NewCargoBodyType } from "../../../cargosAPI";

export const CommodityForm = ({
  currentCargo,
  handleOnChange,
  canUpdate,
}: {
  currentCargo: NewCargoBodyType;
  handleOnChange: (label: string, value: string | any | null) => void;

  canUpdate?: boolean;
}) => {
  return (
    <Paper elevation={1} sx={{ padding: 3, borderRadius: 5, margin: "20px 0" }}>
      <Typography variant="h6">Commodity</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <TextField
          value={currentCargo.commodity?.description ?? ""}
          margin="normal"
          fullWidth
          name="commodity"
          disabled={!canUpdate}
          label="Descripción mercadería"
          type="text"
          id="commodity"
          onChange={(e) =>
            handleOnChange("commodity", {
              ...currentCargo.commodity,
              description: e.target.value,
            })
          }
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <TextField
          value={currentCargo.commodity?.quantities?.package}
          margin="normal"
          fullWidth
          name="lumps"
          label={"Bultos"}
          disabled={!canUpdate}
          type="number"
          id="lumps"
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          onChange={(e) =>
            handleOnChange("commodity", {
              ...currentCargo.commodity,
              quantities: {
                ...currentCargo?.commodity?.quantities,
                package: e.target.value,
              },
            })
          }
        />
        <TextField
          value={currentCargo.commodity?.quantities?.weight}
          margin="normal"
          fullWidth
          name="weight"
          disabled={!canUpdate}
          label="Peso"
          type="number"
          id="weight"
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          onChange={(e) =>
            handleOnChange("commodity", {
              ...currentCargo.commodity,
              quantities: {
                ...currentCargo?.commodity?.quantities,
                weight: e.target.value,
              },
            })
          }
        />

        <TextField
          value={currentCargo.commodity?.quantities?.height}
          margin="normal"
          fullWidth
          name="height"
          label={"Volumen"}
          type="number"
          disabled={!canUpdate}
          id="height"
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          onChange={(e) =>
            handleOnChange("commodity", {
              ...currentCargo.commodity,
              quantities: {
                ...currentCargo?.commodity?.quantities,
                height: e.target.value,
              },
            })
          }
        />
      </Box>
    </Paper>
  );
};
