import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatusType } from "../../app/constants";
import { RootState } from "../../app/store";
import agentsAPI, {
  AgentType,
  GetAgentsFilters,
  NewAgentBodyType,
} from "./agentsAPI";

export interface AgentsState {
  getAgentsStatus: StatusType;
  agents: AgentType[];
  totalAgents: number;

  createAgentStatus: StatusType;
  updateAgentStatus: StatusType;
}

const initialState: AgentsState = {
  getAgentsStatus: StatusType.idle,
  agents: [],
  totalAgents: 0,

  createAgentStatus: StatusType.idle,
  updateAgentStatus: StatusType.idle,
};

export const getAgents = createAsyncThunk(
  "agents/getAgents",
  async (filters: GetAgentsFilters) => {
    const response = await agentsAPI.getAgents(filters);
    return response.data;
  },
);

export const getAgentById = createAsyncThunk(
  "agents/getAgentById",
  async (id: string) => {
    const response = await agentsAPI.getAgentById(id);
    return response.data;
  },
);

export const createAgent = createAsyncThunk(
  "agents/createAgent",
  async (body: NewAgentBodyType, { rejectWithValue }) => {
    try {
      const response = await agentsAPI.createAgent(body);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.message);
    }
  },
);

export const updateAgent = createAsyncThunk(
  "agents/updateAgent",
  async (body: NewAgentBodyType, { rejectWithValue }) => {
    try {
      const response = await agentsAPI.updateAgent(body);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.message);
    }
  },
);

export const agentsSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAgents.pending, (state) => {
        state.getAgentsStatus = StatusType.loading;
      })
      .addCase(getAgents.fulfilled, (state, action) => {
        state.getAgentsStatus = StatusType.succeeded;
        state.totalAgents = action.payload.totalDocs;
        state.agents = action.payload.docs.map((doc: AgentType) => ({
          ...doc,
          id: doc._id,
        }));
      })
      .addCase(getAgents.rejected, (state) => {
        state.getAgentsStatus = StatusType.failed;
      })

      .addCase(createAgent.pending, (state) => {
        state.createAgentStatus = StatusType.loading;
      })
      .addCase(createAgent.fulfilled, (state) => {
        state.createAgentStatus = StatusType.succeeded;
      })
      .addCase(createAgent.rejected, (state) => {
        state.createAgentStatus = StatusType.failed;
      })

      .addCase(updateAgent.pending, (state) => {
        state.updateAgentStatus = StatusType.loading;
      })
      .addCase(updateAgent.fulfilled, (state) => {
        state.updateAgentStatus = StatusType.succeeded;
      })
      .addCase(updateAgent.rejected, (state) => {
        state.updateAgentStatus = StatusType.failed;
      });
  },
});

export const selectAgentsStatus = (state: RootState) =>
  state.agents.getAgentsStatus;
export const selectTotalAgents = (state: RootState) => state.agents.totalAgents;
export const selectAgents = (state: RootState) => state.agents.agents;

export const selectCreateAgentsStatus = (state: RootState) =>
  state.agents.createAgentStatus;
export const selectUpdateAgentsStatus = (state: RootState) =>
  state.agents.updateAgentStatus;

export default agentsSlice.reducer;
