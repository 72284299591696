import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CargoMaritimeType } from "../../../cargosAPI";
import { CargoMaritimeTerminal } from "../../../../../app/constants";

export function PortForm({
  currentMaritimeCargo,
  handleOnChange,
  maritimeFormError,
  canUpdate,
}: {
  currentMaritimeCargo: CargoMaritimeType;
  handleOnChange: (label: string, value: string | any | null) => void;
  maritimeFormError: {
    terminal: boolean;
  };
  canUpdate?: boolean;
}) {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <FormControl fullWidth margin="normal">
          <InputLabel id="terminal-label">Terminal portuaria</InputLabel>
          <Select
            labelId="terminal"
            id="terminal"
            value={currentMaritimeCargo.terminal ?? ""}
            label="Terminal portuaria"
            required
            disabled={!canUpdate}
            onChange={(e) =>
              handleOnChange &&
              handleOnChange("terminal", e.target.value ? e.target.value : null)
            }
          >
            <MenuItem value={CargoMaritimeTerminal.Montecon}>
              {CargoMaritimeTerminal.Montecon}
            </MenuItem>
            <MenuItem value={CargoMaritimeTerminal.TCP}>
              {CargoMaritimeTerminal.TCP}
            </MenuItem>
          </Select>
          {maritimeFormError.terminal && !currentMaritimeCargo.terminal && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontSize: "0.75rem",
                fontWeight: 400,
                marginTop: "7px",
              }}
            >
              Por favor seleccione una terminal
            </Typography>
          )}
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="returningTerminal-label">
            Terminal de devolución
          </InputLabel>
          <Select
            labelId="returningTerminal"
            id="returningTerminal"
            value={currentMaritimeCargo.returningTerminal ?? ""}
            label="Terminal de devolución"
            disabled={!canUpdate}
            onChange={(e) =>
              handleOnChange &&
              handleOnChange(
                "returningTerminal",
                e.target.value ? e.target.value : null,
              )
            }
          >
            <MenuItem value={CargoMaritimeTerminal.Montecon}>
              {CargoMaritimeTerminal.Montecon}
            </MenuItem>
            <MenuItem value={CargoMaritimeTerminal.TCP}>
              {CargoMaritimeTerminal.TCP}
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          value={currentMaritimeCargo.freeDelayDays}
          margin="normal"
          fullWidth
          name="freeDelayDays"
          label="Dias libres de demora"
          disabled={!canUpdate}
          type="number"
          id="freeDelayDays"
          onKeyDown={(e) =>
            ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
          }
          onChange={(e) => handleOnChange("freeDelayDays", e.target.value)}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <TextField
          value={currentMaritimeCargo.deconsolidationDeposit}
          margin="normal"
          fullWidth
          name="deconsolidationDeposit"
          label="Depósito de desconsolidación"
          type="text"
          id="deconsolidationDeposit"
          disabled={!canUpdate}
          onChange={(e) =>
            handleOnChange("deconsolidationDeposit", e.target.value)
          }
        />
        <TextField
          value={currentMaritimeCargo.stockNumber}
          margin="normal"
          fullWidth
          name="stockNumber"
          label="Nro de Stock"
          type="text"
          id="stockNumber"
          disabled={!canUpdate}
          onChange={(e) => handleOnChange("stockNumber", e.target.value)}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="stockReference-label">Acta</InputLabel>
          <Select
            labelId="stockReference"
            id="stockReference"
            value={currentMaritimeCargo.stockReference ?? undefined}
            label="Acta"
            disabled={!canUpdate}
            onChange={(e) =>
              handleOnChange && handleOnChange("stockReference", e.target.value)
            }
          >
            <MenuItem value={undefined}>Sin especificar</MenuItem>
            <MenuItem value={"true"}>Si</MenuItem>
            <MenuItem value={"false"}>No</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <FormControlLabel
        label="Seguro por tailored logistics"
        control={
          <Checkbox
            checked={currentMaritimeCargo.insurance}
            disabled={!canUpdate}
            onChange={(e) => handleOnChange("insurance", e.target.checked)}
          />
        }
      />
    </Box>
  );
}
