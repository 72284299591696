import {
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { TransitionGroup } from "react-transition-group";

import { MEDIA_QUERY_MOBILE } from "../../../../../utils/constants";
import { CurrencyCode, ExtendedCargoFinanceType } from "../../../cargosAPI";
import { FinanceRow } from "./Components/FinanceRow";
import { FinanceTypeEnum } from "../../../../../app/constants";

const emptyFinance = {
  type: undefined,
  amount: undefined,
  hasIVA: false,
  id: 0,
  concept: "",
};

const Finance = ({
  finance,
  setFinance,
  financeError,
  canUpdate,
}: {
  finance: ExtendedCargoFinanceType[];
  setFinance: (finance: ExtendedCargoFinanceType[]) => void;
  financeError: boolean;
  canUpdate?: boolean;
}) => {
  const isMobile = useMediaQuery(MEDIA_QUERY_MOBILE);
  const [currentId, setId] = useState(finance.length + 1 || 2);

  const handleOnChange = (
    label: string,
    value: string | boolean,
    id: number,
  ) => {
    if (finance) {
      setFinance(
        finance?.map((financeRow) =>
          financeRow.id === id ? { ...financeRow, [label]: value } : financeRow,
        ),
      );
    }
  };

  const addFinance = (type: FinanceTypeEnum) => {
    setFinance([...finance, { ...emptyFinance, id: currentId, type }]);
    setId(currentId + 1);
  };

  const onDelete = (id: number) =>
    setFinance(finance.filter((financeRow) => financeRow.id !== id));

  return (
    <Paper elevation={1} sx={{ padding: 3, borderRadius: 5, margin: "20px 0" }}>
      <Typography variant="h6">Finanzas</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 4,
          mt: 2,
        }}
      >
        <List sx={{ flex: 1 }}>
          <Typography variant="h5" sx={{ fontSize: 20, mb: 1 }}>
            Ventas
          </Typography>

          <TransitionGroup>
            {finance
              .filter((x) => x.type === FinanceTypeEnum.sale)
              .map((row) => (
                <Collapse key={row.id || row.type}>
                  <FinanceRow
                    row={row}
                    handleOnChange={handleOnChange}
                    onDelete={() => onDelete(row.id!)}
                    isMobile={isMobile}
                    error={financeError}
                    type={FinanceTypeEnum.sale}
                  />
                </Collapse>
              ))}
            {canUpdate && (
              <Button
                onClick={() => addFinance(FinanceTypeEnum.sale)}
                fullWidth
                variant="outlined"
              >
                Agregar Finanza
              </Button>
            )}
          </TransitionGroup>
        </List>
        <List sx={{ flex: 1 }}>
          <Typography variant="h5" sx={{ fontSize: 20, mb: 1 }}>
            Costos
          </Typography>
          <TransitionGroup>
            {finance
              .filter((x) => x.type === FinanceTypeEnum.cost)
              .map((row) => (
                <Collapse key={row.id || row.type}>
                  <FinanceRow
                    row={row}
                    handleOnChange={handleOnChange}
                    onDelete={() => onDelete(row.id!)}
                    isMobile={isMobile}
                    error={financeError}
                    type={FinanceTypeEnum.cost}
                  />
                </Collapse>
              ))}
            {canUpdate && (
              <Button
                onClick={() => addFinance(FinanceTypeEnum.cost)}
                fullWidth
                variant="outlined"
              >
                Agregar Finanza
              </Button>
            )}
          </TransitionGroup>
        </List>
      </Box>

      <Box>
        <Typography variant="h5" sx={{ fontSize: 20, mb: 1 }}>
          Resumen de balance
        </Typography>

        <List>
          {Object.values(CurrencyCode).map((currency) => {
            const financeWithCurrency = finance.filter(
              (x) => (x.currency ?? CurrencyCode.USD) === currency,
            );
            if (financeWithCurrency.length <= 0) return null;
            return (
              <ListItem sx={{ display: "flex", gap: 4 }}>
                <Typography>{currency}</Typography>
                <Typography>
                  {financeWithCurrency
                    .filter((x) => x.type === FinanceTypeEnum.sale)
                    .reduce(
                      (accumulator, current) =>
                        accumulator + Number(current.amount ?? 0),
                      0,
                    ) -
                    financeWithCurrency
                      .filter((x) => x.type === FinanceTypeEnum.cost)
                      .reduce(
                        (accumulator, current) =>
                          accumulator + Number(current.amount ?? 0),
                        0,
                      )}
                </Typography>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Paper>
  );
};

export default Finance;
