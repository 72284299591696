import { useMemo } from "react";
import { Box, Paper, Typography } from "@mui/material";

import { CargoFollowType } from "../../../cargosAPI";
import { CargoFollowForm } from "./components/CargoFollowForm";
import { selectProfile } from "../../../../signIn/signInSlice";
import { useAppSelector } from "../../../../../app/hooks";
import { CargoFollowGrid } from "./components/CargoFollowGrid";

export const CargoFollow = ({
  follows,
  handleOnChange,
}: {
  follows?: CargoFollowType[];
  handleOnChange: (label: string, value: string | null | any) => void;
}) => {
  const currentFollow = useMemo(() => follows ?? [], [follows]);
  const profile = useAppSelector(selectProfile);

  const addFollow = (value: CargoFollowType) => {
    if (!profile) return false;
    handleOnChange("follows", [...currentFollow, { ...value, user: profile }]);
    return true;
  };

  return (
    <Box>
      <Paper
        elevation={1}
        sx={{ padding: 3, borderRadius: 5, margin: "20px 0" }}
      >
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Seguimiento
        </Typography>

        <CargoFollowGrid follows={currentFollow} />

        {currentFollow.length <= 0 && (
          <Typography textAlign="center" sx={{ marginTop: 2 }}>
            No hay comentarios por el momento
          </Typography>
        )}
        <CargoFollowForm isMobile={false} submit={addFollow} />
      </Paper>
    </Box>
  );
};
