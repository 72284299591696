import { FC, useMemo } from "react";
import { Page, View, Document, Text } from "@react-pdf/renderer";

import styles from "./styles";
import { CargoRefContainer, RowElement, TemplateHeader } from "../shared";
import { PdfInterface } from "../../interfaces/PdfInterface";
import { CityType } from "../../../../cities/citiesAPI";
import { DateTime } from "luxon";
import { TemplateFooter } from "../shared/Footer/TemplateFooter";
import { getCargoRef } from "../../../../../utils/cargo";
import { CargoType } from "../../../cargosAPI";

export const AvisoDesconsolidacion: FC<PdfInterface> = ({ cargo }) => {
  const rowValues = useMemo(() => {
    return [
      {
        leftColumn: {
          title: "Shipper / Embarcador",
          subtitle: cargo.shipper || "-",
        },
        rigthColumn: {
          title: "Consignee / Consignatario:",
          subtitle: cargo.consignee || "-",
        },
      },
      {
        leftColumn: {
          title: "Port Of Loading / Puerto de embarque",
          subtitle:
            `${(cargo.cities.departure as CityType).cityName}, ${
              (cargo.cities.departure as CityType).cityCountry
            }` || "-",
        },
        rigthColumn: {
          title: "ETD / Fecha estimada de salida",
          subtitle:
            (cargo.maritime?.etd as DateTime)?.toFormat("dd/MM/yyyy") || "-",
        },
      },

      {
        leftColumn: {
          title: "Port Of Discharge / Puerto de Descarga",
          subtitle:
            `${(cargo.cities.destination as CityType).cityName}, ${
              (cargo.cities.destination as CityType).cityCountry
            }` || "-",
        },
        rigthColumn: {
          title: "ETA / Fecha estimada de llegada",
          subtitle:
            (cargo.maritime?.eta as DateTime)?.toFormat("dd/MM/yyyy") || "-",
        },
      },
      {
        leftColumn: {
          title: "Ocean Vessel / Buque",
          subtitle: cargo.maritime?.vessel || "-",
        },
        rigthColumn: {
          title: "Carrier / Cargador",
          subtitle: cargo.responsible || "-",
        },
      },
      {
        leftColumn: {
          title: "MBL N°",
          subtitle: cargo.maritime?.mbl.number.toString() || "-",
        },
        rigthColumn: {
          title: "Tipo de MBL",
          subtitle: cargo.maritime?.mbl.type.toUpperCase() || "-",
        },
      },
    ];
  }, [
    cargo.cities.departure,
    cargo.cities.destination,
    cargo.consignee,
    cargo.maritime?.eta,
    cargo.maritime?.etd,
    cargo.maritime?.mbl,
    cargo.maritime?.vessel,
    cargo.responsible,
    cargo.shipper,
  ]);

  return (
    <Document
      title={`Aviso Desconsolidación ${cargo.type}-${cargo.number}`}
      author="Tailored Logistics"
      creator="Tailored Logistics"
      producer="Tailored Logistics"
    >
      <Page size="A4" style={styles.page}>
        <View>
          <TemplateHeader title="Aviso Desconsolidacion" />
          <View style={styles.content}>
            <CargoRefContainer
              cargoRef={cargo.cargoRef ?? getCargoRef(cargo as CargoType)}
            />
          </View>
          <View style={styles.section2}>
            <View style={styles.table}>
              {rowValues.map((values) => (
                <RowElement values={values} />
              ))}
              <View style={styles.tableRow}>
                <View style={{ flex: 1 }}>
                  <View style={styles.rowElement}>
                    <Text style={styles.rowElementTitle}>Bultos</Text>
                    <Text style={styles.rowElementSubtitle}>
                      {cargo.commodity?.quantities?.package ?? "-"}
                    </Text>
                  </View>
                </View>
                <View style={{ flex: 1 }}>
                  <View style={styles.rowElement}>
                    <Text style={styles.rowElementTitle}>Kg</Text>
                    <Text style={styles.rowElementSubtitle}>
                      {cargo.commodity?.quantities?.weight ?? "-"}
                    </Text>
                  </View>
                </View>
                <View style={{ flex: 1 }}>
                  <View style={styles.rowElement}>
                    <Text style={styles.rowElementTitle}>CBM</Text>
                    <Text style={styles.rowElementSubtitle}>
                      {cargo.commodity?.quantities?.height ?? "-"}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={{ flex: 1 }}>
                  <View style={styles.rowElement}>
                    <Text style={styles.rowElementTitle}>
                      Depósito de desconsolidación
                    </Text>
                    <Text style={styles.rowElementSubtitle}>
                      {cargo.maritime?.deconsolidationDeposit ?? "-"}
                    </Text>
                  </View>
                </View>
                <View style={{ flex: 1 }}>
                  <View style={styles.rowElement}>
                    <Text style={styles.rowElementTitle}>Número de stock</Text>
                    <Text style={styles.rowElementSubtitle}>
                      {cargo.maritime?.stockNumber ?? "-"}
                    </Text>
                  </View>
                </View>
                <View style={{ flex: 1 }}>
                  <View style={styles.rowElement}>
                    <Text style={styles.rowElementTitle}>Acta</Text>
                    <Text style={styles.rowElementSubtitle}>
                      {cargo.maritime?.stockReference === "false"
                        ? "NO"
                        : cargo.maritime?.stockReference === "true"
                        ? "SI"
                        : "-"}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <TemplateFooter />
      </Page>
    </Document>
  );
};
