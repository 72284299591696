import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    color: "black",
    padding: 5,
    display: "flex",
    gap: 5,
    fontFamily: "Open Sans",
  },

  container: {
    border: "1px solid black",
    padding: 5,
  },

  fillingText: {
    fontSize: 8,
    border: "1px solid #CAC8C8",
    padding: 2,
    fontWeight: 600,
    marginTop: 2,
  },

  subtitle: {
    fontSize: 8,
    fontWeight: 300,
  },

  title: {
    fontSize: 10,
    fontWeight: 800,
    textTransform: "uppercase",
  },
});

export default styles;
