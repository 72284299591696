import { useMemo, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { ClientBodyType, ExtendedContactType } from "./clientsAPI";
import { StatusType } from "../../app/constants";
import LoadingButton from "../shared/LoadingButton";
import Contacts from "./Contacts";
import { ClientStatus, getStatusName } from "./constants";
import ClientBrokerSelector from "../clientBrokers/ClientBrokerSelector";
import { ClientBrokerType } from "../clientBrokers/clientBrokersAPI";
import SellerSelector from "../users/SellerSelector";
import { UserType } from "../users/usersAPI";
import { useAppSelector } from "../../app/hooks";
import { selectProfile } from "../signIn/signInSlice";
import { Role } from "../users/constants";

export default function ClientForm({
  currentClient,
  handleSubmit,
  handleOnChange,
  formError,
  status,
  isUpdate,
  onCancel,
  showErrors,
}: {
  status: StatusType;
  currentClient: ClientBodyType;
  handleSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  handleOnChange?: (
    label: string,
    value:
      | string
      | string[]
      | ClientBrokerType
      | ExtendedContactType[]
      | UserType[],
  ) => void;
  formError?: {
    name: boolean;
    taxId: boolean;
    address: boolean;
    status: boolean;
  };
  showErrors?: boolean;
  isUpdate?: boolean;
  onCancel?: () => void;
}) {
  const [openClientBroker, setOpenClientBroker] = useState(false);
  const [openSellersSelector, setOpenSellersSelector] = useState(false);

  const profile = useAppSelector(selectProfile);
  const isAdmin = useMemo(
    () =>
      (profile?.roles?.includes(Role.admin) ||
        profile?.roles?.includes(Role.customerService)) ??
      false,
    [profile],
  );

  const initialClientStatusIsPotential =
    currentClient.status === ClientStatus.potential;

  const canUpdate = useMemo(
    () =>
      !isUpdate ||
      isAdmin ||
      initialClientStatusIsPotential ||
      (profile &&
        (currentClient?.sellers?.includes(profile._id) ||
          currentClient?.sellers?.find(
            (seller: any) => seller?._id === profile._id,
          ) !== undefined)),
    [isUpdate, isAdmin, profile, currentClient, initialClientStatusIsPotential],
  );

  const isSeller = useMemo(
    () =>
      profile &&
      (currentClient?.sellers?.includes(profile._id) ||
        currentClient?.sellers?.find(
          (seller: any) => seller?._id === profile._id,
        ) !== undefined),
    [profile, currentClient],
  );

  const toggleClientBrokerSelector = () => {
    setOpenClientBroker(!openClientBroker);
  };

  const onSelectExistentBroker = (broker: ClientBrokerType) => {
    if (handleOnChange) handleOnChange("selectedClientBroker", broker);
  };

  const toggleSellersSelector = () => {
    setOpenSellersSelector(!openSellersSelector);
  };

  const onSelectExistentSellers = (sellers: UserType[]) => {
    if (handleOnChange) handleOnChange("selectedSellers", sellers);
  };

  const activeClientDate = currentClient.statuses
    ?.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    )
    ?.find((s) => s.status === ClientStatus.active)?.createdAt;
  const inactiveClientDate = currentClient.statuses
    ?.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    )
    ?.find((s) => s.status === ClientStatus.inactive)?.createdAt;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{
        mt: 1,
        alignSelf: "center",
        width: { xs: "90%", sm: "100%", md: "60%" },
      }}
    >
      <TextField
        value={currentClient.name}
        margin="normal"
        required
        fullWidth
        id="name"
        label="Nombre"
        name="name"
        autoFocus
        disabled={!canUpdate}
        type="text"
        onChange={(e) =>
          handleOnChange && handleOnChange("name", e.target.value)
        }
        error={formError?.name}
        helperText={formError?.name ? "Por favor ingrese un nombre válido" : ""}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          columnGap: "10px",
        }}
      >
        <FormControl fullWidth margin="normal">
          <InputLabel id="status-label">Estado</InputLabel>
          <Select
            labelId="status-label"
            id="status"
            value={currentClient.status}
            disabled={
              !canUpdate ||
              (!isAdmin &&
                !isSeller &&
                !isUpdate &&
                initialClientStatusIsPotential)
            }
            label="Estado"
            onChange={(e) =>
              handleOnChange && handleOnChange("status", e.target.value)
            }
          >
            <MenuItem value={ClientStatus.potential}>
              {getStatusName(ClientStatus.potential)}
            </MenuItem>
            <MenuItem value={ClientStatus.active}>
              {getStatusName(ClientStatus.active)}
            </MenuItem>
            <MenuItem value={ClientStatus.inactive}>
              {getStatusName(ClientStatus.inactive)}
            </MenuItem>
          </Select>
          {formError?.status && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontSize: "0.75rem",
                fontWeight: 400,
                marginTop: "7px",
              }}
            >
              Solo los usuarios administradores pueden{" "}
              {isUpdate ? "dar de alta clientes" : "crear clientes activos"}
            </Typography>
          )}
        </FormControl>
        <TextField
          value={currentClient.address}
          margin="normal"
          fullWidth
          id="address"
          label="Dirección"
          name="address"
          type="text"
          disabled={!canUpdate}
          onChange={(e) =>
            handleOnChange && handleOnChange("address", e.target.value)
          }
          error={formError?.address}
          helperText={
            formError?.address ? "Por favor ingrese una dirección válida" : ""
          }
        />

        <TextField
          value={currentClient.taxId}
          margin="normal"
          fullWidth
          id="taxId"
          label="RUT"
          name="taxId"
          type="text"
          disabled={!canUpdate}
          onChange={(e) =>
            handleOnChange && handleOnChange("taxId", e.target.value)
          }
          error={formError?.taxId}
          helperText={formError?.taxId ? "Por favor ingrese un RUT válido" : ""}
        />
      </Box>

      <TextField
        value={currentClient.notes}
        margin="normal"
        fullWidth
        id="notes"
        label="Notas"
        name="notes"
        multiline
        rows={3}
        disabled={!canUpdate}
        type="text"
        onChange={(e) =>
          handleOnChange && handleOnChange("notes", e.target.value)
        }
      />

      <Contacts
        showErrors={showErrors}
        contacts={currentClient.currentContacts || []}
        canUpdate={canUpdate}
        setContacts={(contacts) =>
          handleOnChange && handleOnChange("currentContacts", contacts)
        }
      />

      <ClientBrokerSelector
        toggleModal={toggleClientBrokerSelector}
        open={openClientBroker}
        canUpdate={canUpdate}
        selectedBroker={currentClient.selectedClientBroker!}
        onSelectExistentBroker={onSelectExistentBroker}
      />

      {(isAdmin ||
        (currentClient?.selectedSellers &&
          currentClient.selectedSellers.length > 0)) && (
        <SellerSelector
          onSelectExistentSellers={onSelectExistentSellers}
          open={openSellersSelector}
          canUpdate={isAdmin}
          selectedSellers={currentClient.selectedSellers || []}
          toggleModal={toggleSellersSelector}
        />
      )}

      {isUpdate && currentClient.createdBy && (
        <Typography variant="subtitle1" component="h4" gutterBottom>
          Cliente creado por: <b>{currentClient.createdBy.email}</b>
        </Typography>
      )}
      {isUpdate && currentClient.createdAt && (
        <Typography variant="subtitle1" component="h4" gutterBottom>
          Cliente creado el{" "}
          <b>{new Date(currentClient.createdAt).toLocaleDateString()}</b>
        </Typography>
      )}
      {isUpdate &&
        currentClient.status === ClientStatus.active &&
        activeClientDate && (
          <Typography variant="subtitle1" component="h4" gutterBottom>
            Cliente dado de alta el:{" "}
            <b>{new Date(activeClientDate).toLocaleDateString()}</b>
          </Typography>
        )}
      {isUpdate &&
        currentClient.status === ClientStatus.inactive &&
        inactiveClientDate && (
          <Typography variant="subtitle1" component="h4" gutterBottom>
            Cliente dado de baja el:{" "}
            <b>{new Date(inactiveClientDate).toLocaleDateString()}</b>
          </Typography>
        )}

      <Box sx={{ display: "flex" }}>
        {isUpdate && (
          <Button
            type="button"
            onClick={onCancel}
            sx={{ flex: 1, mt: 3, mb: 2, marginRight: 2 }}
          >
            {canUpdate ? "Cancelar" : "Atras"}
          </Button>
        )}
        {canUpdate && (
          <LoadingButton
            sx={{ flex: 1 }}
            loading={status === StatusType.loading}
            text={isUpdate ? "Modificar cliente" : "Crear cliente"}
            type="submit"
          />
        )}
      </Box>
    </Box>
  );
}
