import { FC, useMemo } from "react";
import { Page, View, Document, Text } from "@react-pdf/renderer";
import { DateTime } from "luxon";

import styles from "./styles";
import { CargoRefContainer, RowElement, TemplateHeader } from "../shared";
import { PdfInterface } from "../../interfaces/PdfInterface";
import { FinanceTypeEnum } from "../../../../../app/constants";
import { CityType } from "../../../../cities/citiesAPI";
import { TemplateFooter } from "../shared/Footer/TemplateFooter";
import { getCargoRef } from "../../../../../utils/cargo";
import { CargoType, CurrencyCode } from "../../../cargosAPI";

export const AvisoLlegadaAereo: FC<PdfInterface> = ({ cargo, full }) => {
  const rowValues = useMemo(() => {
    return [
      {
        leftColumn: {
          title: "Shipper / Embarcador",
          subtitle: cargo.shipper || "-",
        },
        rigthColumn: {
          title: "Consignee / Consignatario:",
          subtitle: cargo.consignee || "-",
        },
      },
      {
        leftColumn: {
          title: "Airport  Of Loading / Aeropuerto de embarque:",
          subtitle:
            `${(cargo.cities.departure as CityType).cityName}, ${
              (cargo.cities.departure as CityType).cityCountry
            }` || "-",
        },
        rigthColumn: {
          title: "ETD / Fecha estimada de salida",
          subtitle:
            (cargo.aereal?.etd as DateTime)?.toFormat("dd/MM/yyyy") || "-",
        },
      },

      {
        leftColumn: {
          title: "Airport  Of Loading / Aeropuerto de embarque:",
          subtitle:
            `${(cargo.cities.destination as CityType).cityName}, ${
              (cargo.cities.destination as CityType).cityCountry
            }` || "-",
        },
        rigthColumn: {
          title: "ETA / Fecha estimada de llegada",
          subtitle:
            (cargo.aereal?.eta as DateTime)?.toFormat("dd/MM/yyyy") || "-",
        },
      },
      {
        leftColumn: {
          title: "Airline / Linea Aérea:",
          subtitle: cargo.responsible || "-",
        },
        rigthColumn: {
          title: "Airline / Linea Aérea:",
          subtitle: cargo.aereal?.flightNumber || "-",
        },
      },
      {
        leftColumn: {
          title: "Chargeable weight / Peso taxable",
          subtitle: cargo.commodity?.quantities?.weight?.toString() || "-",
        },
        rigthColumn: {
          title: "Gross weight / Peso bruto",
          subtitle: cargo.commodity?.quantities?.grossWeight?.toString() || "-",
        },
      },
      {
        leftColumn: {
          title: "MAWB",
          subtitle: cargo.aereal?.mawb || "-",
        },
        rigthColumn: {
          title: "HAWB",
          subtitle: cargo.aereal?.hawb || "-",
        },
      },
    ];
  }, [
    cargo.shipper,
    cargo.consignee,
    cargo.cities.departure,
    cargo.cities.destination,
    cargo.aereal?.etd,
    cargo.aereal?.eta,
    cargo.aereal?.flightNumber,
    cargo.aereal?.mawb,
    cargo.aereal?.hawb,
    cargo.responsible,
    cargo.commodity?.quantities?.weight,
    cargo.commodity?.quantities?.grossWeight,
  ]);

  const expenses = useMemo(() => {
    return cargo.finance
      ?.filter((x) => x.type === FinanceTypeEnum.sale)
      .map((value) => {
        return {
          name: value.concept ?? "-",
          amount: value.amount ?? 0,
          currency: value.currency ?? CurrencyCode.USD,
        };
      });
  }, [cargo.finance]);

  const expensesQuantity = useMemo(() => {
    const currencyQuantity = Object.values(CurrencyCode).reduce(
      (accumulator, current) =>
        accumulator +
        (expenses?.some((x) => (x.currency ?? CurrencyCode.USD) === current)
          ? 1
          : 0),
      0,
    );

    const numberOfPosibleFinanceLines = 5;

    return numberOfPosibleFinanceLines - currencyQuantity;
  }, [expenses]);

  return (
    <Document
      title={`Aviso Llegada Aerea ${cargo.type}-${cargo.number}`}
      author="Tailored Logistics"
      creator="Tailored Logistics"
      producer="Tailored Logistics"
    >
      <Page size="A4" style={styles.page} wrap={false}>
        <View>
          <TemplateHeader title="Aviso llegada" />
          <View style={styles.content}>
            <CargoRefContainer
              cargoRef={cargo.cargoRef ?? getCargoRef(cargo as CargoType)}
            />
          </View>
          <View style={styles.section2}>
            <View style={styles.table}>
              {rowValues.map((values) => (
                <RowElement values={values} />
              ))}
            </View>
          </View>

          <View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 10,
              }}
            >
              <Text style={{ fontSize: 10, color: "black" }}>
                DETALLE DE COSTOS
              </Text>
            </View>
            <View style={{ border: "1px solid black" }}>
              {expenses?.slice(0, expensesQuantity + 1).map((value) => (
                <View style={[styles.tableRow, { border: "0px", margin: 5 }]}>
                  <View style={{ width: "50%" }}>
                    <Text style={{ fontSize: 8, color: "black" }}>
                      {value?.name}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "50%",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        color: "black",
                        textAlign: "right",
                        width: 50,
                      }}
                    >
                      {value.currency}
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        color: "black",
                        textAlign: "right",
                      }}
                    >
                      {value?.amount?.toFixed(2)}
                    </Text>
                  </View>
                </View>
              ))}

              {expenses && expenses.length <= expensesQuantity && (
                <>
                  {Object.values(CurrencyCode).map((currencyCode) => {
                    const exprensesByCurrency = expenses.filter(
                      (x) => x.currency === currencyCode,
                    );

                    if (exprensesByCurrency.length <= 0) return null;

                    return (
                      <View
                        style={[
                          styles.tableRow,
                          { border: "0px", margin: 5, marginTop: 10 },
                        ]}
                      >
                        <View style={{ width: "50%" }}></View>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "50%",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 8,
                              color: "black",
                              textAlign: "right",
                              width: 50,
                            }}
                          >
                            TOTAL {currencyCode}
                          </Text>
                          <Text
                            style={{
                              fontSize: 8,
                              color: "black",
                              textAlign: "right",
                            }}
                          >
                            {exprensesByCurrency
                              .reduce(
                                (accumulator, currentValue) =>
                                  accumulator + (currentValue?.amount || 0),
                                0,
                              )
                              ?.toFixed(2)}
                          </Text>
                        </View>
                      </View>
                    );
                  })}
                </>
              )}
            </View>
          </View>
        </View>
        <TemplateFooter />
      </Page>

      {expenses && expenses.length > expensesQuantity && (
        <Page size="A4" style={styles.page} wrap={false}>
          <View>
            <TemplateHeader title="Aviso llegada" />
            <View style={styles.content}>
              <CargoRefContainer
                cargoRef={cargo.cargoRef ?? getCargoRef(cargo as CargoType)}
              />
            </View>
            <View style={{ border: "1px solid black" }}>
              {expenses?.slice(expensesQuantity + 1).map((value) => (
                <View style={[styles.tableRow, { border: "0px", margin: 5 }]}>
                  <View style={{ width: "50%" }}>
                    <Text style={{ fontSize: 8, color: "black" }}>
                      {value?.name}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "50%",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 8,
                        color: "black",
                        textAlign: "right",
                        width: 50,
                      }}
                    >
                      {value.currency}
                    </Text>
                    <Text
                      style={{
                        fontSize: 8,
                        color: "black",
                        textAlign: "right",
                      }}
                    >
                      {value?.amount?.toFixed(2)}
                    </Text>
                  </View>
                </View>
              ))}
              <>
                {Object.values(CurrencyCode).map((currencyCode) => {
                  const exprensesByCurrency = expenses.filter(
                    (x) => x.currency === currencyCode,
                  );

                  if (exprensesByCurrency.length <= 0) return null;

                  return (
                    <View
                      style={[
                        styles.tableRow,
                        { border: "0px", margin: 5, marginTop: 10 },
                      ]}
                    >
                      <View style={{ width: "50%" }}></View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "50%",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 8,
                            color: "black",
                            textAlign: "right",
                            width: 50,
                          }}
                        >
                          TOTAL {currencyCode}
                        </Text>
                        <Text
                          style={{
                            fontSize: 8,
                            color: "black",
                            textAlign: "right",
                          }}
                        >
                          {exprensesByCurrency
                            .reduce(
                              (accumulator, currentValue) =>
                                accumulator + (currentValue?.amount || 0),
                              0,
                            )
                            ?.toFixed(2)}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </>
            </View>
          </View>
          <TemplateFooter />
        </Page>
      )}
    </Document>
  );
};
