import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMemo, useState } from "react";
import { StatusType } from "../../app/constants";
import LoadingButton from "../shared/LoadingButton";
import { ProfileUpdateType } from "../signIn/signInAPI";
import { Role } from "../users/constants";
import { UserType } from "../users/usersAPI";
import UpdatePassword from "./UpdatePassword";

export default function ProfileForm({
  profile,
  updatedProfile,
  handleSubmit,
  handleOnChange,
  resetProfile,
  formError,
  status,
}: {
  status: StatusType;
  profile?: UserType;
  updatedProfile: ProfileUpdateType;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleOnChange: (label: string, value: string | string[]) => void;
  resetProfile: () => void;
  formError: {
    email: boolean;
    name: boolean;
  };
}) {
  const [updatePasswordOpen, setUpdatePasswordOpen] = useState(false);

  const updateEnabled = useMemo(
    () =>
      profile?.name !== updatedProfile.name ||
      profile?.email !== updatedProfile.email,
    [profile, updatedProfile],
  );

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        value={updatedProfile.name}
        margin="normal"
        required
        fullWidth
        id="name"
        label="Nombre"
        name="name"
        autoFocus
        type="text"
        onChange={(e) => handleOnChange("name", e.target.value)}
        error={formError.name}
        helperText={formError.name ? "Por favor ingrese un nombre válido" : ""}
      />

      <TextField
        value={updatedProfile.email}
        margin="normal"
        required
        fullWidth
        id="email"
        label="Correo electrónico"
        name="email"
        autoComplete="email"
        autoFocus
        type="email"
        onChange={(e) => handleOnChange("email", e.target.value)}
        error={formError.email}
        disabled={true}
        helperText={
          formError.email ? "Por favor, ingresa un correo valido" : ""
        }
      />

      <FormControl fullWidth margin="normal">
        <InputLabel id="user-role-label">Rol</InputLabel>
        <Select
          labelId="user-role-label"
          id="role"
          value={profile?.roles[0] ?? "seller"}
          label="Role"
          disabled={true}
          onChange={(e) => handleOnChange("roles", [e.target.value])}
        >
          <MenuItem value={Role.seller}>Vendedor</MenuItem>
          <MenuItem value={Role.customerService}>Customer Service</MenuItem>
          <MenuItem value={Role.admin}>Administrador</MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ display: "flex" }}>
        <Button
          type="button"
          onClick={resetProfile}
          disabled={!updateEnabled}
          sx={{ flex: 1, mt: 3, mb: 2, marginRight: 2 }}
        >
          Cancelar
        </Button>
        <LoadingButton
          sx={{ flex: 1 }}
          loading={status === StatusType.loading}
          disabled={!updateEnabled}
          text="Actualizar perfil"
          type="submit"
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
        <Button
          type="button"
          onClick={() => setUpdatePasswordOpen(true)}
          sx={{ mt: 1, padding: 2 }}
        >
          Cambiar contraseña
        </Button>
      </Box>

      <UpdatePassword
        open={updatePasswordOpen}
        onClose={() => setUpdatePasswordOpen(false)}
      />
    </Box>
  );
}
