import { Box } from "@mui/material";

import { NewCargoBodyType } from "../../../../cargosAPI";
import { CityType } from "../../../../../cities/citiesAPI";
import { TextTitle } from "../../shared/TextTitle";
import { TextSubtitle } from "../../shared/TextSubtitle";

export const Route = ({ currentCargo }: { currentCargo: NewCargoBodyType }) => {
  const departureCity = currentCargo.cities.departure as CityType;
  const destinationCity = currentCargo.cities.destination as CityType;
  const originCity = currentCargo.cities.origin as CityType;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        gap: 3,
      }}
    >
      <Box>
        <TextTitle label="Ciudad de puerto de carga" />
        <TextSubtitle
          label={
            departureCity
              ? `${departureCity?.cityName} (${departureCity?.code})`
              : "N/A"
          }
        />
      </Box>
      <Box>
        <TextTitle label="Ciudad de origen" />
        <TextSubtitle
          label={
            originCity ? `${originCity?.cityName} (${originCity?.code})` : "N/A"
          }
        />
      </Box>
      <Box>
        <TextTitle label="Ciudad de destino" />
        <TextSubtitle
          label={
            destinationCity
              ? `${destinationCity?.cityName} (${destinationCity?.code})`
              : "N/A"
          }
        />
      </Box>
    </Box>
  );
};
