import { CSSProperties, useEffect, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridPaginationModel,
  GridRowId,
} from "@mui/x-data-grid";
import type {} from "@mui/x-data-grid/themeAugmentation";
import { Button, Container } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getCities,
  selectTotalCities,
  selectCities,
  selectCitiesStatus,
} from "./citiesSlice";
import { StatusType } from "../../app/constants";
import { CityType, GetCitiesFilters, NewCityBodyType } from "./citiesAPI";
import UpdateCity from "./UpdateCity";
import CreateCity from "./CreateCity";
import DeleteCity from "./DeleteCity";
import CustomFilterSection from "../shared/FiltersSection";

const CreateButtonStyle: CSSProperties = {
  marginBottom: 20,
  paddingLeft: 15,
  paddingRight: 15,
};

const initialCity: NewCityBodyType = {
  code: "",
  cityName: "",
  cityCountry: "",
};

export default function DataTable() {
  const dispatch = useAppDispatch();

  const loading = useAppSelector(selectCitiesStatus) === StatusType.loading;
  const cities = useAppSelector(selectCities);
  const total = useAppSelector(selectTotalCities);

  const [currentCity, setCurrentCity] = useState({ ...initialCity });
  const [pageModel, setPageModel] = useState({ page: 0, pageSize: 10 });
  const [search, setSearch] = useState("");
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const getCitiesPage = (newFilters?: GetCitiesFilters) =>
    dispatch(
      getCities({
        page: pageModel.page + 1,
        pageSize: pageModel.pageSize,
        search,
        ...newFilters,
      }),
    );

  const onClickAddButton = () => {
    setCurrentCity({ ...initialCity });
    setOpenCreate(true);
  };

  const onCloseCreate = () => {
    setOpenCreate(false);
    setCurrentCity({ ...initialCity });
    getCitiesPage();
  };

  const updateCity = (id: GridRowId, city: CityType) => {
    if (id) {
      setCurrentCity(city);
      setOpenUpdate(true);
    }
  };

  const onCloseUpdate = (updateUsers = false) => {
    setOpenUpdate(false);
    setCurrentCity({ ...initialCity });
    if (updateUsers) getCitiesPage();
  };

  const deleteCity = (city: CityType) => {
    setOpenDelete(true);
    if (city) setCurrentCity(city);
  };

  const onCloseDelete = () => {
    setOpenDelete(false);
    setCurrentCity({ ...initialCity });
    getCitiesPage();
  };

  const columns: GridColDef[] = [
    {
      field: "cityName",
      headerName: "Ciudad",
      width: 230,
      filterable: false,
      sortable: false,
    },
    {
      field: "cityCountry",
      headerName: "País",
      width: 230,
      filterable: false,
      sortable: false,
    },
    {
      field: "code",
      headerName: "Código Internacional",
      width: 230,
      filterable: false,
      sortable: false,
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Modificar"
          onClick={() => updateCity(params.id, params.row)}
        />,
        <GridActionsCellItem
          icon={<Delete />}
          label="Eliminar"
          onClick={() => deleteCity(params.row)}
        />,
      ],
      filterable: false,
      sortable: false,
    },
  ];

  const onPaginationModelChange = (model: GridPaginationModel) => {
    setPageModel({ page: model.page, pageSize: model.pageSize });
    getCitiesPage({ page: model.page + 1, pageSize: model.pageSize });
  };

  useEffect(() => {
    if (loading) return;
    getCitiesPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCitiesPage({
      search: search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        height: "calc(100vh - 115px)",
        marginTop: 8,
      }}
    >
      <Button
        variant="contained"
        color="primary"
        aria-label="add"
        style={CreateButtonStyle}
        onClick={onClickAddButton}
      >
        <AddIcon />
        <span>Crear</span>
      </Button>

      <div style={{ height: "74%", width: "100%" }}>
        <CustomFilterSection searchValue={search} setSearchValue={setSearch} />
        <DataGrid
          sx={{
            borderRadius: "0px 0px 5px 5px",
            border: "1px lightgray solid",
            borderTop: "unset",
            "& .MuiDataGrid-columnHeaderTitleContainer": { padding: "0 10px" },
            "& .MuiDataGrid-cell": { padding: "0 20px" },
            "& .MuiDataGrid-row": { cursor: "pointer" },
          }}
          filterMode="server"
          paginationMode="server"
          pagination={true}
          paginationModel={pageModel}
          onPaginationModelChange={onPaginationModelChange}
          loading={loading}
          rows={cities}
          columns={columns}
          disableColumnMenu
          rowCount={total}
          pageSizeOptions={[5, 10, 25]}
          onRowClick={(params) => updateCity(params.id, params.row)}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
        />
      </div>

      <CreateCity
        currentCity={currentCity}
        open={openCreate}
        onClose={onCloseCreate}
        setCurrentCity={setCurrentCity}
      />

      <UpdateCity
        currentCity={currentCity}
        open={openUpdate}
        onClose={onCloseUpdate}
        setCurrentCity={setCurrentCity}
      />

      <DeleteCity
        currentCity={currentCity}
        onClose={onCloseDelete}
        open={openDelete}
      />
    </Container>
  );
}
