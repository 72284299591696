import { FC, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, TextField } from "@mui/material";

import { CargoAerealType, NewCargoBodyType } from "../../cargosAPI";
import ClientSelector from "../../../clients/ClientSelector";
import AgentSelector from "../../../agents/AgentSelector";
import { ClientType } from "../../../clients/clientsAPI";
import { AgentType } from "../../../agents/agentsAPI";
import RoutesForm from "./components/RoutesForm";
import { CommodityForm } from "./components/CommodityForm";
import Finance from "../shared/Finance/Finance";
import { DownloadCargoPdfButtons } from "../../DownloadView/DownloadCargoPdfButtons";
import { CargoFollow } from "../shared/CargoFollowForm/CargoFollow";
import { CargoRating } from "../shared/CargoRating";
import { CargoStatusForm } from "../shared/CargoStatusForm";

interface AereoCargoFormProps {
  currentCargo: NewCargoBodyType;
  handleOnChange: (label: string, value: string | any | null) => void;
  canUpdate?: boolean;
  formError: {
    type: boolean;
    incoterm: boolean;
    client: boolean;
    agent: boolean;
    departureCity: boolean;
    destinationCity: boolean;
    originCity: boolean;
    shipper: boolean;
    finance: boolean;
  };
  isUpdate: boolean;
  handleAereoCargoOnChange: (label: string, value: any) => void;
  selectedAgent: AgentType | null;
  setSelectedAgent: (agent: AgentType) => void;
  selectedClient: ClientType | null;
  setSelectedClient: (client: ClientType) => void;
  currentAereoCargo: CargoAerealType;
}

const AereoCargoForm: FC<AereoCargoFormProps> = ({
  currentCargo,
  handleOnChange,
  canUpdate,
  formError,
  isUpdate,
  handleAereoCargoOnChange,
  selectedAgent,
  setSelectedAgent,
  selectedClient,
  setSelectedClient,
  currentAereoCargo,
}) => {
  const [openSelectClient, setOpenSelectClient] = useState(false);

  const [openSelectAgent, setOpenSelectAgent] = useState(false);

  const toggleSelectClientModal = () => {
    setOpenSelectClient(!openSelectClient);
  };

  const toggleSelectAgentModal = () => {
    setOpenSelectAgent(!openSelectAgent);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <TextField
          value={currentCargo.shipper}
          margin="normal"
          fullWidth
          name="shipper"
          label="Shipper"
          required
          type="text"
          id="shipper"
          disabled={!canUpdate}
          onChange={(e) => handleOnChange("shipper", e.target.value)}
          error={formError.shipper}
          helperText={formError.shipper ? "Por favor ingrese un shipper" : ""}
        />

        <TextField
          value={currentCargo.consignee}
          margin="normal"
          fullWidth
          name="consignee"
          label="Consignatario"
          type="text"
          disabled={!canUpdate}
          id="consignee"
          onChange={(e) => handleOnChange("consignee", e.target.value)}
        />

        <TextField
          value={currentCargo.responsible}
          margin="normal"
          fullWidth
          disabled={!canUpdate}
          name="responsible"
          label="Aerolínea"
          type="text"
          id="responsible"
          onChange={(e) => handleOnChange("responsible", e.target.value)}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <Box flex={1}>
          <ClientSelector
            open={openSelectClient}
            toggleModal={toggleSelectClientModal}
            selectedClient={selectedClient}
            onSelectExistentClient={setSelectedClient}
            error={formError.client}
            canUpdate={canUpdate}
          />
        </Box>
        <Box flex={1}>
          <AgentSelector
            open={openSelectAgent}
            toggleModal={toggleSelectAgentModal}
            selectedAgent={selectedAgent}
            onSelectExistentAgent={setSelectedAgent}
            error={formError.agent}
            canUpdate={canUpdate}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <CargoStatusForm
          canUpdate={canUpdate}
          currentStatus={currentAereoCargo.status}
          handleStatusChange={handleAereoCargoOnChange}
        />

        <TextField
          value={currentAereoCargo.booking}
          margin="normal"
          fullWidth
          name="booking"
          label="Número de booking"
          type="text"
          disabled={!canUpdate}
          id="booking"
          onChange={(e) => handleAereoCargoOnChange("booking", e.target.value)}
        />

        <TextField
          value={currentAereoCargo.flightNumber}
          margin="normal"
          fullWidth
          name="flightNumber"
          label="Número de vuelo"
          type="text"
          id="flightNumber"
          disabled={!canUpdate}
          onChange={(e) =>
            handleAereoCargoOnChange("flightNumber", e.target.value)
          }
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "10px",
        }}
      >
        <TextField
          value={currentAereoCargo.hawb}
          margin="normal"
          fullWidth
          name="hawb"
          label="Número de HAWB"
          type="text"
          id="hawb"
          disabled={!canUpdate}
          onChange={(e) => handleAereoCargoOnChange("hawb", e.target.value)}
        />
        <TextField
          value={currentAereoCargo.mawb}
          margin="normal"
          fullWidth
          name="mawb"
          label="Número de MAWB"
          type="text"
          id="mawb"
          disabled={!canUpdate}
          onChange={(e) => handleAereoCargoOnChange("mawb", e.target.value)}
        />
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            marginTop: "16px", // marginTop that TextField has
          }}
        >
          <DatePicker
            label="ETD"
            value={currentAereoCargo.etd}
            disabled={!canUpdate}
            onChange={(value) =>
              handleAereoCargoOnChange && handleAereoCargoOnChange("etd", value)
            }
            sx={{ width: "100%" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            marginTop: "16px", // marginTop that TextField has
          }}
        >
          <DatePicker
            label="ETA"
            disabled={!canUpdate}
            value={currentAereoCargo.eta}
            onChange={(value) =>
              handleAereoCargoOnChange && handleAereoCargoOnChange("eta", value)
            }
            sx={{ width: "100%" }}
          />
        </Box>
      </Box>

      <RoutesForm
        currentCargo={currentCargo}
        formError={formError}
        handleOnChange={handleOnChange}
        canUpdate={canUpdate}
      />
      <CommodityForm
        currentCargo={currentCargo}
        handleOnChange={handleOnChange}
        canUpdate={canUpdate}
      />

      <Finance
        finance={currentCargo.currentFinance || []}
        setFinance={(finance) =>
          handleOnChange && handleOnChange("currentFinance", finance)
        }
        financeError={formError.finance}
        canUpdate={canUpdate}
      />

      {isUpdate && (
        <DownloadCargoPdfButtons
          currentCargo={currentCargo}
          cargoClient={selectedClient ?? undefined}
        />
      )}

      <CargoFollow
        follows={currentCargo.follows}
        handleOnChange={handleOnChange}
      />

      <CargoRating
        currentCargo={currentCargo}
        handleOnChange={handleOnChange}
        canUpdate={canUpdate}
      />
    </Box>
  );
};

export default AereoCargoForm;
