import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { deleteCity, selectDeleteCitiesStatus } from "./citiesSlice";
import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  Snackbar,
  Typography,
} from "@mui/material";
import { NewCityBodyType } from "./citiesAPI";
import LoadingButton from "../shared/LoadingButton";
import { StatusType } from "../../app/constants";

export default function DeleteCity({
  open,
  onClose,
  currentCity,
}: {
  open: boolean;
  onClose: () => void;
  currentCity: NewCityBodyType;
}) {
  const status = useAppSelector(selectDeleteCitiesStatus);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const dispatch = useAppDispatch();

  const onConfirmDelete = () => {
    if (currentCity._id)
      dispatch(deleteCity(currentCity._id)).then(() => {
        setOpenSnackbar(true);
        onClose();
      });
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={status === StatusType.failed ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {status === StatusType.failed
            ? "Ocurrió un error al eliminar la ciudad, intente nuevamente."
            : "Ciudad eliminada"}
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={onClose}>
        <Container
          component="main"
          maxWidth="lg"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginTop: 4,
            marginBottom: 4,
          }}
        >
          <Typography variant="h6" component="h1" gutterBottom align="center">
            Estas seguro de que quieres eliminar la ciudad{" "}
            {currentCity.cityName}?
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Button
              type="button"
              onClick={onClose}
              sx={{ flex: 1, mt: 3, mb: 2, marginRight: 2 }}
            >
              Cancelar
            </Button>

            <LoadingButton
              sx={{ flex: 1 }}
              loading={status === StatusType.loading}
              text="Eliminar"
              type="button"
              onClick={onConfirmDelete}
            />
          </Box>
        </Container>
      </Dialog>
    </>
  );
}
